export const formatIndianNumber = (num) => {
  const scales = [
    {threshold: 1e13, unit: "Kharab"},
    {threshold: 1e11, unit: "Kh"},
    {threshold: 1e9, unit: "Ar"},
    {threshold: 1e7, unit: "Cr"},
    {threshold: 1e5, unit: "L"},
    {threshold: 1, unit: ""}
  ];

  if (num < 1e5) {
    return Number(num.toFixed(2)).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const scale = scales.find((s) => num >= s.threshold);
  const divided = num / scale.threshold;
  const formattedNumber = Number(divided.toFixed(2)).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber + " " + scale.unit;
};