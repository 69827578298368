class Time {
  static makeHour(units, unitType='seconds') {
    if (unitType.toLowerCase() === 'seconds') {
      return `${units/3600}h`;
    }

    return units;
  }

  /**
   * Adds zero to the date number, for example: 1 => 01
   * @param  {Number} dateNumber
   * @return {String}
   */
  static padDate(dateNumber=0) {
    return String(dateNumber).padStart(2, '0')
  }

  static formatDateForApi(day, month, year) {
    return `${year}-${this.padDate(month+1)}-${this.padDate(day)}T00:00:00Z`;
  }

  /**
   * Converts REST API string date into date time,
   * for example: '2022-08-10T07:51:52.983552Z' => '10/08/2022 07:51:52 AM'
   * @param  {Strong} ajaxDate Date in the REST API format.
   * @param  {String} locale   Locale, defaults to 'en-GB'
   * @return {Date}
   */
  static getDateTime(ajaxDate, locale='en-GB') {
    const dateObj = new Date(ajaxDate);
    if (isNaN(dateObj.getTime())) return '-';

    const splittedDate = dateObj.toLocaleString(locale).split(',');

    try {
      return `${splittedDate[0]} ${splittedDate[1]}`;
    } catch (err) {
      return null;
    }
  }

  /**
   * Converts REST API string date into date,
   * for example: '2022-08-10T07:51:52.983552Z' => '10/08/2022'
   * @param  {Strong} ajaxDate Date in the REST API format.
   * @param  {String} locale   Locale, defaults to 'en-GB'
   * @return {Date}
   */
  static getDate(ajaxDate, locale='en-GB') {
    const dateObj = new Date(ajaxDate);
    if (isNaN(dateObj.getTime())) return '-';

    const splittedDate = dateObj.toLocaleString(locale).split(',');

    try {
      return splittedDate[0];
    } catch (err) {
      return null;
    }
  }

  /**
   * Converts REST API string date into time,
   * for example: '2022-08-10T07:51:52.983552Z' => '07:51:52 AM'
   * @param  {Strong} ajaxDate Date in the REST API format.
   * @param  {String} locale   Locale, defaults to 'en-GB'
   * @return {Date}
   */
  static getTime(ajaxDate, locale='en-GB') {
    const dateObj = new Date(ajaxDate);
    if (isNaN(dateObj.getTime())) return '-';

    const splittedDate = dateObj.toLocaleString(locale).split(',');

    try {
      return splittedDate[1];
    } catch (err) {
      return null;
    }
  }

  /**
   * Checks if the year is a leap year
   * @param  {integer}  year
   * @return {Boolean}
   */
  static isLeapYear(year) {
    if (year % 400 === 0) return true;
    if (year % 100 === 0) return false;
    return year % 4 === 0;
  }
}

export default Time;
