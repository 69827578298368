import { useState } from 'react';
import {
  Box,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { ToolboxExpertTools } from '../Toolbox/ToolboxExpertTools';
import { ExpertToolCard } from './ExpertToolCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    backgroundColor: '#f7f8fc',
    flex: 1,
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
  },
  card: {
    borderRadius: 15,
    boxShadow: '0px 6px 16px 0px #7575751A',
    padding: theme.spacing(2.5),
    backgroundColor: 'white',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ExpertToolsContainer = () => {
  const classes = useStyles();
  const [expertTool, setExpertTool] = useState();

  const expertToolsConfig = window.appConfig.expert_tools ?? [];

  const handleSelectExpertTool = (tool) => {
    setExpertTool(tool);
  };

  const expertToolsList = (
    <Grid
      container
      rowSpacing={{ xs: 2, md: 2.5 }}
      columnSpacing={{ xs: 2, md: 2.75 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {!expertToolsConfig?.length ? (
        <Typography sx={{ml: 2}}>No data</Typography>
      ) : (
        expertToolsConfig.map((card) => (
          <ExpertToolCard
            key={card?.id}
            card={card}
            onSelectTool={handleSelectExpertTool}
          />
      )))}
    </Grid>
  );

  return (
    <Box className={classes.root}>
      <Toolbar />
      <ToolboxExpertTools
        onBack={() => setExpertTool(undefined)}
        expertTool={expertTool}
      />
      {expertTool ? (
        <iframe src={expertTool.attributes?.application_link} width="100%" style={{ flex: 1 }} />
      ) : (
        <Box className={classes.main}>
          <Box className={classes.card}>{expertToolsList}</Box>
        </Box>
      )}
    </Box>
  );
};

export default ExpertToolsContainer;
