import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useEventExternal } from "../../hooks/eventExternal/useEventExternal"
import EventDetailsExternalCard from "./EventDetailsExternalCard"
import Logo from "../../assets/images/logo-new 1.svg";
import HeaderExternal from "../Header/HeaderExternal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: `${theme.spacing(2.75)} ${theme.spacing(2)}`,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f7f8fc",
    marginTop: 50,
    "@media (max-width: 768px)": {
      width: "100%",
      height: "100%",
      marginTop: 0,
      padding: 0,
      backgroundColor: "white",
    }
  },

  cardContainer: {
    borderRadius: 12,
    padding: theme.spacing(2),
    flex: 1,
    display: "flex",
    position: 'relative',
    "@media (max-width: 768px)": {
      width: "100%",
      height: "auto",
      marginTop: 0,
      padding: 0
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 15,
    position: 'absolute',
    bottom : 23,
    right : 29,
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
}));

const EventDetailsExternal = () => {
  const classes = useStyles();
  const params = useParams();
  const { data, isLoading } = useEventExternal({
    monitoringId: params.id,
  });

  const monitoringDataCatalog = data?.monitoringTargetDto

  return (
    <>
    <HeaderExternal tenantHandle={data?.tenantHandle} />
    <Box className={classes.root}>
      <Box className={classes.main}>
        {data && !isLoading && (
          <Box className={classes.cardContainer}>
             <EventDetailsExternalCard data = {data} monitoringDataCatalog = { monitoringDataCatalog } monitoringId= {params.id} />
             <Box className={classes.footer}>
            <Typography> Operated by </Typography>
            <img src={Logo} alt="Logo" />
        </Box>
          </Box>
        )}
        {!data && !isLoading && <Typography>No data</Typography>}
        {isLoading && <Typography>Loading</Typography>}
      </Box>
    </Box>
    </>
  );
};

export default EventDetailsExternal;
