import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "QUERY_FILE_LABELS";

export const useFileLabels = () => {
  return {
    query: useQuery({
      queryKey: [QUERY_KEY],
      queryFn: async () => {
        const token = await GetToken();
        const url = `${window.appConfig.apiUrl}/internal/files/labels`;

        const response = await Ajax.getData(url, token);
        return response;
      },
      refetchOnWindowFocus: false,
    })
  };
};