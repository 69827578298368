import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

const TabsContainer = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  borderBottom: '1px solid #e5e7eb', // border-b - exact match from HTML
}));

const TabButtonsContainer = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  display: 'flex',
  gap: '2rem', // space-x-8 - exact match from HTML
}));

const TabButton = styled(Button)(({ theme, active }) => ({
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  padding: '0.5rem 1rem', // px-4 py-2 - exact match from HTML
  color: active ? '#191920' : '#6b7280', // text-[#191920] or text-gray-500 - exact match from HTML
  borderBottom: active ? '2px solid #ffe034' : 'none', // border-b-2 border-[#ffe034] - exact match from HTML
  borderRadius: 0,
  textTransform: 'none',
  minWidth: 'auto',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#191920', // hover:text-[#191920] - exact match from HTML
  },
  '&:focus': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
}));

const TabContent = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  padding: '1rem 0',
  width: '100%',
}));

/**
 * Tabs component for navigation between different views
 */
const RwTabs = ({ 
  tabs, 
  selectedTab, 
  onChange, 
  children,
  ...props 
}) => {
  return (
    <Box {...props}>
      <TabsContainer>
        <TabButtonsContainer>
          {tabs.map((tab, index) => (
            <TabButton
              key={index}
              active={index === selectedTab}
              onClick={() => onChange(index)}
              disableRipple
              disableElevation
              sx={{ '&.MuiButton-root:hover': { bgcolor: 'transparent' } }}
            >
              {tab.label}
            </TabButton>
          ))}
        </TabButtonsContainer>
      </TabsContainer>
      
      <TabContent>
        {React.Children.toArray(children)[selectedTab] || null}
      </TabContent>
    </Box>
  );
};

// TabPanel component to wrap tab content
const TabPanel = ({ children, value, index, ...props }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...props}
    >
      {value === index && children}
    </div>
  );
};

RwTabs.propTypes = {
  /**
   * Array of tab objects
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Tab label
       */
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Selected tab index
   */
  selectedTab: PropTypes.number,
  /**
   * Callback for tab change
   */
  onChange: PropTypes.func,
  /**
   * Tab panel content
   */
  children: PropTypes.node,
};

TabPanel.propTypes = {
  /**
   * Tab panel content
   */
  children: PropTypes.node,
  /**
   * Current selected tab value
   */
  value: PropTypes.number.isRequired,
  /**
   * Tab panel index
   */
  index: PropTypes.number.isRequired,
};

RwTabs.defaultProps = {
  selectedTab: 0,
  onChange: () => {},
};

RwTabs.TabPanel = TabPanel;

export default RwTabs;
