import React, { useEffect, useMemo, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress, Stack, Collapse, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import useSupercluster from "use-supercluster";
import maplibre from "maplibre-gl";
import { useLocation } from "react-router-dom";
import "maplibre-gl/dist/maplibre-gl.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },
  map: {
    flex: 1,
    display: "none",
  },
  mapShow: {
    display: "block",
  },
  icon: {
    position: "relative",
  },
  label: {
    position: "absolute",
    inset: 0,
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  markerContainer: {
    display: "flex",
    cursor: "pointer",
  },
  alertContainer: {
    position: "absolute",
    top: 10,
    zIndex: 100,
  },
  alert: {
    backgroundColor: theme.palette.indicatorGreen,
    borderRadius: 2,
    color: 'white',
  }
}));

const apiKey = window.appConfig.geoapify.api.key;

const COLOR = {
  Green: "GREEN",
  Red: "RED",
};

const getColor = (status) => {
  switch (status) {
    case "DETECTED":
    case "QUALIFIED":
      return COLOR.Red;
    case "MONITORED":
    case "NON_QUALIFIED":
    case "UNKNOWN":
    default:
      return COLOR.Green;
  }
};

const getMarkerIcon = (color = COLOR.Green, selected) => {
  switch (color) {
    case COLOR.Green:
      return selected
        ? "assets/images/green-circle-shadow-icon.svg"
        : "assets/images/green-circle-icon.svg";
    case COLOR.Red:
      return selected
        ? "assets/images/red-circle-shadow-icon.png"
        : "assets/images/red-circle-icon.svg";
    case "YELLOW":
      return selected
        ? "assets/images/yellow-circle-shadow-icon.svg"
        : "assets/images/yellow-circle-icon.svg";
    default:
      throw new Error("Invalid color");
  }
};

const options = {
  radius: 75,
  maxZoom: 15,
  reduce: (acc, props) => {
    acc.count += props.count;

    if (!["DETECTED", "QUALIFIED"].includes(acc.status)) {
      acc.status = props.status;
    }

    return acc;
  },
};

const PointGroups = ({
  regions,
  bounds,
  zoom,
  map,
  selectedCoordinates,
  setSelectedCoordinates,
  panelWidth,
  setZoom,
  index,
}) => {
  const [points, setPoints] = useState([]);
  const classes = useStyles();

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: {
      ...options,
    },
  });

  useEffect(() => {
    if (!map) {
      return;
    }

    const markers = clusters.map((cluster) => {
      const [lng, lat] = cluster.geometry.coordinates;
      const { cluster: isCluster, count, status, cluster_id: clusterId } = cluster.properties;

      const markerContainer = document.createElement("div");
      markerContainer.classList.add(classes.markerContainer);

      const icon = document.createElement("img");
      icon.src = getMarkerIcon(
        getColor(status),
        selectedCoordinates?.lng === lng && selectedCoordinates?.lat === lat,
      );
      icon.classList.add(classes.icon);

      const label = document.createElement("div");
      label.innerText = count;
      label.classList.add(classes.label);
      markerContainer.appendChild(icon);
      markerContainer.appendChild(label);

      const marker = new maplibre.Marker({ element: markerContainer });

      if (!isCluster) {
        markerContainer.addEventListener("click", (e) => {
          e.stopPropagation();
          setSelectedCoordinates({ lng, lat });
          if (!selectedCoordinates) {
            map.flyTo({
              center: [lng, lat],
              padding: {
                right: panelWidth,
              },
            });
          }
        });
      } else {
        markerContainer.addEventListener("click", (e) => {
          e.stopPropagation();
          const expansionZoom = supercluster.getClusterExpansionZoom(clusterId);
          setZoom(expansionZoom);

          map.flyTo({
            zoom: expansionZoom,
            duration: 0,
            center: [lng, lat],
          });
        });
      }

      marker.setLngLat([lng, lat]).addTo(map);
      return marker;
    });

    return () => {
      markers.forEach((marker) => marker.remove());
    };
  }, [
    classes,
    clusters,
    map,
    points.length,
    setSelectedCoordinates,
    setZoom,
    supercluster,
    selectedCoordinates,
    panelWidth,
  ]);

  useEffect(() => {
    if (regions?.length === 0) {
      return;
    }

    const points = regions.map((region) => ({
      type: "Feature",
      properties: { cluster: false, status: region.status, count: region.count },
      geometry: {
        type: "Point",
        coordinates: region.geometry.coordinates,
      },
    }));

    setPoints(points);
  }, [regions, index]);

  return null;
};

export const MonitoringMap = ({
  selectedCoordinates,
  setSelectedCoordinates,
  regions = [],
  isLoading,
  bounds,
  setBounds,
  zoom,
  setZoom,
  panelWidth,
}) => {
  const classes = useStyles();
  const [map, setMap] = useState(null);
  const [mapContainer, setMapContainer] = useState(null);
  const [mapReady, setMapReady] = useState(false);
  const [open, setOpen] = useState(() => {
    return localStorage.getItem('monitoringMapAlertClosed') !== 'true';
  });
  const location = useLocation();
  const isInitRef = useRef(false);

  useEffect(() => {
    if (!mapContainer) return;
    const mapStyle = "https://maps.geoapify.com/v1/styles/toner-grey/style.json";

    const updateMap = () => {
      const b = map.getBounds();
      setBounds([
        b.getSouthWest().lng,
        b.getSouthWest().lat,
        b.getNorthEast().lng,
        b.getNorthEast().lat,
      ]);
      setZoom(map.getZoom());
    };

    const map = new maplibre.Map({
      container: mapContainer,
      style: `${mapStyle}?apiKey=${apiKey}`,
      interactive: true,
    });

    map.on("load", () => {
      map.setZoom(0);
      setMapReady(true);
      setMap(map);
      map.on("move", updateMap);
    });

    return () => {
      setMapReady(false);
      map?.remove?.();
    };
  }, [mapContainer, setZoom, setBounds]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const bounds = new maplibre.LngLatBounds();

    if (!isInitRef.current) {
      regions?.forEach((region) => {
        const [lng, lat] = region.geometry.coordinates;
        bounds.extend([lng, lat]);
      });

      if (regions?.length > 0) {
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds, { duration: 0 });
        map.setZoom(map.getZoom() - 1);

        if (map.getZoom() > 15) {
          map.setZoom(15);
        }
      }
    }
  }, [map, regions]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const [lng, lat, zoom] = searchParams.get("coordinates")?.split(",") ?? [];
    const bounds = searchParams.get("bounds");

    if (
      typeof lng !== "undefined" &&
      typeof lat !== "undefined" &&
      typeof zoom !== "undefined" &&
      typeof bounds !== "undefined"
    ) {
      isInitRef.current = true;
      setSelectedCoordinates({ lng: Number(lng), lat: Number(lat) });
      setZoom(zoom);
      setBounds(bounds.split(","));
    }
  }, [location.search, setBounds, setSelectedCoordinates, setZoom]);

  useEffect(() => {
    if (!map || !isInitRef.current || !bounds) {
      return;
    }

    if (bounds) {
      map.fitBounds(bounds, { duration: 0, zoom });
    }

    isInitRef.current = false;
  }, [map, zoom, bounds]);

  const groupedRegions = useMemo(
    () =>
      Object.entries(
        regions?.reduce((acc, val) => {
          const arr = acc[val.index] ?? [];
          arr.push(val);
          acc[val.index] = arr;

          return acc;
        }, {}),
        {},
      ),
    [regions],
  );

  return isLoading ? (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  ) : (
    <div className={classes.root}>
      <Collapse className={classes.alertContainer} in={open}>
        <Stack direction="row" alignItems="center" className={classes.alert} sx={{py: 2, px: 3}}>
          <InfoIcon fontSize="inherit" />
          <Typography component="span" color="white" fontSize="14px" lineHeight="1" sx={{mx: 1.25}}>Select a cluster to explore index tracker</Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
              localStorage.setItem('monitoringMapAlertClosed', 'true');
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Collapse>
      <div
        className={clsx(classes.map, mapReady && classes.mapShow)}
        ref={setMapContainer}
        onClick={() => {
          setSelectedCoordinates(null);
        }}
      />
      {groupedRegions.map(([index, regions]) => (
        <PointGroups
          regions={regions}
          bounds={bounds}
          index={index}
          map={map}
          panelWidth={panelWidth}
          selectedCoordinates={selectedCoordinates}
          setSelectedCoordinates={setSelectedCoordinates}
          setZoom={setZoom}
          zoom={zoom}
          key={index}
        />
      ))}
    </div>
  );
};
