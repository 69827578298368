import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Tooltip, Popover, Button, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faInfoCircle,
  faDownload,
  faEye,
  faHardDrive,
  faCodeBranch,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFilePdf,
  faFileExcel,
  faFileWord,
  faCalendar,
  faUser,
  faClock,
} from '@fortawesome/free-regular-svg-icons';
import { getFileIcon } from './utils';

// Styled components
const ListContainer = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  width: '100%',
  backgroundColor: '#fff',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '0.5rem',
  padding: '1rem',
  display: 'flex',
  alignItems: 'center',
}));



const FileIcon = styled(Box)(({ color }) => ({
  fontSize: '1.875rem',
  color: color || 'rgba(0, 0, 0, 0.6)',
}));

const MetadataItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '0.875rem',
}));

const MetadataIcon = styled(Box)(() => ({
  color: 'rgba(0, 0, 0, 0.4)',
}));

const PopoverContent = styled(Box)(() => ({
  width: '16rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
}));

/**
 * FileAttachmentList component for displaying files in a list format
 */
const FileAttachmentList = ({
  fileName,
  fileSize,
  fileType,
  fileVersion,
  creator,
  createdDate,
  updater,
  updatedDate,
  onDisplay,
  onDownload,
  onInfo,
}) => {
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const { icon, color } = getFileIcon(fileType);
  
  const handleInfoClick = (event) => {
    setInfoAnchorEl(event.currentTarget);
    if (onInfo) onInfo();
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  const infoOpen = Boolean(infoAnchorEl);
  const infoPopoverId = infoOpen ? 'attachment-list-info-popover' : undefined;

  // Info popover content
  const infoPopover = (
    <Popover
      id={infoPopoverId}
      open={infoOpen}
      anchorEl={infoAnchorEl}
      onClose={handleInfoClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <PopoverContent>
        <MetadataItem>
          <MetadataIcon>
            <FontAwesomeIcon icon={faCalendar} />
          </MetadataIcon>
          <Typography variant="body2">Created: {createdDate}</Typography>
        </MetadataItem>
        <MetadataItem>
          <MetadataIcon>
            <FontAwesomeIcon icon={faUser} />
          </MetadataIcon>
          <Typography variant="body2">Created by: {creator}</Typography>
        </MetadataItem>
        {updatedDate && (
          <MetadataItem>
            <MetadataIcon>
              <FontAwesomeIcon icon={faClock} />
            </MetadataIcon>
            <Typography variant="body2">Updated: {updatedDate}</Typography>
          </MetadataItem>
        )}
        {updater && (
          <MetadataItem>
            <MetadataIcon>
              <FontAwesomeIcon icon={faUser} />
            </MetadataIcon>
            <Typography variant="body2">Updated by: {updater}</Typography>
          </MetadataItem>
        )}
        {fileVersion && (
          <MetadataItem>
            <MetadataIcon>
              <FontAwesomeIcon icon={faCodeBranch} />
            </MetadataIcon>
            <Typography variant="body2">Version: {fileVersion}</Typography>
          </MetadataItem>
        )}
      </PopoverContent>
    </Popover>
  );

  return (
    <ListContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
        <FileIcon color={color}>
          <FontAwesomeIcon icon={icon} />
        </FileIcon>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ color: 'rgba(0, 0, 0, 0.9)', mb: 0.5 }}
          >
            {fileName}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap', mt: 0.5 }}>
            <Chip 
              label={fileSize}
              icon={<FontAwesomeIcon icon={faHardDrive} />}
              color="default"
              size="small"
              variant="outlined"
              sx={{ fontFamily: theme => theme.typography?.fontFamily || '"Inter", sans-serif' }}
            />
            {fileVersion && (
              <Chip 
                label={`v${fileVersion}`}
                icon={<FontAwesomeIcon icon={faCodeBranch} />}
                color="default"
                size="small"
                variant="outlined"
                sx={{ fontFamily: theme => theme.typography?.fontFamily || '"Inter", sans-serif' }}
              />
            )}
            {creator && (
              <Chip 
                label={creator}
                icon={<FontAwesomeIcon icon={faUser} />}
                color="default"
                size="small"
                variant="outlined"
                sx={{ fontFamily: theme => theme.typography?.fontFamily || '"Inter", sans-serif' }}
              />
            )}
          </Box>
        </Box>
      </Box>
      
      <Tooltip title="File Information">
        <Box component="span" sx={{ display: 'inline-block' }}>
          <Button 
            variant="text" 
            color="secondary" 
            size="small" 
            onClick={handleInfoClick}
            startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
            sx={{
              padding: '0.5rem',
              minWidth: 'auto',
              borderRadius: '9999px',
              color: 'rgba(0, 0, 0, 0.6)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)'
              },
              fontFamily: theme => theme.typography?.fontFamily || '"Inter", sans-serif'
            }}
            aria-label="File Information"
          />
        </Box>
      </Tooltip>
      {infoPopover}
      
      <Box sx={{ display: 'flex', gap: 1 }}>
        {onDisplay && (
          <Tooltip title="View File">
            <Box component="span" sx={{ display: 'inline-block' }}>
              <Button 
                variant="text" 
                color="secondary" 
                size="small" 
                onClick={onDisplay}
                startIcon={<FontAwesomeIcon icon={faEye} />}
                aria-label="View File"
                sx={{
                  padding: '0.5rem',
                  minWidth: 'auto',
                  borderRadius: '9999px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  },
                  fontFamily: theme => theme.typography?.fontFamily || '"Inter", sans-serif'
                }}
              />
            </Box>
          </Tooltip>
        )}
        {onDownload && (
          <Tooltip title="Download File">
            <Box component="span" sx={{ display: 'inline-block' }}>
              <Button 
                variant="text" 
                color="secondary" 
                size="small" 
                onClick={onDownload}
                startIcon={<FontAwesomeIcon icon={faDownload} />}
                aria-label="Download File"
                sx={{
                  padding: '0.5rem',
                  minWidth: 'auto',
                  borderRadius: '9999px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  },
                  fontFamily: theme => theme.typography?.fontFamily || '"Inter", sans-serif'
                }}
              />
            </Box>
          </Tooltip>
        )}
      </Box>
    </ListContainer>
  );
};

FileAttachmentList.propTypes = {
  /** Name of the file */
  fileName: PropTypes.string.isRequired,
  /** Size of the file (formatted string) */
  fileSize: PropTypes.string.isRequired,
  /** Type of the file (pdf, excel, word, etc.) */
  fileType: PropTypes.string.isRequired,
  /** Version of the file */
  fileVersion: PropTypes.string,
  /** Name of the creator */
  creator: PropTypes.string,
  /** Date the file was created (formatted string) */
  createdDate: PropTypes.string,
  /** Name of the person who last updated the file */
  updater: PropTypes.string,
  /** Date the file was last updated (formatted string) */
  updatedDate: PropTypes.string,
  /** Function to call when display button is clicked */
  onDisplay: PropTypes.func,
  /** Function to call when download button is clicked */
  onDownload: PropTypes.func,
  /** Function to call when info button is clicked */
  onInfo: PropTypes.func,
};

FileAttachmentList.defaultProps = {
  fileVersion: '',
  creator: '',
  createdDate: '',
  updater: '',
  updatedDate: '',
};

export default FileAttachmentList;
