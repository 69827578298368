import React from "react";
import { styled, makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GreenTree from "../../assets/images/green-tree.png";
import RedTree from "../../assets/images/red-tree.svg";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import plural from "../../Util/pluralize";
import { convertUnit } from "../../Util/convertUnit";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Grain from "@mui/icons-material/Grain";
import ThermostatAutoIcon from "@mui/icons-material/ThermostatAuto";
import WavesIcon from "@mui/icons-material/Waves";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CloudIcon from "@mui/icons-material/Cloud";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import TsunamiIcon from "@mui/icons-material/Tsunami";
import CycloneIcon from "@mui/icons-material/Cyclone";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 16,
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    minHeight: "479px",
    maxHeight: "670px",
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    "@media (max-width: 768px)": {
      width: "100%",
      maxHeight: "100%",
      minWidth: "95%",
      padding: 10,
    },
    position: "relative",
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  daysCount: {
    fontSize: "3rem",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: "italic",
    color: "rgba(107, 107, 107, 1)",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  headerCard: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    padding: "20px 32px",
  },
  indexDefinitionName: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 7,
    maxWidth: 200,
    color: props => props.isTriggered ? "#fff" : "#000000"
  },
  indexTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },

  progressContainer: {
    position: "relative",
  },
  progressBar: {
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.lavenderMist,
    },
  },
  bar: {
    width: 3,
    height: "100%",
    backgroundColor: "#000",
    position: "absolute",
    top: 0,
    transform: "translateX(-1px)",
  },
  mainIcon: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  mainValue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      justifyContent: "center",
      marginTop: 110,
    },
  },
  triggerDetail: {
    "@media (max-width: 768px)": {
      marginTop: 20,
      marginBottom: 30,
    },
  },
  valueDaily: {
    display: "grid",
    justifyContent: "space-around",
    gridTemplateColumns: "repeat(3, 1fr)",
    marginTop: "2rem",
    padding: "0 2rem",
  },
  dateTitleText: {
    color: props => props.isTriggered ? "#fff" : "#000000"
  },
  infoDate: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    color: "rgb(165 165 165)",
    justifyContent: "flex-end",
    "& p": {
      fontWeight: "400",
      fontSize: "16px",
      color: "rgb(165 165 165)",
    }
  },
  tags: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",

  },
  itemTag: {
    padding: "5px 16px",
    background: "#c9c7c747",
    borderRadius: "31px",
    "& p": {
      fontSize: "14px",
      fontWeight: "400"
    }
  },
  boxContent: {
    "& p": {
      color: "#00000099",
      fontSize: 18,

      "&:first-child": {
        fontSize: 36,
        fontWeight: 400,
        color: "#191920"
      },


      "&:last-child": {
        fontSize: 14,
        fontWeight: 400,
        color: "#00000066"
      }
    }
  }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  [`& .${linearProgressClasses.bar}`]: {},
}));

const ColorSegment = styled(Box)(({ theme, color, width, borderRadius }) => ({
  position: "absolute",
  height: "100%",
  backgroundColor: color,
  width: width,
  top: "0",
  borderRadius: borderRadius,
}));

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const getIconComponent = (iconName, color) => {
  color = "gray";

  const iconStyle = { color: color, position: "relative", bottom: "2px" };

  switch (iconName) {
    case "grain":
      return <Grain sx={iconStyle} />;
    case "waves":
      return <WavesIcon sx={iconStyle} />;
    case "thermostat_auto":
      return <ThermostatAutoIcon sx={iconStyle} />;
    case "wb_sunny":
      return <WbSunnyIcon sx={iconStyle} />;
    case "cloud":
      return <CloudIcon sx={iconStyle} />;
    case "umbrella":
      return <UmbrellaIcon sx={iconStyle} />;
    case "tsunami":
      return <TsunamiIcon sx={iconStyle} />;
    case "cyclone":
      return <CycloneIcon sx={iconStyle} />;
    case "thermostat":
      return <ThermostatIcon sx={iconStyle} />;
    default:
      return null;
  }
};
function formatPct(num) {
  return typeof num === "number" ? parseFloat(num.toFixed(2)) :num ?? '';
}
const EventDetailsExternalCard = ({ data }) => {
  const isTriggered =
    data?.coverage?.indexDefinition?.triggerComparator === "GT"
      ? data.indexValue > data?.coverage?.trigger
      : data.indexValue < data?.coverage?.trigger;

  const classes = useStyles({ isTriggered });

  const maxValue = data?.coverage?.exit || 1;
  const triggerPercent = data?.coverage?.exit
    ? Math.min((data?.coverage?.trigger * 100) / data?.coverage?.exit, 100)
    : data?.coverage?.trigger > data.indexValue
      ? Math.min((data?.coverage?.trigger * 100) / (data?.coverage?.trigger * 1.2), 100)
      : Math.min((data?.coverage?.trigger * 100) / data.indexValue, 100);

  const exitPercent = Math.min(Math.min((data?.coverage?.exit * 100) / maxValue, 100));
  const actual = data?.coverage?.exit
    ? Math.min((data.indexValue * 100) / maxValue, 100)
    : data?.coverage?.trigger > data.indexValue
      ? Math.min((data.indexValue * 100) / data?.coverage?.trigger, 100)
      : 100;
  const actualWidth = `${data?.coverage?.exit
    ? Math.min((data.indexValue * 100) / maxValue, 100)
    : data?.coverage?.trigger > data.indexValue
      ? Math.min((data.indexValue * 100) / data?.coverage?.trigger, 100)
      : 100
    }%`;

  const checkColor = data?.coverage?.exit
    ? Boolean(
      (data?.coverage?.trigger * 100) / data?.coverage?.exit < (data.indexValue * 100) / maxValue,
    )
    : Boolean(
      (data?.coverage?.trigger * 100) / data?.coverage?.trigger <
      (data.indexValue * 100) / data?.coverage?.trigger,
    );

  const triggerWidth = `${triggerPercent}%`;

  const payout = Math.max(0, Math.min(data?.coverage?.payoutPerUnitPct, 1));
  const redOpacity = 0.2 + payout * 0.6;

  const backgroundHeaderCard =
    payout === 0 || !isTriggered
      ? "rgb(214 255 224)"
      : payout > 0 && payout < 70 && actual < 80
        ? `rgba(244, 67, 54, ${redOpacity})`
        : "rgba(244, 67, 54, 0.8)";

  const coverageTimeRange = React.useMemo(() => {
    const startYear = dayjs(data?.coverage?.start).format("YYYY");
    const endYear = dayjs(data?.coverage?.end).format("YYYY");
    const start = dayjs(data?.coverage?.start).format("DD MMM");
    const end = dayjs(data?.coverage?.end).format("DD MMM");
    if (startYear === endYear) {
      return `${start} - ${end} ${startYear}`;
    }
    else {
      return `${start} ${startYear} - ${end} ${endYear}`;
    }
  }, [data]);

  return (
    <Box className={classes.root}>
      <Box
        className={classes.headerCard}
        sx={{
          backgroundColor: backgroundHeaderCard,
        }}
      >
        <Box className={classes.flexRow} marginBottom="8px">
          <Box className={classes.indexTitle}>
            <Typography variant="body2" className={classes.indexDefinitionName}>
              {data?.coverage?.label}
            </Typography>
            {data?.footprint && <Typography variant="body2" className={classes.dateTitleText}>
              {data?.coverage?.indexDefinition?.description}
            </Typography>}
          </Box>
          <Box display="flex" justifyContent="center" flexDirection="column" gap="1rem">

            <Box className={classes.infoDate}>
              <CalendarTodayIcon color="inherit" />
              <Typography>{coverageTimeRange}</Typography>
            </Box>
            <Box className={classes.tags}>
              {(data?.coverage?.labels || []).map(label => <Box key={`label-${label}`} className={classes.itemTag}><Typography>{label}</Typography></Box>)}
            </Box>
          </Box>
        </Box>
        <Box className={classes.mainValue} marginBottom="15px">
          <Typography
            className={classes.daysCount}
            sx={{
              color: isTriggered ? "#fff" : "rgba(49, 209, 88, 1)",
            }}
          >{`${data.indexValue.toFixed(1)} ${plural(
            convertUnit(data?.indexUnit?.symbol),
            Number(data.indexValue.toFixed(1)),
          ) ?? ""}`}</Typography>
          <img
            src={isTriggered ? RedTree : GreenTree}
            alt={isTriggered ? "Red Tree" : "Green Tree"}
            className={classes.mainIcon}
          />
        </Box>
      </Box>
      <Box className={classes.progressContainer}>
        <BorderLinearProgress
          variant="determinate"
          value={
            data?.coverage?.exit
              ? Math.min((data.indexValue * 100) / maxValue, 100)
              : data?.coverage?.trigger > data.indexValue
                ? Math.min((data.indexValue * 100) / data?.coverage?.trigger, 100)
                : 100
          }
          className={classes.progressBar}
          sx={{
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor:
                data?.coverage?.indexDefinition?.triggerComparator === "GT"
                  ? data.indexValue > data?.coverage?.trigger
                    ? "rgba(244, 67, 54, 1)"
                    : "#31D158"
                  : data.indexValue < data?.coverage?.trigger
                    ? "rgba(244, 67, 54, 1)"
                    : "#31D158",
            },
          }}
        />
        {checkColor ? (
          <ColorSegment
            color={`${data.coverage.indexDefinition.triggerComparator === "GT" ? "#31D158" : "rgba(244, 67, 54, 1)"
              } `}
            width={data?.coverage?.trigger ? triggerWidth : "0%"}
          />
        ) : (
          ""
        )}
        {checkColor ? (
          <ColorSegment
            color={`${data.coverage.indexDefinition.triggerComparator === "GT" ? "rgba(244, 67, 54, 1)" : "#31D158"
              } `}
            width={`calc(100% - ${triggerWidth - actualWidth})`}
            sx={{
              right: 0,
              zIndex: 0,
            }}
          />
        ) : (
          ""
        )}
        {data?.coverage?.trigger ? (
          <Box className={classes.bar} sx={{ left: `min(${triggerPercent}%, calc(100% - 3px))` }} />
        ) : (
          ""
        )}
        {data?.coverage?.exit ? (
          <Box className={classes.bar} sx={{ left: `min(${exitPercent}%, calc(100% - 3px))` }} />
        ) : (
          ""
        )}
      </Box>
      <Box display="flex" flexDirection="column" flex={1} marginTop="8px">
        <Box
          className={classes.triggerDetail}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          padding="0 16px"
        >
          <Typography className={classes.subtitle}>
            {data?.coverage?.triggerLabel}{" "}
          </Typography>
        </Box>

        <Box mt={2} className={classes.valueDaily} padding="0 16px">
          <Box className={classes.boxContent}>
            <Typography>{data?.coverage?.trigger} {plural(data?.coverage?.triggerUnit, data?.coverage?.trigger)}</Typography>
            <Typography>Strike</Typography>
            <Typography>Threshold</Typography>
          </Box>
          <Box className={classes.boxContent}>
            <Typography>{data?.coverage?.exit} {plural(data?.coverage?.triggerUnit, data?.coverage?.trigger)}</Typography>
            <Typography>Exit</Typography>
            <Typography>Maximum coverage</Typography>
          </Box>
          <Box className={classes.boxContent}>
            <Typography>{formatPct(data?.coverage?.payoutPerUnitPct)}%</Typography>
            <Typography>Payout Rate</Typography>
            <Typography>Per {data?.coverage?.triggerUnit} above strike</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EventDetailsExternalCard;
