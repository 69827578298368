import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

// Styled components
const CheckboxContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem', // gap-2
  cursor: 'pointer',
});

const CheckboxInput = styled('input')({
  position: 'absolute',
  opacity: 0,
  height: 0,
  width: 0,
});

const CheckboxBox = styled(Box)(({ checked, disabled }) => ({
  width: '1.25rem', // w-5
  height: '1.25rem', // h-5
  border: '1px solid rgba(0, 0, 0, 0.4)', // border-black/40
  borderRadius: '0.25rem', // rounded
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: checked ? '#ffe034' : 'transparent', // bg-[#ffe034] when checked
  opacity: disabled ? 0.5 : 1,
  transition: 'background-color 0.2s',
}));

const CheckboxLabel = styled(Typography)({
  fontSize: '0.875rem', // text-sm
  fontFamily: '"Inter", sans-serif',
});

/**
 * Checkbox component for selecting items with custom styling
 */
const RwCheckbox = ({ label, checked, onChange, disabled, name, ...props }) => {
  const handleChange = (e) => {
    if (!disabled && onChange) {
      onChange(e);
    }
  };

  return (
    <CheckboxContainer onClick={disabled ? undefined : (e) => {
      // Simulate click on hidden input to trigger onChange
      const input = e.currentTarget.querySelector('input');
      if (input) {
        input.click();
      }
    }}>
      <CheckboxBox checked={checked} disabled={disabled}>
        <CheckboxInput 
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          name={name}
          {...props}
        />
        {checked && (
          <CheckIcon 
            sx={{
              fontSize: '0.75rem', // text-xs
              color: 'rgba(0, 0, 0, 0.9)', // text-black/90
            }}
          />
        )}
      </CheckboxBox>
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxContainer>
  );
};

RwCheckbox.propTypes = {
  /**
   * The label to be displayed next to the checkbox
   */
  label: PropTypes.string,
  /**
   * If true, the checkbox is checked
   */
  checked: PropTypes.bool,
  /**
   * Callback fired when the state changes
   */
  onChange: PropTypes.func,
  /**
   * If true, the checkbox will be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Name attribute for the input element
   */
  name: PropTypes.string,
};

RwCheckbox.defaultProps = {
  checked: false,
  disabled: false,
};

export default RwCheckbox;
