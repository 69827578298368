import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "LIST_PARAMETRIC_EVENT";

/**
 * 
 * @param {k} param0 
 * key = user/adm2
 * value= Kullu
 * page = 1
 * size = 12
 * @returns 
 */
export const useListParametricEvent = ({ key, value, page, size, isTokenRequired = true }) => {
  return useQuery({
    queryKey: [QUERY_KEY, { key, value, page, size }],
    queryFn: async () => {
      const token = isTokenRequired ? await GetToken() : null;
      const params = new URLSearchParams();

      params.append('page', page - 1);
      params.append('size', size);

      if (key && value) {
        params.append('labels', `${key}:${value}`);
        var defaultFilter = window.appConfig.indexTracker?.predefinedFilter;
        if (defaultFilter && !key.includes(defaultFilter.key)) {
          params.set('labels', `${params.get('labels')};${defaultFilter.key}:${defaultFilter.value}`);
        }
      }

      const url = new URL(`${window.appConfig.apiUrl}/internal/parametric-events/overview?${params.toString()}`);
      const response = await Ajax.getData(url.href, token);

      return response;
    },
    refetchOnWindowFocus: false,
    enabled: !!(key && value),
  });
};
