import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Skeleton } from '@mui/material';

import orchard from '../../assets/images/expert-tools/orchard.png';
import canola from '../../assets/images/expert-tools/canola-council.png';
import undp from '../../assets/images/expert-tools/undp.png';
import riskwolf from '../../assets/images/expert-tools/riskwolf.png';

const getLogo = (previewImage) => {
  switch (previewImage) {
    case 'orchard':
      return <img src={orchard} alt={previewImage} />;
    case 'canola-council':
      return <img src={canola} alt={previewImage} />;
    case 'undp':
      return <img src={undp} alt={previewImage} />;
    default:
      return <img src={riskwolf} alt={previewImage} />;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    border: '1px solid #00000026',
    overflow: 'hidden',
    height: '100%',
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    height: 222,
    // TOOD: remove after change image
    fontSize: '3rem',
    fontWeight: 'bold',
    textAlign: 'center',
    '& img': {
      maxHeight: '100%',
      maxWidth: '100%'
    }
  },
  content: {
    padding: theme.spacing(2.5),
    background: '#F6F9FD',
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1E1E1E',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: '#000000DE',
  },
  btnWrapper: {
    minHeight: 50,
    flex: 1,
    alignItems: 'flex-end',
  },
  btn: {
    padding: 0,
    fontSize: 12,
    fontWeight: 700,
    color: '#1A1A21',
  },
}));

export const ExpertToolCard = (props) => {
  const classes = useStyles();

  const {
    card,
    onSelectTool,
    isLoading,
  } = props;

  return (
    <Grid sm={8} md={6} lg={4}>
      <Box className={classes.root}>
        <Stack className={classes.iconContainer}>
          {isLoading ? <Skeleton /> : getLogo(card.attributes.preview_img)}
        </Stack>
        <Stack className={classes.content}>
          {isLoading ? <Skeleton /> : <Typography className={classes.title}>{card.attributes.label ?? '---'}</Typography>}
          {isLoading ? <Skeleton /> : <Typography className={classes.description} sx={{mt: 1.25}}>{card.attributes.description ?? '---'}</Typography>}
          {!isLoading && (
            <Stack direction="row" gap={5} className={classes.btnWrapper}>
              <Button className={classes.btn} onClick={() => onSelectTool(card)}>
                View tool
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
    </Grid>
  );
};
