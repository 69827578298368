import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography, Stack } from '@mui/material';
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const CoverageOutput = (props) => {
  const { variable } = props;
  const classes = useStyles();

  const getIcon = () => {
    const iconName =  variable?.materialIcon?.substring(10)?.toLowerCase();

    return 'material-symbols:thermostat-' + iconName;
  };

  return (
    <Box className={classes.root}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Icon icon={getIcon()} fontSize={36} />
        <Typography variant='h5' className={classes.title}>{variable.name}</Typography>
      </Stack>
      <Stack gap={1} sx={{mt: 2}}>
        <Stack direction="row">
          <Typography variant="body1" fontWeight={600}>Description:</Typography>
          <Typography variant="body1" sx={{ml: 1}}>{variable.description}</Typography>
        </Stack>
        <Stack direction="row">
          <Typography variant="body1" fontWeight={600}>Unit:</Typography>
          <Typography variant="body1" sx={{ml: 1}}>{variable.unit.symbol} ({variable.unit.name})</Typography>
        </Stack>
        <Stack direction="row">
          <Typography variant="body1" fontWeight={600}>Reference System:</Typography>
          <Typography variant="body1" sx={{ml: 1}}>{variable.unit.referenceSystem}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CoverageOutput;