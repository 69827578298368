import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ToolboxTermSheet from '../Toolbox/ToolboxTermSheet';
import TermSheet from './TermSheet';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TermSheetContainer = () => {
  const classes = useStyles();

	return (
		<Box className={classes.root}>
			<ToolboxTermSheet />
      <TermSheet />
		</Box>
	);
}

export default TermSheetContainer;
