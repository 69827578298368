import React, { useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { Info } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import EventExternalCard from "../EventExternal/EventExternalCard";
import BackgroundWelcome from "../../assets/images/background-welcome.png";
import LogoPng from "../../assets/images/logo.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Checkbox, CircularProgress, Pagination } from "@mui/material";
import HowItWorks from "./HowItWorks";
import classNames from "classnames";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import WeatherNotificationForm from "./WeatherNotificationForm";
import Footer from "./Footer";
import InputSearch from "./InputSearch";
import { useSearchEventExternal } from "../../hooks/eventExternal/useSearchEventExternal"
import { useListParametricEvent } from "../../hooks/eventExternal/useListParametricEvent";
import { useHistory, useLocation } from "react-router-dom";
import EventDetailsExternal from "./EventDetailsExternal";

const useStyles = makeStyles((theme) => ({
  resultItem: {
    display: "flex",
    gap: "1rem",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    height: "fit-content",
    cursor: "pointer",
    "& .result-icon": {
      display: "flex",
      alignItems: "center",
    },
    "& .content-info": {
      "& >p": {
        "&:first-child": {
          fontWeight: 400,
          fontSize: "16px",
          color: "#1F2937",
        },
        "&:last-child": {
          fontWeight: 400,
          fontSize: "14px",
          color: "#6B7280",
        }
      }
    },
    "&:hover": {
      background: "#f3f3f3"
    }
  },
  inputSearch: {
    background: "#fff",
    minWidth: "400px",
    "&.MuiInputBase-root": {
      borderRadius: "8px"
    }
  },
  boxResultSearch: {
    marginTop: "7vh",
    minHeight: 168,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem"
  },
  boxInputSearch: {
    marginTop: "25vh",
  },
  boxInfo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-60%)",
    width: "45%",
    "& .title": {
      fontWeight: 700,
      fontSize: "48px",
      lineHeight: "100%",
      letterSpacing: 0,
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      color: "#fff",
      marginBottom: 20
    },
    "& .description": {
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: "24px",
      lineHeight: "100%",
      color: "#fff"
    }
  },
  cardContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top"
  },
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: 31,
    "@media (max-width: 768px)": {
      padding: 0,
    },
  },
  main: {
    padding: `${theme.spacing(2.75)} ${theme.spacing(2)}`,
    display: "flex",
    flexDirection: "column",
    marginTop: 50,
    borderRadius: 12,
    height: "auto",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      width: "100%",
      height: "auto",
      margin: 0,
      padding: 0,
      backgroundColor: "white",
    },
  },

  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 15,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  header: {
    height: 490,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  boxHeader: {
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
    paddingTop: "2rem"
  },
  searchBar: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
  },
  infoWeather: {
    maxWidth: "1274px",
    width: "100%",
    margin: "0 auto",
    marginTop: "-227px",
  },
  contentCard: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2rem",

    "@media (max-width: 1280px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    "@media (max-width: 768px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
      backgroundColor: "#fff",
      padding: "0 0.5rem",
      gap: "1rem",
    },

    "& >div": {
      justifySelf: "center"
    }
  },
  boxTitle: {
    marginTop: "45px",
    paddingLeft: "24px",
    "& p": {
      "&:first-child": {
        fontWeight: 700,
        fontSize: "48px",
        color: "#fff"
      },
      "&:last-child": {
        fontWeight: 700,
        fontSize: "24px",
        color: "#fff"
      }
    }
  },
  cardBackground: {
    background: "#f1f1f1",
    minHeight: "100vh",
    height: "unset",
    width: "100%"
  },
  infoSection: {
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
    padding: "4rem 0"
  },
  checked: {
    paddingLeft: 0,
    "&.MuiButtonBase-root": {
      color: "green"
    }
  },
  backgroundInfo: {
    background: "#fff"
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem"
  },
  loadingListParametric: {
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
    marginTop: "-227px",
    background: "#ffffff94",
    minHeight: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1rem"
  },
  noData: {
    gridColumn: "span 3",
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
    background: "#ffffff",
    minHeight: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1rem",
    fontSize: "24px"
  },
  logo: {
    position: "absolute",
    top: "2rem",
    right: "3rem"
  },
  fullWidth: {
    maxWidth: "100%"
  }
}));

const PAGE = {
  WELCOME: 1,
  LIST: 2,
  DETAIL: 3,
};

const EventExternal = () => {
  const history = useHistory();
  const location = useLocation();
  const paramsUrl = new URLSearchParams(location.search);
  const key = paramsUrl.get("key");
  const value = paramsUrl.get("value");
  const page = paramsUrl.get("page");
  const id = paramsUrl.get("id");
  const { data } = useSearchEventExternal();
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    size: 12
  });
  const [eventId, setEventId] = useState(null);
  const [valueSearch, setValueSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(PAGE.WELCOME);
  const { data: listParametric, isLoading: loadingListParametric } = useListParametricEvent({ ...valueSearch, ...pageConfig, isTokenRequired: false })
  const classes = useStyles();

  const onChangPageIndex = (_, newPage) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    paramsUrl.set("page", newPage);
    setPageConfig(prev => ({ ...prev, page: newPage }));
    history.push(`${window.location.pathname}?${paramsUrl.toString()}`);
  }

  const onSearch = (value) => {
    if (value) {
      paramsUrl.set("key", value.key);
      paramsUrl.set("value", value.value);
      paramsUrl.delete("page");
      setValueSearch(value);
      setPageConfig(prev => ({ ...prev, page: 1 }));
      history.push(`${window.location.pathname}?${paramsUrl.toString()}`);
    } else {
      history.push(window.location.pathname);
    }
  }

  useEffect(() => {
    setValueSearch(key && value ? {
      key,
      value,
    } : null);
    page && setPageConfig(prev => ({ ...prev, page: +page }));
    if (id) {
      setEventId(id);
      setCurrentPage(PAGE.DETAIL);
    }
    else if (key && value) {
      setCurrentPage(PAGE.LIST);
    }
    else {
      setCurrentPage(PAGE.WELCOME);
    }
  }, [location]);

  const dataAccordion = [{
    label: "What is Parametric Insurance?",
    content: <Box>
      <Typography>Parametric insurance is a type of insurance that pays out 
        a pre-determined amount when a specific, measurable event or parameter 
        (like a certain wind speed or rainfall) occurs, rather than based on the actual damage or loss incurred. 
        Payouts are tied to specific threshold (triggres) on an index.</Typography>
    </Box>
  }, {
    label: "What happens when an index reaches a payout threshold?",
    content: <Box>
      <Typography>When a predetermined payout condition is triggerd on an index, the insured is entitled to an agreed payout.</Typography>
      <Box display="flex" alignItems="center">
        <Checkbox checked={true} className={classes.checked} />
        <Typography>Instead of going through a damage assessment after an event, all parties involved recieve a payout notification.</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox checked={true} className={classes.checked} />
        <Typography>Policy holders can get the agreed payout within days/weeks of reaching the trigges on the index.</Typography>
      </Box>
    </Box>
  }, {
    label: "Who is the paying the Claim?",
    content: <Box>
      <Typography>All claims are paid/settled by the insurance company that underwrites the policy. 
      The claims are either paid directly by the insurance company or through a distribution/broker channel.</Typography>
    </Box>
  }, {
    label: "Can I check the index for other locations?",
    content: <Box>
      <Typography>Yes. Riskwolf's TrueVUE platform allows a user to track index values for all the locations that are part 
        of the overall insurance program. One can select a location from the drop down menu on the home page and track 
        the index behaviour for that location at a district level.</Typography>
    </Box>
  }];


  const optionsSearch = data?.reduce((acc, item) => {
    return [...acc, ...(item?.values?.map(e => ({ key: item.key, value: e })))]
  }, []) ?? [];

  //const highlightLocation = data?.map(label => ({ key: label.key, value: label.values[0], })).slice(0, 4) ?? [];
  const highlightLocation = [
    {key: 'user/block', value: "Shopian"},
    {key: 'user/block', value: "Pulwama"},
    {key: 'user/block', value: "Manali"},
  ];


  const detailData = useMemo(() => {
    return (listParametric?.content || []).find(item => item.coverage?.id === eventId) || {};
  }, [listParametric, eventId]);

  return (
    <>
      {/* <HeaderExternal hideArrow={true} tenantHandle={tenantHandle} /> */}
      <Box className={classes.root}>
        <Box className={classes.main}>
          {currentPage === PAGE.WELCOME ? (
            <Box className={classes.cardContainer} style={{ backgroundImage: `url(${BackgroundWelcome})` }}>
              <Box className={classes.logo}>
                <img src={LogoPng} alt="logo" />
              </Box>
              <Box className={classes.boxInfo} >
                <Typography className="title">TrueVUE</Typography>
                <Typography className="description">Live view of your Parametric Insurance Programs</Typography>
                <Box className={classes.boxInputSearch} >
                  <InputSearch options={optionsSearch} onChange={(_, value) => onSearch(value)} value={valueSearch} />
                </Box>
                <Box className={classes.boxResultSearch}>
                  {highlightLocation.map(e => {
                    return <Box className={classes.resultItem} key={e.key} onClick={() => onSearch(e)}>
                      <Box className="result-icon">
                        <LocationOnIcon />
                      </Box>
                      <Box className="content-info">
                        <Typography>{e.value}</Typography>
                        <Typography>{e.key}</Typography>
                      </Box>
                    </Box>
                  })}
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box className={classNames(classes.cardContainer, classes.cardBackground)}>
                <Box className={classes.header} style={{ backgroundImage: `linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 75%)), url(${BackgroundWelcome})` }}>
                  <Box className={classes.boxHeader}>
                    <Box className={classes.searchBar}>
                      <InputSearch options={optionsSearch} onChange={(_, value) => onSearch(value)} value={valueSearch} />
                      <Box>
                        <img src={LogoPng} alt="logo" />
                      </Box>
                    </Box>
                    <Box className={classes.boxTitle}>
                      <Typography>TrueVUE</Typography>
                      <Typography>{key}: {value}</Typography>
                    </Box>
                  </Box>
                </Box>
                {currentPage === PAGE.LIST && (
                  <>
                    {loadingListParametric ? (
                      <Box className={classes.loadingListParametric}>
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <Box className={classNames(classes.infoWeather, {
                        [classes.fullWidth]: !loadingListParametric && !listParametric?.content?.length
                      })}>
                        <Box className={classes.contentCard}>
                          {!!listParametric?.content?.length && listParametric?.content?.map?.((event) => {
                            return (
                              <EventExternalCard
                                data={event}
                                monitoringId={event?.coverage?.monitoringTarget?.id}
                                key={event.id}
                              />
                            );
                          })}
                          {!listParametric?.content?.length && <>
                            <Typography className={classes.noData} variant="body1" fontSize={22}>
                              <Info />
                              No data
                            </Typography>
                          </>}
                        </Box>
                        {listParametric?.totalPages > 1 && <Pagination className={classes.pagination} page={pageConfig.page} onChange={onChangPageIndex} count={listParametric?.totalPages} shape="rounded" />}
                      </Box>)}
                  </>
                )}
                {currentPage === PAGE.DETAIL && (
                  <EventDetailsExternal data={detailData} isLoading={loadingListParametric} />
                )}
                <HowItWorks />
                <Box className={classes.infoSection}>
                  <CollapsibleSection data={dataAccordion} />
                </Box>
                {/* <Box className={classes.backgroundInfo}>

                  <Box className={classes.infoSection}>
                    <WeatherNotificationForm />
                  </Box>
                </Box> */}

                <Footer />
              </Box>
            </>
          )}
          {/* <Box className={classes.footer}>
            <Typography> Operated by </Typography>
            <img src={Logo} alt="Logo" />
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default EventExternal;
