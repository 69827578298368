import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonNav } from '../Buttons/Buttons';
import Led from '../Led/Led';
import formatMissingValue from '../../Util/formatMissingValue';
import plural from '../../Util/pluralize';
import useWindowResize from '../../hooks/utils/useWindowResize';
import { useSidebarContext } from '../../Contexts/SidebarContext';
import { useDebounce } from '../../Util/useDebounce';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    textAlign: 'right',
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(1),
    }
  },
  tableContainer: {
    maxWidth: '100vw',
    overflowX: 'auto',
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& th, & td': {
      position: 'relative',
    }
  },
  subTableContainer: {
    maxWidth: '100%',
  },
  subTableRow: {
    '& > div': {
      padding: theme.spacing(2),
    }
  },
  tableHead: {
    backgroundColor: theme.palette.stripe,
    textTransform: 'uppercase',
    height: 80
  },
  tableCellIndicator: {
    position: 'relative',
    paddingLeft: 22
  },
  tableCell: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  definitionCell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    right: 5,
  },
  icon: {
    color: theme.palette.divider,
  },
  selectInput: {
    fontSize: "12px",
    paddingLeft: 4,
    paddingRight: 4,
    textTransform: "none"
  },
  menuItem: {
    fontSize: "12px",
    textTransform: "none"
  },
  expandBtn: {
    transform: "rotate(90deg)",
    position: "absolute",
    right: "0",
    zIndex: 100,
  }
}));

const Row = (props) => {
  const classes = useStyles();
  const { row, isSelected, expanded, handleClick, index, mainTableHeadRef } = props;
  const isItemSelected = isSelected(row.id);
  const [open, setOpen] = useState(false);
  const [colWidths, setColWidths] = useState([]);
  const screenWidth = useWindowResize();
  const {isOpen} = useSidebarContext();
  const debouncedIsSidebarOpen = useDebounce(isOpen, 300);
  const canActivate = row.uiState === 'simulated';
  const defaultLabel = row.dataSet + ' Coverage For ' + row.monitoringTarget?.name;
  const labelId = `coverage-table-checkbox-${index}`;
  const isMulti = row.coverageType === 'MULTI_TRIGGER';

  useEffect(() => {
    if (mainTableHeadRef.current !== null) {
      setColWidths(
        Array.from(mainTableHeadRef.current?.children || []).map(c => {
          return c.getBoundingClientRect().width
        })
      );
    }
  }, [mainTableHeadRef, expanded, screenWidth, debouncedIsSidebarOpen])

  useEffect(() => {
    setOpen(false);
  }, [screenWidth, expanded, isOpen])

  return (
    <React.Fragment>
      <TableRow
        onClick={() => handleClick(row, canActivate)}
        aria-checked={isItemSelected}
        role="checkbox"
        tabIndex={-1}
        selected={isItemSelected}
        sx={{ cursor: 'pointer', maxHeight: 73 }}
        key={row.id}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCell>
        <TableCell key={1} component="td" scope="row" sx={{width: '14%'}}>
          <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
            {row.label ?? defaultLabel}<br/>
          </Box>
        </TableCell>
        <TableCell key={2} component="td" scope="row" sx={{width: '14%'}}>
          <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
            {formatMissingValue(row?.monitoringTarget?.name)}
          </Box>
        </TableCell>
        <TableCell key={3} component="td" scope="row" sx={{width: '14%'}}>
          <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
            {formatMissingValue(dayjs(row.start).format(dayjs(row.start).isSame(dayjs(row.end), 'year') ? 'DD-MMM' : "DD-MMM'YY") + ' to ' + dayjs(row.end).format("DD-MMM'YY"))}
          </Box>
        </TableCell>
        <TableCell key={3} component="td" scope="row" sx={{width: '15%'}}>
          <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
            {!isMulti && <span style={{fontStyle: 'italic'}}>{formatMissingValue(row?.indexDefinition?.name)}</span>}
            {isMulti && <span>Multi Trigger</span>}
          </Box>
        </TableCell>
        <TableCell key={5} component="td" scope="row" sx={{width: '14%'}}>
          <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
            {!row.trigger ? "---" : formatMissingValue(parseInt(row.trigger * 100) / 100 + " " + plural(row.triggerUnit,row.trigger) + " pays " + parseInt(row.minPayout.amount * 100) / 100 + " %" + (row.payoutPerUnit?.amount ? (', then ' + parseInt(row.payoutPerUnit?.amount * 100) / 100 + '% per ' + row.triggerUnit) : ''))}
          </Box>
        </TableCell>
        <TableCell key={6} component="td" scope="row" sx={{width: '14%'}}>
          <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
            {row.riskPremiumPct !== undefined && row.riskPremiumPct !== null ? (row.riskPremiumPct * 100).toFixed(2)  + ' %' : ' - '}
          </Box>
        </TableCell>
        <TableCell key={8} component="td" scope="row" sx={{width: '7%'}}>
          <div className={classes.tableCellIndicator}><Led status={row.uiState}/></div>
        </TableCell>
        <TableCell key={9} component="td" scope="row" sx={{width: '7%'}}>
          {isMulti ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(prev => !prev)
              }}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          ) : (
            <ButtonNav to={`/coverages/coverage/${row.id}`} component={RouterLink}>
              <ArrowForwardIcon className={classes.arrowIcon}/>
            </ButtonNav>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{position: 'relative'}}>
              {isMulti && !!row.coverages?.length && (
                <Box className={classes.subTableContainer} sx={{backgroundColor: isItemSelected ? 'rgba(26, 26, 33, 0.08)' : 'inherit'}}>
                  {row.coverages?.map((single) => (
                    <Stack key={`single-row-${single.id}`} direction="row" className={classes.subTableRow} sx={{cursor: 'pointer'}} onClick={() => handleClick(single, single.uiState === 'simulated')}>
                      <Stack justifyContent="center" sx={{width: colWidths?.[0], px: '0 !important', boxSizing: 'border-box'}} />
                      <Box sx={{width: colWidths?.[1] ?? '14%', boxSizing: 'border-box'}}>
                        <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
                          {single.label ?? (single.dataSet + ' Coverage For ' + single.monitoringTarget?.name)}<br/>
                        </Box>
                      </Box>
                      <Box sx={{width: colWidths?.[2] ?? '14%', boxSizing: 'border-box'}}>
                        <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
                          {formatMissingValue(single?.monitoringTarget?.name)}
                        </Box>
                      </Box>
                      <Box sx={{width: colWidths?.[3] ?? '14%', boxSizing: 'border-box'}}>
                        <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
                          {formatMissingValue(dayjs(single.start).format(dayjs(single.start).isSame(dayjs(single.end), 'year') ? 'DD-MMM' : "DD-MMM'YY") + ' to ' + dayjs(single.end).format("DD-MMM'YY"))}
                        </Box>
                      </Box>
                      <Box sx={{width: colWidths?.[4] ?? '15%', boxSizing: 'border-box'}}>
                        <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
                          <span style={{fontStyle: 'italic'}}>{formatMissingValue(single?.indexDefinition?.name)}</span>
                        </Box>
                      </Box>
                      <Box sx={{width: colWidths?.[5] ?? '14%', boxSizing: 'border-box'}}>
                        <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
                          {formatMissingValue(parseInt(single.trigger * 100) / 100 + " " + plural(single.triggerUnit, single.trigger) + " pays 0%" + (single.payoutPerUnit?.amount ? (', then ' + parseInt(single.payoutPerUnit?.amount * 100) / 100 + '% per ' + single.triggerUnit) : ''))}
                        </Box>
                      </Box>
                      <Box sx={{width: colWidths?.[6] ?? '14%', boxSizing: 'border-box'}}>
                        <Box className={classes.tableCell} sx={{whiteSpace: expanded ? 'normal' : 'nowrap'}}>
                          {single.riskPremiumPct !== undefined && single.riskPremiumPct !== null ? (single.riskPremiumPct * 100).toFixed(2)  + ' %' : ' - '}
                        </Box>
                      </Box>
                      <Stack justifyContent="center" sx={{width: colWidths?.[7] / 2 ?? '7%', position: 'relative', boxSizing: 'border-box'}}>
                        <Box className={classes.tableCellIndicator}><Led status={single.uiState}/></Box>
                      </Stack>
                      <Stack justifyContent="center" alignItems="flex-start" sx={{width: colWidths?.[7] / 2 ?? '7%', boxSizing: 'border-box'}}>
                        <ButtonNav to={`/coverages/coverage/${single.id}`} component={RouterLink}>
                          <ArrowForwardIcon className={classes.arrowIcon}/>
                        </ButtonNav>
                      </Stack>
                    </Stack>
                  ))}
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const CoveragesData = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const tableHeadRef = useRef(null);

  const { coverages: rows = [], page, rowsPerPage, totalPages, totalElements, onChangePage: handleChangePage, selected, onSelect: setSelected } = props;

  const columns = [
    { label: "Label", width: "14%" },
    { label: "Monitoring Target", width: "14%" },
    { label: "Risk Period", width: "14%" },
    { label: "Definition", width: "15%" },
    { label: "Payout Structure", width: "14%" },
    { label: "Risk Premium", width: "14%" },
    {
      label: "Status",
      rightAdornment: (
        <Select
          disableUnderline
          inputProps={{ className: classes.selectInput }}
          variant="standard"
          value={props.selectedStatus}
          onChange={(e) => props.onSelectStatus?.(e.target.value)}
        >
          <MenuItem value="all" className={classes.menuItem}>
            All
          </MenuItem>
          <MenuItem value="simulated" className={classes.menuItem}>
            Simulated
          </MenuItem>
          <MenuItem value="active" className={classes.menuItem}>
            Activated
          </MenuItem>
        </Select>
      ),
      width: "14%"
    },
  ];
  
  const isSelected = (id) => selected.map(({id}) => id).indexOf(id) !== -1;
  
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => ({...n, canActivate: n.uiState === 'simulated'}));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  
  const handleClick = (row, canActivate) => {
    const selectedIndex = selected.map(({id}) => id).indexOf(row.id);
    let newSelected = [];
    
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { ...row, canActivate });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const headerCells = columns.map((col, index) => {
    return (
      <TableCell className={classes.tableHeadCell} key={index} sx={{width: col.width}} colSpan={index === columns.length - 1 ? 2 : 1}>
        <Stack spacing={1} direction="row" alignItems="center">
          <span>{col.label}</span>
          <span>{col.rightAdornment}</span>
        </Stack>
      </TableCell>
    );
  });

  const skeleton = Array(14).fill().map((item, index) => {
    return <Skeleton key={index} height={81} />
  });

  const table = (
    <Box sx={{position: 'relative'}}>
      <IconButton className={classes.expandBtn} onClick={() => setExpanded(!expanded)}>
        {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </IconButton>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="Coverages table" size="medium">
          <TableHead className={ classes.tableHead }>
            <TableRow ref={tableHeadRef}>
              <TableCell key={1} component="td" scope="row" padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < rows.length}
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell>
              { headerCells }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row
                key={`row-${index}`}
                mainTableHeadRef={tableHeadRef}
                row={row}
                handleClick={handleClick}
                isSelected={isSelected}
                expanded={expanded}
                columns={columns}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={null}
        rowsPerPageOptions={[]}
        onPageChange={handleChangePage}
        labelDisplayedRows={() => `Page: ${page + 1} of ${totalPages}`}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );

  return (
    props.loaded ? table : skeleton
  );
};

export default CoveragesData;
