import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "6rem",
    marginBottom: "4rem",
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
    "& >p": {
      "&:first-child": {
        fontWeight: "500",
        fontSize: "48px",
        color: "#000",
        marginBottom: "1rem"
      },
      "&:last-child": {
        fontWeight: "400",
        fontSize: "18px",
        color: "#000",
        marginBottom: "3rem"
      }
    }
  },
  content: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "1rem",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  itemContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 20px",
    background: "#fff",
    borderRadius: "8px",
    gap: "1rem",
    textAlign: "center",
    "& .icon": {
      background: "#FFE500",
      width: 72,
      height: 72,
      borderRadius: 72,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .label": {
      fontWeight: 700,
      fontSize: "22px",
      color: "#000000"
    },
    "& .desc": {
      fontWeight: 500,
      fontSize: "16px",
      color: "#64607D"
    }
  }
}));

const IconChart = () => {
  return <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0807 26.3189H11.0893V15.8489H14.0807V26.3189ZM20.0636 26.3189H17.0721V11.3618H20.0636V26.3189ZM26.0464 26.3189H23.055V20.336H26.0464V26.3189ZM29.0379 29.3103H8.09787V8.37033H29.0379V29.4599M29.0379 5.37891H8.09787C6.45259 5.37891 5.10645 6.72505 5.10645 8.37033V29.3103C5.10645 30.9556 6.45259 32.3017 8.09787 32.3017H29.0379C30.6831 32.3017 32.0293 30.9556 32.0293 29.3103V8.37033C32.0293 6.72505 30.6831 5.37891 29.0379 5.37891Z" fill="black" />
  </svg>
};

const IconWeather = () => {
  return <svg width="34" height="43" viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.1736 37.2425V22.9609H19.2019C19.7473 22.9609 20.2704 22.6873 20.6561 22.2003C21.0418 21.7134 21.2585 21.0529 21.2585 20.3642V15.1709C21.2585 14.4822 21.0418 13.8217 20.6561 13.3348C20.2704 12.8478 19.7473 12.5742 19.2019 12.5742H15.0887C14.5432 12.5742 14.0201 12.8478 13.6344 13.3348C13.2487 13.8217 13.032 14.4822 13.032 15.1709V20.3642C13.032 21.0529 13.2487 21.7134 13.6344 22.2003C14.0201 22.6873 14.5432 22.9609 15.0887 22.9609H16.117V37.2425H2.74902V39.8392H31.5415V37.2425H18.1736ZM15.0887 15.1709H19.2019L19.2039 20.3642H15.0887V15.1709Z" fill="black" />
    <path d="M10.2894 24.5429C9.53474 23.6904 8.93099 22.6465 8.51764 21.4793C8.10428 20.3122 7.89062 19.048 7.89062 17.7695C7.89062 16.491 8.10428 15.2268 8.51764 14.0596C8.93099 12.8925 9.53474 11.8486 10.2894 10.9961L11.6602 12.9306C11.1211 13.5395 10.6898 14.2852 10.3945 15.1189C10.0993 15.9526 9.94664 16.8556 9.94664 17.7688C9.94664 18.6821 10.0993 19.5851 10.3945 20.4188C10.6898 21.2525 11.1211 21.9982 11.6602 22.6071L10.2894 24.5429ZM24.0008 24.5429L22.6291 22.6084C23.1683 21.9995 23.5997 21.2537 23.8951 20.4199C24.1905 19.5861 24.3432 18.6829 24.3432 17.7695C24.3432 16.8561 24.1905 15.9529 23.8951 15.1191C23.5997 14.2853 23.1683 13.5395 22.6291 12.9306L24.0008 10.9961C24.7555 11.8486 25.3593 12.8925 25.7726 14.0596C26.186 15.2268 26.3996 16.491 26.3996 17.7695C26.3996 19.048 26.186 20.3122 25.7726 21.4793C25.3593 22.6465 24.7555 23.6904 24.0008 24.5429Z" fill="black" />
    <path d="M7.27316 29.1957C5.86871 27.8651 4.72884 26.14 3.94377 24.1569C3.15871 22.1739 2.75 19.9873 2.75 17.7704C2.75 15.5534 3.15871 13.3668 3.94377 11.3838C4.72884 9.40076 5.86871 7.67566 7.27316 6.34505L8.50712 8.42238C7.35753 9.51078 6.42445 10.9222 5.78179 12.5448C5.13913 14.1675 4.80455 15.9568 4.80455 17.771C4.80455 19.5852 5.13913 21.3745 5.78179 22.9972C6.42445 24.6198 7.35753 26.0313 8.50712 27.1197L7.27316 29.1957ZM27.0166 29.1957L25.7826 27.1184C26.9322 26.03 27.8653 24.6185 28.5079 22.9959C29.1506 21.3732 29.4852 19.5839 29.4852 17.7697C29.4852 15.9555 29.1506 14.1662 28.5079 12.5435C27.8653 10.9209 26.9322 9.50948 25.7826 8.42108L27.0166 6.34375C28.4212 7.67434 29.5613 9.39951 30.3465 11.3827C31.1316 13.3658 31.5404 15.5526 31.5404 17.7697C31.5404 19.9869 31.1316 22.1736 30.3465 24.1568C29.5613 26.1399 28.4212 27.8651 27.0166 29.1957Z" fill="black" />
  </svg>
};

const IconFlash = () => {
  return <svg width="37" height="47" viewBox="0 0 37 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_16989_29177)">
      <path d="M33.7606 17.8948L11.3249 46.222H6.24185L12.9726 29.2257H5.10254L16.3204 0.898438H28.9229L22.1922 17.8948H33.7606ZM28.327 20.7275H18.564L25.2947 3.73116H17.7051L8.73081 26.3929H16.6008L9.87012 43.3893H10.3784L28.327 20.7275ZM36.3197 33.0543L26.5567 45.4032L21.8417 39.4279L23.4192 37.4361L26.5567 41.3754L34.7422 31.0625L36.3197 33.0543Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_16989_29177">
        <rect width="35.8971" height="45.3236" fill="white" transform="translate(0.755859 0.898438)" />
      </clipPath>
    </defs>
  </svg>
};

const IconMoney = () => {
  return <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.79826 23.3272V30.0579H32.234V14.3529H27.7468V12.1094H33.0394C33.5397 12.1094 33.7214 12.161 33.9032 12.2597C34.0847 12.3549 34.2332 12.5027 34.3294 12.6837C34.4259 12.8655 34.4775 13.0472 34.4775 13.5475V30.8634C34.4775 31.3637 34.4259 31.5454 34.3272 31.7271C34.232 31.9087 34.0842 32.0572 33.9032 32.1534C33.7214 32.2499 33.5397 32.3015 33.0394 32.3015H8.99282C8.4925 32.3015 8.31077 32.2499 8.12904 32.1512C7.94752 32.0559 7.79896 31.9082 7.70276 31.7271C7.60853 31.5454 7.55469 31.3637 7.55469 30.8656V23.3272H9.79826Z" fill="black" />
    <path d="M27.7466 7.62248H5.31095V23.3275H27.7466V7.62248ZM29.9902 6.81703V24.1329C29.9902 24.6332 29.9386 24.8149 29.8399 24.9967C29.7447 25.1782 29.5969 25.3268 29.4159 25.423C29.2341 25.5194 29.0524 25.571 28.5521 25.571H4.50551C4.00519 25.571 3.82347 25.5194 3.64174 25.4207C3.46022 25.3255 3.31165 25.1777 3.21546 24.9967C3.12123 24.8149 3.06738 24.6332 3.06738 24.1351V6.81703C3.06738 6.31672 3.11898 6.13499 3.2177 5.95326C3.31294 5.77174 3.46072 5.62318 3.64174 5.52698C3.82347 5.43275 4.00519 5.37891 4.50327 5.37891H28.5498C29.0502 5.37891 29.2319 5.43051 29.4136 5.52923C29.5951 5.62446 29.7437 5.77225 29.8399 5.95326C29.9364 6.13499 29.988 6.31672 29.988 6.81703H29.9902Z" fill="black" />
    <path d="M16.5288 21.0811C15.0413 21.0811 13.6146 20.4902 12.5627 19.4383C11.5109 18.3864 10.9199 16.9598 10.9199 15.4722C10.9199 13.9846 11.5109 12.558 12.5627 11.5061C13.6146 10.4542 15.0413 9.86328 16.5288 9.86328C18.0164 9.86328 19.4431 10.4542 20.495 11.5061C21.5468 12.558 22.1378 13.9846 22.1378 15.4722C22.1378 16.9598 21.5468 18.3864 20.495 19.4383C19.4431 20.4902 18.0164 21.0811 16.5288 21.0811ZM16.5288 18.8376C17.4214 18.8376 18.2774 18.483 18.9085 17.8519C19.5396 17.2207 19.8942 16.3648 19.8942 15.4722C19.8942 14.5797 19.5396 13.7237 18.9085 13.0925C18.2774 12.4614 17.4214 12.1069 16.5288 12.1069C15.6363 12.1069 14.7803 12.4614 14.1492 13.0925C13.5181 13.7237 13.1635 14.5797 13.1635 15.4722C13.1635 16.3648 13.5181 17.2207 14.1492 17.8519C14.7803 18.483 15.6363 18.8376 16.5288 18.8376Z" fill="black" />
  </svg>
};

const LIST_ITEM = [{
  key: "insurer-enrollment",
  label: "Insurer Enrollment",
  description: "Enrollment through our insurance partners on the Riskwolf platform. Select different weather index to insure against.",
  icon: <IconChart />
},
{
  key: "in-time-weather-data",
  label: "In-time Weather Data",
  description: "Weather conditions are tracked using satellite data, meteorological stations, and climate models.",
  icon: <IconWeather />
},
{
  key: "determine-index-triggers",
  label: "Determine Index Triggers",
  description: "When the predefined weather condition (e.g., 10 dry days) is met, payouts are triggered automatically.",
  icon: <IconFlash />
},
{
  key: "fast-transparent-payouts",
  label: "Fast & Transparent Payouts",
  description: "Funds are transferred directly to the insured party through our insurance partners without the hassle of claims assessment.",
  icon: <IconMoney />
}];

const HowItWorks = () => {
  const classes = useStyles()
  
  return <Box className={classes.root}>
    <Box className={classes.title}>
      <Typography>How Does This Work?</Typography>
      <Typography>Understanding parametric insurance is simple! Unlike traditional insurance, payouts are triggered <br /> automatically based on a predefined index (e.g., rainfall, drought, temperature). </Typography>
    </Box>

    <Box className={classes.content}>
      {LIST_ITEM.map(e => {
        return <div className={classes.itemContent} key={e.key}>
          <Box className="icon">
            {e.icon}
          </Box>
          <Typography className="label">{e.label}</Typography>
          <Typography className="desc">{e.description}</Typography>
        </div>
      })}
    </Box>
  </Box>
};

export default HowItWorks;

