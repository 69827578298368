import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import EventDetailsExternalCard from "./EventDetailsExternalCard"
import { FootprintChart } from "../EventDetails/FootprintChart";
import { convertUnit } from "../../Util/convertUnit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: "1rem",
    maxWidth: "1274px",
    width: "100%",
    margin: "0 auto",
    marginTop: "-227px",
  },
  loading: {
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
    marginTop: "-227px",
    background: "#ffffff94",
    minHeight: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1rem"
  }
}));

const EventDetailsExternal = ({ data, isLoading }) => {
  const classes = useStyles();

  useEffect(() => {
    localStorage.setItem("lastY", window.scrollY.toString());
    window.scrollTo({ top: 0, behavior: "smooth" });
    return () => {
      window.scrollTo({ top: Number(localStorage.getItem("lastY")) });
      localStorage.removeItem("lastY");
    };
  }, []);


  if (isLoading) {
    return (
      <Box className={classes.loading}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="column" gap="2rem" width="100%" marginBottom="3rem">
        <EventDetailsExternalCard data={data} />
        {data.footprint && !!data.footprint.length && <FootprintChart
          data={data.footprint || []}
          isLoading={isLoading}
          riskTypeId={data.coverage?.riskTypeId}
          name={data.coverage?.indexDefinition?.description}
          trigger={data.coverage?.trigger}
          exit={data.coverage?.exit}
          unit={convertUnit(data.coverage?.indexDefinition?.output?.unit?.symbol)}
          lowerChartTitle={data.coverage?.indexDefinition.output.name}
          minPayout={data.coverage?.minPayout}
          payoutPerUnit={data.coverage?.payoutPerUnit}
          triggers={data.coverage?.indexDefinition?.triggers}
          monitoringDataCatalog={null}
          radius={data?.coverage?.indexDefinition?.triggers?.[0]?.value ?? 0}
          triggerComporator={data?.coverage?.indexDefinition?.triggerComparator}
        />}
      </Box>
    </Box>
  );
};

export default EventDetailsExternal;
