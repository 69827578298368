import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  container: {
    boxShadow: "0px 6px 16px 0px #7575751A",
    borderRadius: "15px",
    "&:before": {
      display: "none"
    },
    "&.MuiPaper-root": {
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
      margin: "32px 0"
    }
  },
  header: {
    padding: "1rem 2rem",
    "& .MuiAccordionSummary-expandIconWrapper": {
      borderRadius: 20,
      border: "1px solid #1A1A21"
    }

  },
  content: {
    padding: "1rem 2rem",
    paddingBottom: "2rem"
  }
}));

const CollapsibleSection = ({ data }) => {
  const classes = useStyles();
  
  return <div>
    {data.map((e, index) => {
      return <Accordion className={classes.container} key={index}>
        <AccordionSummary
          className={classes.header}
          expandIcon={<ExpandMore />}
          id={index}
        >
          <Typography component="span" sx={{
            fontSize: 18
          }}>{e?.label}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          {e?.content}
        </AccordionDetails>
      </Accordion>
    })}

  </div>
};

export default CollapsibleSection;