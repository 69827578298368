import React from 'react';
import PropTypes from 'prop-types';
import { Switch as MuiSwitch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components
const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'var(--color-secondary, #ffe034)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 12,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  padding: '4px 0',
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem', // text-sm
    fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
    paddingLeft: theme.spacing(1),
  },
}));

/**
 * Switch component for toggling between two states
 */
const RwSwitch = ({ checked, onChange, label, disabled, ...props }) => {
  if (label) {
    return (
      <StyledFormControlLabel
        control={
          <StyledSwitch 
            checked={checked} 
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
        }
        label={label}
      />
    );
  }
  
  return (
    <StyledSwitch 
      checked={checked} 
      onChange={onChange}
      disabled={disabled}
      {...props}
    />
  );
};

RwSwitch.propTypes = {
  /**
   * If true, the switch is checked (on)
   */
  checked: PropTypes.bool,
  /**
   * Callback fired when the state changes
   */
  onChange: PropTypes.func,
  /**
   * The label to be displayed next to the switch
   */
  label: PropTypes.string,
  /**
   * If true, the switch will be disabled
   */
  disabled: PropTypes.bool,
};

RwSwitch.defaultProps = {
  checked: false,
  disabled: false,
};

export default RwSwitch;
