import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "MONITORING_DATA_CATALOG_BOUNDING_BOX";

export const useMonitoringDataCatalogBoundingBox = (props) => {
  return useQuery({
    queryKey: [QUERY_KEY, props],
    queryFn: async () => {
      if (!props || !props.dp || !props.ds || !props.variableId || !props.variableId?.length) {
        return [];
      }

      const { topLeftLat, topLeftLon, bottomRightLat, bottomRightLon, variableId, dp, ds } = props;

      const token = await GetToken();
      const url = new URL(`${window.appConfig.apiUrl}/internal/data-catalog/data-providers/${dp}/data-sets/${ds}/monitoring-targets`);
      
      url.searchParams.set("topLeftLat", topLeftLat);
      url.searchParams.set("topLeftLon", topLeftLon);
      url.searchParams.set("bottomRightLat", bottomRightLat);
      url.searchParams.set("bottomRightLon", bottomRightLon);
      if (variableId) {
        url.searchParams.set("variableId", variableId);
      }

      const response = await Ajax.getData(url.href, token);

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
