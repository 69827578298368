import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  leftSide: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    justifyContent: "center",
    paddingRight: "85px",
    "& >p": {
      "&:first-child": {
        fontWeight: "800",
        fontSize: "36px",
        textTransform: "uppercase",

      },
      "&:last-child": {
        fontWeight: "500",
        fontSize: "20px",
        color: "#1E1E1E"
      }
    }
  },
  rightSide: {
    flex: 1,
    height: 770
  },
}));

const WeatherNotificationForm = () => {
  const classes = useStyles();
  return <Box display="flex" marginBlock={24} gap={4}>
    <Box className={classes.leftSide}>
      <Typography>Stay Updated with Weather Insurance Alerts</Typography>
      <Typography>Get notifications when weather indices reach critical thresholds
      </Typography>
    </Box>
    <Box className={classes.rightSide}>
      <div class="pipedriveWebForms contact-form input-material" data-pd-webforms="https://pipedrivewebforms.com/form/c362e550384214a7c86935c518dbf1897105705" />
    </Box>
  </Box>
};

export default WeatherNotificationForm;