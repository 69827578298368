import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonSuccess, ButtonNav } from '../Buttons/Buttons';
import { Button, Stack } from '@mui/material';
import Ajax, { GetToken } from '../../Util/ajax';
import CoverageBar from '../Coverage/CoverageBar';
import { useLocation } from 'react-router-dom';


const ToolboxCoverage = props => {
  const status = props.variant ? props.variant.toLowerCase() : null;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'sticky',
      top: 64,
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.appBar,
      [theme.breakpoints.up('md')]: {
        top: 64,
      }
    },
    Toolbar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      gap: theme.spacing(2),
      borderBottomColor: theme.palette.divider,
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '& .MuiButton-root': {
        padding: `${theme.spacing(1.25)} ${theme.spacing(3.5)}`,
        borderRadius: 8,
        fontWeight: 600,
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: 0,
        flexDirection: 'row',
      },
    },
    title: {
      marginRight: theme.spacing(2),
      fontSize: 18,
      fontWeight: 700,
    },
    toolbox: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: -12,
      [theme.breakpoints.up('md')]: {
        maxWidth: 'calc(100% - 364px)',
      },
    },
    controls: {
      margin: theme.spacing(-0.5),
      '& > *': {
        // margin: theme.spacing(0.5),
        [theme.breakpoints.up('md')]: {
          minWidth: 85,
        }
      }
    },
    button: {
      whiteSpace: "nowrap"
    }
  }));

  const classes = useStyles();

  const handleClickRunPayoutSimulation = async () => {
    const token = await GetToken();
    await Ajax.postData(
      `${window.appConfig.apiUrl}/internal/coverages/${props.id}/trigger-job/`,
      undefined,
      token,
    );

    props.onRunSimulationPayout?.();
  };

  const toolbox = status ? (
    <Stack className={classes.controls} direction="row" gap={1}>
      {(status === "simulated" || status === "error") && (
        <Button
          variant="contained"
          color="warning"
          className={classes.button}
          onClick={handleClickRunPayoutSimulation}
        >
          Run Payout Simulation
        </Button>
      )}
      {(status === "simulated" || status === "created") && (
        <ButtonSuccess className={classes.button} onClick={props.handleOpen} disabled={status === "created"}>
          Activate Coverage
        </ButtonSuccess>
      )}
    </Stack>
  ) : null;

  const backTo = searchParams.get("backTo");
  const tab = searchParams.get("tab");
  const backPath = backTo ? backTo + (tab ? `?tab=${tab}` : "") : "/coverages";

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.Toolbar}>
        <div className={classes.toolbox}>
          <ButtonNav component={RouterLink} to={backPath}>
            <ArrowBackIcon />
          </ButtonNav>
          {props.loaded ? (
            <Typography className={classes.title} variant="h6">
              {props.label}
            </Typography>
          ) : (
            <Skeleton width={120} height={18} variant="rectangular" />
          )}
        </div>
        {toolbox}
      </Toolbar>
      <CoverageBar
        loaded={props.loaded}
        creator={props.creator}
        createdAt={props.createdAt}
        version={props.version}
        variant={status}
      />
    </Box>
  );
};

ToolboxCoverage.defaultProps = {
  variant: '',
};

export default ToolboxCoverage;
