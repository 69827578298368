import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components
const TagContainer = styled(Box)(({ variant }) => {
  const getBackgroundColor = () => {
    switch (variant) {
      case 'default':
        return 'rgba(0, 0, 0, 0.05)'; // bg-black/5
      case 'primary':
        return '#ffe034'; // bg-[#ffe034]
      case 'outlined':
        return 'transparent';
      default:
        return 'rgba(0, 0, 0, 0.05)'; // bg-black/5
    }
  };

  const getBorder = () => {
    return variant === 'outlined' ? '1px solid rgba(0, 0, 0, 0.4)' : 'none'; // border border-black/40
  };

  return {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.5rem', // gap-2
    padding: '0.25rem 0.75rem', // px-3 py-1
    borderRadius: '9999px', // rounded-full
    backgroundColor: getBackgroundColor(),
    border: getBorder(),
  };
});

const TagLabel = styled(Typography)({
  fontSize: '0.875rem', // text-sm
  margin: 0,
});

const CloseButton = styled(IconButton)({
  padding: '2px',
  color: 'rgba(0, 0, 0, 0.4)', // text-black/40
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.6)', // hover:text-black/60
    backgroundColor: 'transparent',
  },
  '& i': {
    fontSize: '0.875rem',
  },
});

/**
 * Tag component for displaying labels that can be removed
 */
const RwTag = ({ 
  label, 
  variant, 
  onDelete, 
  ...props 
}) => {
  return (
    <TagContainer variant={variant} {...props}>
      <TagLabel>{label}</TagLabel>
      {onDelete && (
        <CloseButton onClick={onDelete} size="small">
          <i className="fa-solid fa-xmark" />
        </CloseButton>
      )}
    </TagContainer>
  );
};

RwTag.propTypes = {
  /**
   * The text to display inside the tag
   */
  label: PropTypes.string.isRequired,
  /**
   * The variant of the tag
   */
  variant: PropTypes.oneOf(['default', 'primary', 'outlined']),
  /**
   * Callback fired when the delete button is clicked
   */
  onDelete: PropTypes.func,
};

RwTag.defaultProps = {
  variant: 'default',
};

export default RwTag;
