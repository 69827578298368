import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Avatar, Tooltip, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import { formatFileSize, formatTimeAgo } from '../../Utilities';

// Styled components
const AttachmentContainer = styled(Paper)(({ theme }) => {
  // Handle theme.spacing as either a function or a value
  const spacing = (factor) => {
    return typeof theme.spacing === 'function' 
      ? theme.spacing(factor) 
      : `${factor * 8}px`; // Default spacing unit in Material-UI is 8px
  };
  
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    borderRadius: theme.shape?.borderRadius || '4px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  };
});

const FileInfoContainer = styled(Box)(({ theme }) => {
  // Handle theme.spacing as either a function or a value
  const spacing = (factor) => {
    return typeof theme.spacing === 'function' 
      ? theme.spacing(factor) 
      : `${factor * 8}px`;
  };
  
  return {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1.5),
    marginBottom: spacing(2),
  };
});

const FileIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2rem',
});

const FileDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const UserInfo = styled(Box)(({ theme }) => {
  // Handle theme.spacing as either a function or a value
  const spacing = (factor) => {
    return typeof theme.spacing === 'function' 
      ? theme.spacing(factor) 
      : `${factor * 8}px`;
  };
  
  return {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    marginTop: spacing(1),
  };
});

const ActionButtons = styled(Box)(({ theme }) => {
  // Handle theme.spacing as either a function or a value
  const spacing = (factor) => {
    return typeof theme.spacing === 'function' 
      ? theme.spacing(factor) 
      : `${factor * 8}px`;
  };
  
  return {
    display: 'flex',
    gap: spacing(1),
    marginTop: spacing(1),
  };
});

const ActionButton = styled(Button)(({ theme }) => {
  // Handle theme.spacing as either a function or a value
  const spacing = (factor) => {
    return typeof theme.spacing === 'function' 
      ? theme.spacing(factor) 
      : `${factor * 8}px`;
  };
  
  return {
    fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
    textTransform: 'none',
    borderRadius: theme.shape?.borderRadius || '4px',
    padding: `${spacing(0.5)} ${spacing(2)}`,
  };
});

// Helper function to get file icon based on file type with appropriate color
const getFileIcon = (fileType) => {
  // Color mapping based on file type categories
  const colorMap = {
    // Documents (red/orange)
    'pdf': '#E94F37',  // PDF - bright red
    'doc': '#F26419',  // Word - orange
    'docx': '#F26419', // Word - orange
    
    // Images (purple/pink)
    'jpg': '#9B5DE5',  // Images - purple
    'jpeg': '#9B5DE5', // Images - purple
    'png': '#F15BB5',  // Images - pink
    'gif': '#F15BB5',  // Images - pink
    
    // Spreadsheets (green/teal)
    'xls': '#00917C',  // Excel - teal
    'xlsx': '#00917C', // Excel - teal
    
    // Default (gray)
    'default': '#757575' // Gray
  };
  
  const fileTypes = {
    'pdf': <PictureAsPdfIcon sx={{ fontSize: 36, color: colorMap['pdf'] }} />,
    'jpg': <ImageIcon sx={{ fontSize: 36, color: colorMap['jpg'] }} />,
    'jpeg': <ImageIcon sx={{ fontSize: 36, color: colorMap['jpeg'] }} />,
    'png': <ImageIcon sx={{ fontSize: 36, color: colorMap['png'] }} />,
    'gif': <ImageIcon sx={{ fontSize: 36, color: colorMap['gif'] }} />,
    'doc': <DescriptionIcon sx={{ fontSize: 36, color: colorMap['doc'] }} />,
    'docx': <DescriptionIcon sx={{ fontSize: 36, color: colorMap['docx'] }} />,
    'xls': <ArticleIcon sx={{ fontSize: 36, color: colorMap['xls'] }} />,
    'xlsx': <ArticleIcon sx={{ fontSize: 36, color: colorMap['xlsx'] }} />,
    'default': <InsertDriveFileIcon sx={{ fontSize: 36, color: colorMap['default'] }} />
  };

  return fileTypes[fileType?.toLowerCase()] || fileTypes.default;
};



/**
 * FileAttachment component displays file information with download and display options
 */
const FileAttachment = ({
  fileName,
  fileSize,
  fileType,
  updatedAt,
  updatedBy,
  userAvatar,
  onDownload,
  onDisplay,
  showUserInfo = true,
  showActions = true,
  ...props
}) => {

  return (
    <AttachmentContainer {...props}>
      <FileInfoContainer>
        <FileIcon>
          {getFileIcon(fileType)}
        </FileIcon>
        <FileDetails>
          <Typography variant="body1" fontWeight={500} noWrap>
            {fileName}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {formatFileSize(fileSize)} • {fileType}
          </Typography>
        </FileDetails>
      </FileInfoContainer>
      
      {showUserInfo && updatedBy && (
        <UserInfo>
          <Avatar 
            src={userAvatar} 
            alt={updatedBy}
            sx={{ width: 24, height: 24 }}
          />
          <Typography variant="caption" color="text.secondary">
            {updatedBy} • Updated {formatTimeAgo(updatedAt)}
          </Typography>
        </UserInfo>
      )}
      
      {showActions && (
        <ActionButtons>
          <ActionButton 
            variant="contained" 
            color="primary"
            disableElevation
            startIcon={<VisibilityIcon />}
            onClick={onDisplay}
            sx={{ bgcolor: '#191920', '&:hover': { bgcolor: '#000' } }}
          >
            Display
          </ActionButton>
          
          <ActionButton 
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
            sx={{ 
              borderColor: '#E0E0E0', 
              color: '#191920',
              '&:hover': { 
                borderColor: '#191920',
                bgcolor: 'transparent' 
              } 
            }}
          >
            Download
          </ActionButton>
        </ActionButtons>
      )}
    </AttachmentContainer>
  );
};

FileAttachment.propTypes = {
  /**
   * Name of the file
   */
  fileName: PropTypes.string.isRequired,
  /**
   * Size of the file in bytes
   */
  fileSize: PropTypes.number.isRequired,
  /**
   * Type of the file (e.g., PDF, DOCX)
   */
  fileType: PropTypes.oneOf(['pdf', 'jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx']).isRequired,
  /**
   * When the file was last updated
   */
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  /**
   * User who last updated the file
   */
  updatedBy: PropTypes.string,
  /**
   * Avatar URL of the user who updated the file
   */
  userAvatar: PropTypes.string,
  /**
   * Function to call when download button is clicked
   */
  onDownload: PropTypes.func,
  /**
   * Function to call when display button is clicked
   */
  onDisplay: PropTypes.func,
  /**
   * Whether to show user information
   */
  showUserInfo: PropTypes.bool,
  /**
   * Whether to show action buttons
   */
  showActions: PropTypes.bool,
};

FileAttachment.defaultProps = {
  updatedAt: null,
  updatedBy: '',
  userAvatar: '',
  onDownload: () => {},
  onDisplay: () => {},
  showUserInfo: true,
  showActions: true,
};

export default FileAttachment;
