import axios from 'axios';
import {InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";
import { msalConfig } from "../Vendor/Azure/authConfig";
import { enqueueSnackbar } from 'notistack';

const GetToken = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return new Promise((resolve, reject) => {

    const accounts = msalInstance.getAllAccounts();

    const request = {
      scopes: window.appConfig.azure.msal.apiScopes,
      account: accounts[0]
    };

    msalInstance.acquireTokenSilent(request)
      .then(response => {
        resolve(response.accessToken);
      })
      .catch(error => {
        console.log("acquire token error..." + error)
        if (error instanceof InteractionRequiredAuthError) {
          console.log("requiring new token via redirect.")
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect(request);
        }
        console.log("rejected token")
        reject(error);
      })
  });

};

class Ajax {
  static downloadFile(URI, token) {
    return new Promise((resolve, reject) => {
      axios({
        url: URI,
        responseType: "blob",
        headers: {'Authorization': `Bearer ${token}`},
      }).then(response => {
        resolve(response);
      }).catch(error => {
        if (axios.isAxiosError(error)) {
          const errorMessage = error.response.data.message;

          enqueueSnackbar(
            errorMessage.length > 200 ? errorMessage.slice(0, 200) + "..." : errorMessage,
            { variant: "error" },
          );
        }

        reject(error);
      })
    })
  }

  static getData (URI, token, options = {}) {
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    headers['x-tenant-id'] = window.appConfig.tenantId;
    return new Promise((resolve, reject) => {
      axios({
        url: URI,
        headers,
        ...options,
      })
        .then(response => {
          // handle success
          resolve(response.data);
        })
        .catch(error => {
          // handle error
          if (axios.isAxiosError(error)) {
            const errorMessage = error.response.data.message;

            enqueueSnackbar(
              errorMessage.length > 200 ? errorMessage.slice(0, 200) + "..." : errorMessage,
              { variant: "error" },
            );
          }

          reject(error);
        })
    });
  }

  static getDataAll (URIs, token) {
    return new Promise((resolve, reject) => {
      // All requests have same header, just the URLs are different.
      axios.all(
        URIs.map(URI => {
          return axios.get(URI, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-tenant-id': window.appConfig.tenantId
            },
          });
        })
      )
        .then(responses => {
          // handle success
          // Get just the data out of the entire response.
          resolve(responses.map(response => {
            return response.data;
          }));
        })
        .catch(error => {
          // handle error
          if (axios.isAxiosError(error)) {
            const errorMessage = error.response.data.message;

            enqueueSnackbar(
              errorMessage.length > 200 ? errorMessage.slice(0, 200) + "..." : errorMessage,
              { variant: "error" },
            );
          }

          reject(error);
        })
    });
  }

  static deleteData (URI, token) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: URI,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-tenant-id': window.appConfig.tenantId
        },
      })
        .then(response => {
          // handle success
          resolve(response.data);
        })
        .catch(error => {
          // handle error
          if (axios.isAxiosError(error)) {
            const errorMessage = error.response.data.message;

            enqueueSnackbar(
              errorMessage.length > 200 ? errorMessage.slice(0, 200) + "..." : errorMessage,
              { variant: "error" },
            );
          }

          reject(error);
        })
    });
  }

  /**
   * Make a POST request
   * @param  {string} URI   Address to the end-point
   * @param  {object} data  Payload
   * @param  {string} token Auth token
   */
  static postData (URI, data, token) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: URI,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-tenant-id': window.appConfig.tenantId
        },
        data: data,
      })
        .then(response => {
          // handle success
          resolve(response.data);
        })
        .catch(error => {
          // handle error
          if (axios.isAxiosError(error)) {
            const errorMessage = error.response.data.message;

            enqueueSnackbar(
              errorMessage.length > 200 ? errorMessage.slice(0, 200) + "..." : errorMessage,
              { variant: "error" },
            );
          }

          reject(error);
        })
    });
  }

  static putData (URI, data, token) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: URI,
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-tenant-id': window.appConfig.tenantId
        },
        data: data,
      })
        .then(response => {
          // handle success
          resolve(response.data);
        })
        .catch(error => {
          // handle error
          if (axios.isAxiosError(error)) {
            const errorMessage = error.response.data.message;

            enqueueSnackbar(
              errorMessage.length > 200 ? errorMessage.slice(0, 200) + "..." : errorMessage,
              { variant: "error" },
            );
          }

          reject(error);
        })
    });
  }
}

export { Ajax as default, GetToken };
