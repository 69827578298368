import { useState, useEffect } from "react";

export default function useWindowResize() {
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth);
            })
        }
    }, []);
    
    return screenWidth;
}