import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import maplibre from 'maplibre-gl';
import Ajax, { GetToken } from '../../Util/ajax';
import 'maplibre-gl/dist/maplibre-gl.css';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderradius: 10,
  },
  locationMap: {
    width: '100%',

    '& .maplibregl-popup-content': {
      fontSize: 16,
    }
  },
  map: {
    width: '100%',
    height: 400,
  },
}));

const CoverageMap = (props) => {
  const classes = useStyles();
  const { dataSourceId, dataSetId, monitoringTarget } = props;
  const [paths, setPaths] = useState([]);
  const [geometry, setGeometry] = useState({ type: "", coordinates: [] });
  const [center, setCenter] = useState({
    lat: undefined,
    lng: undefined,
  });
  const [mapReady, setMapReady] = useState(false);
  const mapContainer = useRef(null);
  const maplibreRef = useRef();
  const apiKey = window.appConfig.geoapify.api.key;

  const showMap = center.lat !== undefined && center.lng !== undefined;
  
  const getMonitoringTarget = () => {
    const URI = `${window.appConfig.apiUrl}/internal/data-catalog/data-providers/${dataSourceId}/data-sets/${dataSetId}/monitoring-targets/${monitoringTarget?.id}`;

    GetToken().then((token) => {
      Ajax.getData(URI, token)
        .then((data) => {
          const newPaths = data.geometryData?.geoJson?.features?.map((feature) => {
            const flatten = feature.geometry.coordinates.flat(Infinity);
            setGeometry({
              type: feature.geometry.type,
              coordinates: [...feature.geometry.coordinates],
            });
            return [
              {
                lng: flatten[0],
                lat: flatten[1],
              },
            ];
          });
          if (newPaths?.length) {
            setPaths(newPaths);
          }
        })
        .catch((error) => {
          // Requested by the client.
          console.error(error);
        });
    });
  };

  useEffect(() => {
    setMapReady(false);
    if (dataSourceId && dataSetId && monitoringTarget?.id) {
      getMonitoringTarget();
    }
  }, [dataSourceId, dataSetId, monitoringTarget?.id]);

  useEffect(() => {
    if (paths.length && paths[0].length) {
      const point = paths[0][0];

      setCenter({ lat: point.lat, lng: point.lng });
    }
  }, [paths]);

  useEffect(() => {
    if (!showMap || typeof center.lat === 'undefined' || typeof center.lng === 'undefined') return;
    const mapStyle = 'https://maps.geoapify.com/v1/styles/toner-grey/style.json';
    maplibreRef.current = new maplibre.Map({
      container: mapContainer.current,
      style: `${mapStyle}?apiKey=${apiKey}`,
      center: [center.lng, center.lat],
      interactive: true,
      zoom: 6,
    });
    maplibreRef.current.on('load', () => {
      setMapReady(true);
    });
    return () => {
      setMapReady(false);
      maplibreRef.current?.remove?.();
    };
  }, [showMap, center, apiKey]);

  useEffect(() => {
    if (
      !center.lat ||
      !center.lng ||
      !mapContainer.current ||
      !maplibreRef.current ||
      !mapReady ||
      !paths.length ||
      !geometry
    )
      return;

    const map = maplibreRef.current;
    const marker = new maplibre.Marker();
    map.addControl(new maplibre.NavigationControl({showCompass: false}));
    const popup = new maplibre.Popup({
      closeButton: false,
      closeOnClick: false
    });

    let topLeftLatValue;
    let topLeftLonValue;
    let bottomRightLonValue;
    let bottomRightLatValue;
    let latValues;
    let lonValues;

    const geometryType = geometry.type;
    switch (geometryType) {
      case "MultiPolygon":
        latValues = geometry.coordinates.flat(2).map(coord => coord[1]);
        lonValues = geometry.coordinates.flat(2).map(coord => coord[0]);
        break;
      case "Point":
        latValues = [geometry.coordinates[1]];
        lonValues = [geometry.coordinates[0]];
        break;
      default: // Polygon
        latValues = geometry.coordinates[0].map(coord => coord[1]);
        lonValues = geometry.coordinates[0].map(coord => coord[0]);
        break;
    }


    if (!!latValues.length && !!lonValues.length) {
      topLeftLonValue = Math.min(...lonValues);
      topLeftLatValue = Math.min(...latValues);
      bottomRightLonValue = Math.max(...lonValues);
      bottomRightLatValue = Math.max(...latValues);

      let bounds;
      if (geometryType === "Point") {
        // For Point type, create a fixed-size bounding box around the point
        const point = geometry.coordinates;
        const fixedOffset = 0.5; // This will create a 1-degree box around the point
        bounds = [
          [point[0] - fixedOffset, point[1] - fixedOffset],
          [point[0] + fixedOffset, point[1] + fixedOffset]
        ];
      } else {
        // For Polygon and MultiPolygon, use the calculated bounds with offset
        const offsetMultiplier = 0.3;
        const latOffset = (bottomRightLatValue - topLeftLatValue) * offsetMultiplier;
        const lonOffset = (bottomRightLonValue - topLeftLonValue) * offsetMultiplier;
        bounds = [
          [topLeftLonValue - lonOffset, topLeftLatValue - latOffset],
          [bottomRightLonValue + lonOffset, bottomRightLatValue + latOffset]
        ];
      }

      if (!!map) {
        map.fitBounds(bounds);
      }
    }

    // Remove existing layers if they exist
    if (map.getLayer("main1")) {
      map.removeLayer("main1");
    }
    if (map.getLayer("main2")) {
      map.removeLayer("main2");
    }
    if (map.getSource("maine")) {
      map.removeSource("maine");
    }

    if (geometry?.type === "Point") {
      // Add marker for Point type
      const marker = new maplibre.Marker()
        .setLngLat(geometry.coordinates)
        .addTo(map);

      // Add popup for Point type
      const popup = new maplibre.Popup({
        closeButton: false,
        closeOnClick: false
      })
        .setLngLat(geometry.coordinates)
        .setHTML(monitoringTarget?.id)
        .addTo(map);

      return () => {
        marker.remove();
        popup.remove();
      };
    } else {
      // Create GeoJSON data based on geometry type
      const geojsonData = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              description: monitoringTarget?.id
            },
            geometry: geometry
          }
        ]
      };

      map.addSource("maine", {
        type: "geojson",
        data: geojsonData
      });

      map.addLayer({
        id: "main1",
        type: "fill",
        source: "maine",
        paint: {
          "fill-color": "#888888",
          "fill-opacity": 0.4,
        },
      });
      map.addLayer({
        id: "main2",
        type: "line",
        source: "maine",
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });
      map.on('mouseenter', 'main1', (e) => {
        map.getCanvas().style.cursor = 'pointer';
        const description = e.features[0].properties.description;
        const popupLng = topLeftLonValue + (bottomRightLonValue - topLeftLonValue) * 0.5;
        const popupLat = topLeftLatValue + (bottomRightLatValue - topLeftLatValue) * 0.5;
        popup.setLngLat([popupLng, popupLat]).setHTML(description).addTo(map);
      });
      map.on('mouseleave', 'main1', () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      });
    }

    return () => {
      marker.remove();
    };
  }, [center, paths, mapReady, geometry]);

  return showMap ? (
    <Box className={classes.root}>
      <div className={classes.locationMap}>
        <div className={classes.map} ref={mapContainer} />
      </div>
    </Box>
  ) : null;
};

export default CoverageMap;
