import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonNav } from '../Buttons/Buttons';
import { Button, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ToolboxTermSheet = props => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'sticky',
      top: 64,
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.appBar,
      [theme.breakpoints.up('md')]: {
        top: 64,
      }
    },
    Toolbar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      gap: theme.spacing(2),
      borderBottomColor: theme.palette.divider,
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '& .MuiButton-root': {
        padding: `${theme.spacing(1.25)} ${theme.spacing(3.5)}`,
        borderRadius: 8,
        fontWeight: 600,
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: 0,
        flexDirection: 'row',
      },
    },
    title: {
      marginRight: theme.spacing(2),
      fontSize: 18,
      fontWeight: 700,
    },
    toolbox: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: -12,
      [theme.breakpoints.up('md')]: {
        maxWidth: 'calc(100% - 364px)',
      },
    },
    controls: {
      margin: theme.spacing(-0.5),
      '& > *': {
        // margin: theme.spacing(0.5),
        [theme.breakpoints.up('md')]: {
          minWidth: 85,
        }
      }
    },
    button: {
      whiteSpace: "nowrap"
    },
    messageContainer: {
      backgroundColor: "#EDEDED",
      padding: "24px 16px",
    },
    message: {
      fontSize: 18,
      fontStyle: "italic",
    }
  }));

  const classes = useStyles();

  const backTo = searchParams.get("backTo");
  const tab = searchParams.get("tab");
  const backPath = backTo ? backTo + (tab ? `?tab=${tab}` : "") : "/coverages";

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.Toolbar}>
        <div className={classes.toolbox}>
          <ButtonNav component={RouterLink} to={backPath}>
            <ArrowBackIcon />
          </ButtonNav>
          <Typography className={classes.title} variant="h6">
            Term-Sheet Generator
          </Typography>
        </div>
        
        <Stack className={classes.controls} direction="row" gap={1}>
          <Button
            color="error"
            className={classes.button}
            onClick={() => {}}
          >
            Cancel
          </Button>
        </Stack>
      </Toolbar>
      <Box className={classes.messageContainer}>
        <Typography className={classes.message}>
          This view is information only. Any data on the term-sheet will not be saved permanently
        </Typography>
      </Box>
    </Box>
  );
};

ToolboxTermSheet.defaultProps = {
  variant: '',
};

export default ToolboxTermSheet;
