import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components
const BadgeContainer = styled(Box)(({ variant, outlined }) => {
  const getBackgroundColor = () => {
    if (outlined) return 'transparent';
    
    switch (variant) {
      case 'default':
        return '#ffe034';
      case 'success':
        return '#31d158';
      case 'error':
        return '#f44336';
      case 'neutral':
        return 'rgba(0, 0, 0, 0.05)';
      default:
        return '#ffe034';
    }
  };

  const getTextColor = () => {
    if (outlined) return '#191920';
    
    switch (variant) {
      case 'default':
        return '#191920';
      case 'success':
      case 'error':
        return '#ffffff';
      case 'neutral':
        return 'rgba(0, 0, 0, 0.9)';
      default:
        return '#191920';
    }
  };

  const getBorder = () => {
    return outlined ? '1px solid #191920' : 'none';
  };

  return {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem 0.5rem', // px-2 py-1
    backgroundColor: getBackgroundColor(),
    color: getTextColor(),
    borderRadius: '9999px', // rounded-full
    border: getBorder(),
  };
});

const BadgeText = styled(Typography)({
  fontSize: '0.75rem', // text-xs
  fontWeight: 400,
  lineHeight: 1.5,
});

/**
 * Badge component for displaying short status indicators
 */
const RwBadge = ({ variant, label, outlined, ...props }) => {
  return (
    <BadgeContainer variant={variant} outlined={outlined} {...props}>
      <BadgeText>{label}</BadgeText>
    </BadgeContainer>
  );
};

RwBadge.propTypes = {
  /**
   * The variant of the badge (default, success, error, neutral)
   */
  variant: PropTypes.oneOf(['default', 'success', 'error', 'neutral']),
  /**
   * The text to display inside the badge
   */
  label: PropTypes.string.isRequired,
  /**
   * If true, the badge will have an outline instead of a fill
   */
  outlined: PropTypes.bool,
};

RwBadge.defaultProps = {
  variant: 'default',
  outlined: false,
};

export default RwBadge;
