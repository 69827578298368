import { createContext, useContext, useState } from "react";

const CoverageContext = createContext({
  selectedCoverages: [],
	setSelectedCoverages: () => {},
});

export const useCoverageContext = () => useContext(CoverageContext);

export const CoverageContextProdiver = ({ children }) => {
  const [selectedCoverages, setSelectedCoverages] = useState([]);

  return (
    <CoverageContext.Provider value={{ selectedCoverages, setSelectedCoverages }}>{children}</CoverageContext.Provider>
  );
};

export default CoverageContext;
