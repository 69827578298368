import React from "react";
import { styled, makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GreenTree from "../../assets/images/green-tree.png";
import RedTree from "../../assets/images/red-tree.svg";
import InformIcon from "../../assets/images/inform.svg";
import ShareIcon from "../../assets/images/material-symbols-light_share.svg";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import plural from "../../Util/pluralize";
import { convertUnit } from "../../Util/convertUnit";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Grain from "@mui/icons-material/Grain";
import ThermostatAutoIcon from "@mui/icons-material/ThermostatAuto";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import classificationData from "../EventExternal/classification.json";
import WavesIcon from "@mui/icons-material/Waves";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CloudIcon from "@mui/icons-material/Cloud";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import TsunamiIcon from "@mui/icons-material/Tsunami";
import CycloneIcon from "@mui/icons-material/Cyclone";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import EventsDetailShareModal from "../EventDetails/EventDetailsShareModal";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Logo from "../../assets/images/logo-new 1.svg";
import Time from "../../Util/time";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 388,
    borderRadius: 16,
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    minHeight: "479px",
    maxHeight: "670px",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      width: "100%",
      maxHeight: "100%",
      minWidth: "95%",
      padding: 10,
    },
    position: "relative",
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  daysCount: {
    fontSize: "3rem",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: "italic",
    color: "rgba(107, 107, 107, 1)",
    maxWidth: 200,
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  dateText: {
    fontSize: "0.85rem",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  weatherIcon: {
    fontSize: "1.5rem",
    marginRight: theme.spacing(1),
  },
  indexDefinition: {
    position: "absolute",
    top: 30,
    left: "-200px",
    backgroundColor: "#333",
    color: "#fff",
    padding: 16,
    borderRadius: 5,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 100,
    width: 400,
    "&:before": {
      content: "''",
      position: "absolute",
      top: "-10px",
      left: "205px",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "transparent transparent #333 transparent",
    },
    "@media (max-width: 768px)": {
      width: "auto",
    },
  },
  historyButton: {
    textTransform: "uppercase",
    fontSize: "0.75rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerCard: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    padding: 16,
  },
  indexDefinitionName: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 7,
    maxWidth: 200,
    color: props => props.isTriggered ? "#fff" : "#000000"
  },
  indexTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  trigger: {
    fontSize: 12,
    color: "#000000",
    fontWeight: 400,
  },
  dateAdd: {
    fontSize: 14,
    color: "rgba(255, 0, 0, 0.87)",
    marginLeft: 10,
    fontWeight: 700,
  },
  rainIcon: {
    marginRight: 10,
  },
  rainDay: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  dataSource: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: "Roboto",
    textAlign: "end",
    color: "rgba(107, 107, 107, 1)",
  },
  footerCard: {
    display: "flex",
    padding: "0 16px",
    justifyContent: "flex-end",
    marginTop: 40,
    marginBottom: 20,
  },
  tool: {
    display: "flex",
    justifyContent: "space-between",
    gap: 15,
  },
  Inform: {
    position: "relative",
  },
  indexDefinitionTitle: {
    fontSize: 14,
  },
  indexDefinitionSub: {
    fontSize: 12,
    fontWeight: 100,
    marginTop: 10,
  },
  progressContainer: {
    position: "relative",
  },
  progressBar: {
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.lavenderMist,
    },
  },
  bar: {
    width: 3,
    height: "100%",
    backgroundColor: "#000",
    position: "absolute",
    top: 0,
    transform: "translateX(-1px)",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    minWidth: 300,
    outline: "none",
  },
  modalHeader: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  shareButtons: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  },
  toolIcon: {
    cursor: "pointer",
  },
  mainIcon: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  mainValue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      justifyContent: "center",
      marginTop: 110,
    },
  },
  triggerDetail: {
    "@media (max-width: 768px)": {
      marginTop: 20,
      marginBottom: 30,
    },
  },
  valueDaily: {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      marginBottom: 100,
    },
  },
  logo: {
    display: 'none',
    gap: 5,
    position: 'absolute',
    bottom: 30,
    zIndex: 2,
    left: 15,
    "@media (max-width: 768px)": {
      display: "flex",
      fontSize: 10,
    }
  },
  dateTitleText: {
    color: props => props.isTriggered ? "#fff" : "#000000"
  }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  [`& .${linearProgressClasses.bar}`]: {},
}));

const ColorSegment = styled(Box)(({ theme, color, width, borderRadius }) => ({
  position: "absolute",
  height: "100%",
  backgroundColor: color,
  width: width,
  top: "0",
  borderRadius: borderRadius,
}));

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const getIconComponent = (iconName, color) => {
  color = "gray";

  const iconStyle = { color: color, position: "relative", bottom: "2px" };

  switch (iconName) {
    case "grain":
      return <Grain sx={iconStyle} />;
    case "waves":
      return <WavesIcon sx={iconStyle} />;
    case "thermostat_auto":
      return <ThermostatAutoIcon sx={iconStyle} />;
    case "wb_sunny":
      return <WbSunnyIcon sx={iconStyle} />;
    case "cloud":
      return <CloudIcon sx={iconStyle} />;
    case "umbrella":
      return <UmbrellaIcon sx={iconStyle} />;
    case "tsunami":
      return <TsunamiIcon sx={iconStyle} />;
    case "cyclone":
      return <CycloneIcon sx={iconStyle} />;
    case "thermostat":
      return <ThermostatIcon sx={iconStyle} />;
    default:
      return null;
  }
};

const EventDetailsExternalCard = (props) => {
  const { data, monitoringId, setBookmarkedEvents } = props;

  const [showTooltip, setShowTooltip] = React.useState(false);
  const [isBookmarked, setIsBookmarked] = React.useState(true);
  const [openEventDetailsShareModal, setEventDetailsShareModal] = React.useState(false);
  const [eventDetailsShareLink, setEventDetailsShareLink] = React.useState("");

  const isTriggered =
    data?.coverage?.indexDefinition?.triggerComparator === "GT"
      ? data.indexValue > data?.coverage?.trigger
      : data.indexValue < data?.coverage?.trigger;

  const classes = useStyles({isTriggered});

  const handleOpenModal = () => {
    setEventDetailsShareModal(true);
  };

  console.log("data",data);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleCloseEventDetailsShareModal = () => {
    setEventDetailsShareModal(false);
  };

  function lookupCategory(footprintValue) {
    for (const category of classificationData.classification) {
      if (footprintValue >= category.value_from && footprintValue <= category.value_to) {
        return category;
      }
    }
    return { code: "Not Categorized", description: "available categories." };
  }

  const lastFootPrint = data?.footprint?.[data?.footprint?.length - 1];
  const lastCategory = lookupCategory(lastFootPrint?.indexValue);

  const handleBookmarkClick = () => {
    let bookmarks = JSON.parse(localStorage.getItem("bookmarks")) || [];

    if (isBookmarked) {
      bookmarks = bookmarks.filter((item) => item.id !== monitoringId);
      setBookmarkedEvents(bookmarks);
    } else {
      bookmarks.push({ id: monitoringId, data: data });
    }

    localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
    setIsBookmarked(!isBookmarked);
  };

  React.useEffect(() => {
    const bookmarks = JSON.parse(localStorage.getItem("bookmarks")) || [];
    const isAlreadyBookmarked = bookmarks.some((item) => item.id === monitoringId);
    setIsBookmarked(true);
    if (!isAlreadyBookmarked) {
      bookmarks.push({ id: monitoringId, data: data });
      localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
    }
  }, [data, monitoringId]);

  React.useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes(monitoringId)) {
      setEventDetailsShareLink(currentUrl);
    } else {
      setEventDetailsShareLink(`${currentUrl}/${monitoringId}`);
    }
  }, [monitoringId]);

  const lastFour = data?.footprint?.slice(-5, -1).reverse();

  const maxValue = data?.coverage?.exit || 1;
  const triggerPercent = data?.coverage?.exit
    ? Math.min((data?.coverage?.trigger * 100) / data?.coverage?.exit, 100)
    : data?.coverage?.trigger > data.indexValue
    ? Math.min((data?.coverage?.trigger * 100) / (data?.coverage?.trigger * 1.2), 100)
    : Math.min((data?.coverage?.trigger * 100) / data.indexValue, 100);

  const exitPercent = Math.min(Math.min((data?.coverage?.exit * 100) / maxValue, 100));
  const actual = data?.coverage?.exit
    ? Math.min((data.indexValue * 100) / maxValue, 100)
    : data?.coverage?.trigger > data.indexValue
    ? Math.min((data.indexValue * 100) / data?.coverage?.trigger, 100)
    : 100;
  const actualWidth = `${
    data?.coverage?.exit
      ? Math.min((data.indexValue * 100) / maxValue, 100)
      : data?.coverage?.trigger > data.indexValue
      ? Math.min((data.indexValue * 100) / data?.coverage?.trigger, 100)
      : 100
  }%`;

  const checkColor = data?.coverage?.exit
    ? Boolean(
        (data?.coverage?.trigger * 100) / data?.coverage?.exit < (data.indexValue * 100) / maxValue,
      )
    : Boolean(
        (data?.coverage?.trigger * 100) / data?.coverage?.trigger <
          (data.indexValue * 100) / data?.coverage?.trigger,
      );

  const triggerWidth = `${triggerPercent}%`;

  const payout = Math.max(0, Math.min(data?.coverage?.payoutPerUnitPct, 1));
  const redOpacity = 0.2 + payout * 0.6;

  const backgroundHeaderCard =
    payout === 0 || !isTriggered
      ? "rgba(49, 209, 88, 0.15)"
      : payout > 0 && payout < 70 && actual < 80
      ? `rgba(244, 67, 54, ${redOpacity})`
      : "rgba(244, 67, 54, 0.8)";

    

  return (
    <Box className={classes.root}>
      <Box
        className={classes.headerCard}
        sx={{
          backgroundColor: backgroundHeaderCard,
        }}
      >
        <Box className={classes.flexRow} marginBottom="24px">
          <Box className={classes.indexTitle}>
            <Typography variant="body2" className={classes.indexDefinitionName}>
              {data?.coverage?.label}
            </Typography>
            <Typography variant="body2" className={classes.dateTitleText}>
              {Time.getDateTime(data?.footprint?.[data?.footprint?.length - 1]?.timestampUtc)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {lastCategory.colour === "Yellow" ? (
              <PriorityHighIcon sx={{ color: "#d6c426", fontSize: "medium" }} />
            ) : (
              ""
            )}
            {getIconComponent(lastCategory.icon, lastCategory.colour)}
            <Typography variant="body2" className={classes.dateTitleText} marginLeft="8px">
              {data?.footprint
                ? data?.footprint?.[data?.footprint?.length - 1]?.measurements[
                    data?.footprint?.[data?.footprint?.length - 1]?.measurements.length - 1
                  ].value.toFixed(1)
                : "-- "}
              {data?.footprint
                ? ` ${data?.footprint?.[data?.footprint?.length - 1]?.unit?.symbol}`
                : ""}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.mainValue} marginBottom="20px">
          <Typography
            className={classes.daysCount}
            sx={{
              color: isTriggered ? "#fff" : "rgba(49, 209, 88, 1)",
            }}
          >{`${data.indexValue.toFixed(1)} ${plural(
            convertUnit(data?.indexUnit?.symbol),
            Number(data.indexValue.toFixed(1)),
          )}`}</Typography>
          <img
            src={isTriggered ? RedTree : GreenTree}
            alt={isTriggered ? "Red Tree" : "Green Tree"}
            className={classes.mainIcon}
          />
        </Box>
      </Box>
      <Box className={classes.progressContainer}>
        <BorderLinearProgress
          variant="determinate"
          value={
            data?.coverage?.exit
              ? Math.min((data.indexValue * 100) / maxValue, 100)
              : data?.coverage?.trigger > data.indexValue
              ? Math.min((data.indexValue * 100) / data?.coverage?.trigger, 100)
              : 100
          }
          className={classes.progressBar}
          sx={{
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor:
                data?.coverage?.indexDefinition?.triggerComparator === "GT"
                  ? data.indexValue > data?.coverage?.trigger
                    ? "rgba(244, 67, 54, 1)"
                    : "#31D158"
                  : data.indexValue < data?.coverage?.trigger
                  ? "rgba(244, 67, 54, 1)"
                  : "#31D158",
            },
          }}
        />
        {checkColor ? (
          <ColorSegment
            color={`${
              data.coverage.indexDefinition.triggerComparator === "GT" ? "#31D158" : "rgba(244, 67, 54, 1)"
            } `}
            width={data?.coverage?.trigger ? triggerWidth : "0%"}
          />
        ) : (
          ""
        )}
        {checkColor ? (
          <ColorSegment
            color={`${
              data.coverage.indexDefinition.triggerComparator === "GT" ? "rgba(244, 67, 54, 1)" : "#31D158"
            } `}
            width={`calc(100% - ${triggerWidth - actualWidth})`}
            sx={{
              right: 0,
              zIndex: 0,
            }}
          />
        ) : (
          ""
        )}
        {data?.coverage?.trigger ? (
          <Box className={classes.bar} sx={{ left: `min(${triggerPercent}%, calc(100% - 3px))` }} />
        ) : (
          ""
        )}
        {data?.coverage?.exit ? (
          <Box className={classes.bar} sx={{ left: `min(${exitPercent}%, calc(100% - 3px))` }} />
        ) : (
          ""
        )}
      </Box>
      <Box display="flex" flexDirection="column" flex={1} marginTop="8px">
        <Box
          className={classes.triggerDetail}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          padding="0 16px"
        >
          <Typography className={classes.subtitle}>
            {data?.coverage?.indexDefinition?.output?.description}{" "}
            {`from ${dayjs(data?.coverage?.start).format("DD-MMM")} to ${dayjs(
              data?.coverage?.end,
            ).format("DD-MMM YYYY")}`}
          </Typography>
          <Box className={classes.flexRow} gap="18px">
            <Typography className={classes.trigger}>
              {data?.coverage?.trigger
                ? data?.coverage?.indexDefinition?.triggerComparator === "GT"
                  ? ">"
                  : "<"
                : ""}
              {"   "}
              {data?.coverage?.trigger}{" "}
              {plural(
                convertUnit(data.coverage?.indexDefinition?.output?.unit?.symbol),
                data?.coverage?.trigger,
              )}
            </Typography>
            <Typography className={classes.trigger}>
              {" "}
              Exit: {data?.coverage?.exit ? data?.coverage?.exit : "--"}{" "}
              {data?.coverage?.exit
                ? plural(
                    convertUnit(data.coverage?.indexDefinition?.output?.unit?.symbol),
                    data?.coverage?.exit,
                  )
                : ""}
            </Typography>
          </Box>
        </Box>

        <Box mt={2} className={classes.valueDaily} padding="0 16px">
          {lastFour?.map((item) => (
            <Box className={classes.rainDay}>
              <Box className={classes.flexItem}>
                <Typography variant="body2" className={classes.dateText}>
                  {Time.getDate(item?.timestampUtc)}
                </Typography>
                <Typography variant="body2" className={classes.dateAdd}>
                  {item?.indexChange ? `${item?.indexChange.toFixed(1)}` : ""}
                </Typography>
              </Box>
              <Box className={classes.rainDay} gap="3px">
                <Box display="flex" justifyContent="flex-end" marginRight={1}>
                  {lookupCategory(
                    item?.measurements[item?.measurements.length - 1].value.toFixed(1),
                  ).colour === "Yellow" ? (
                    <PriorityHighIcon
                      sx={{ color: "#d6c426", fontSize: "medium", marginTop: "2px" }}
                    />
                  ) : (
                    ""
                  )}
                  {getIconComponent(
                    lookupCategory(
                      item?.measurements[item?.measurements.length - 1].value.toFixed(1),
                    ).icon,
                    lookupCategory(
                      item?.measurements[item?.measurements.length - 1].value.toFixed(1),
                    ).colour,
                  )}
                </Box>
                <Typography variant="body2" className={classes.dateText}>
                  {`${item?.measurements[item?.measurements.length - 1].value.toFixed(1)} ${plural(
                    convertUnit(item?.measurements[item?.measurements.length - 1]?.variable?.unit?.symbol),
                    Number(item?.measurements[item?.measurements.length - 1].value.toFixed(1)),
                  )}`}
                </Typography>
              </Box>
            </Box>
          ))}
          <Typography variant="body2" className={classes.dataSource}>
            {data?.coverage?.dataSourceDescription}
          </Typography>
        </Box>
      </Box>

      <Box marginTop="auto" className={classes.footerCard}>
        {/* <Typography
          variant="body2"
          className={classes.historyButton}
          onClick={() => {
            window.location.href = `/monitoring/event/${data?.policyItems?.eventId}`;
          }}
        >
          VIEW HISTORY
        </Typography> */}
        <Box className={classes.tool}>
          <Box className={classes.Inform}>
            <img
              src={InformIcon}
              alt="inform Icon"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            {showTooltip && (
              <Box className={classes.indexDefinition}>
                <Typography variant="body2" className={classes.indexDefinitionTitle} color="#fff">
                  Index Definition
                </Typography>
                <Typography variant="body2" className={classes.indexDefinitionSub} color="#fff">
                  {data?.coverage?.indexDefinition?.description}
                </Typography>
              </Box>
            )}
          </Box>
          <img
            src={ShareIcon}
            alt="Share Icon"
            onClick={handleOpenModal}
            className={classes.toolIcon}
          />
          {isBookmarked ? (
            <BookmarkIcon className={classes.toolIcon} onClick={handleBookmarkClick} />
          ) : (
            <BookmarkBorderIcon className={classes.toolIcon} onClick={handleBookmarkClick} />
          )}
        </Box>
      </Box>

      <Box className={classes.logo}>
          <Typography sx={{fontSize: 13}}> Operated by </Typography>
          <Box sx={{width: 100, mt: "4px"}}>
            <img src={Logo} alt="Logo" width={"100%"}/>
          </Box>
      </Box>

      <EventsDetailShareModal
        open={openEventDetailsShareModal}
        onClose={handleCloseEventDetailsShareModal}
        value={eventDetailsShareLink}
        error={false}
        isGeneratingLink={false}
      />
    </Box>
  );
};

export default EventDetailsExternalCard;
