import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoPng from "../../assets/images/logo.png";

const useStyles = makeStyles(() => ({
  containerFooter: {
    padding: "120px 0",
    backgroundColor: "#222222"
  },
  topContent: {
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
    display: "flex",
    gap: "2rem",
    marginBottom: "2rem"
  },
  box: {
    maxWidth: 1274,
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between"
  },
  bottomContent: {
    padding: "27px 0",
    backgroundColor: "#2e2e2e",
    "& p": {
      fontWeight: 400,
      fontSize: 16,
      color: "#7a7a7a"
    },
    "& a": {
      textDecoration: "none",
      color: "#fff",
      "& svg": {
        width: 15,
        height: 18,
        fill: "#fff"
      }
    }
  },
  leftSide: {
    flex: 1,
    "& p": {
      fontWeight: 400,
      fontSize: 16,
      color: "#efefef",

      "&:last-child": {
        marginTop: 25
      }
    },
    "& img": {
      marginBottom: 40,
    }
  },
  rightSide: {
    flex: 1,
    display: "flex",
    color: "#fff",
    gap: "5rem"

  },
  menu: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

  },
  itemMenu: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    height: "26px",
    "&::before": {
      content: "''",
      width: 8,
      height: 8,
      borderRadius: "8px",
      backgroundColor: "#fff"
    },
    "& a": {
      color: "#7a7a7a",
      fontFamily: "Open Sans, sans-serif",
      textDecoration: "none",
      transition: "all 0.4s ease",
      "&:hover": {
        color: "#FFFFFF"
      }
    }
  },
  info: {
    "& p": {
      color: "#efefef",
      fontWeight: "bolder",
      fontSize: 16,
    },
    "& a": {
      display: "block",
      textDecoration: "none",
      color: "#7a7a7a",
      marginTop: "25px",
      fontFamily: "Open Sans, sans-serif",
      transition: "all 0.4s ease",
      "&:hover": {
        color: "#FFFFFF"
      }
    }
  }
}));

const MENU = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Blog",
    href: "/blog",
  },
  {
    label: "Technology",
    href: "/#platform",
  },
  {
    label: "Innovations",
    href: "/#use-cases",
  },
  {
    label: "Team",
    href: "/#team",
  },
  ,
  {
    label: "Contact",
    href: "/#contact",
  },
  ,
  {
    label: "Privacy Terms",
    href: "/terms",
  },
];

const Footer = () => {
  const classes = useStyles();

  return <Box className={classes.containerFooter}>
    <Box className={classes.topContent}>
      <Box className={classes.leftSide}>
        <img src={LogoPng} alt="logo" />
        <Typography>With Riskwolf, you can turn real-time data into insurance. Using unique real-time data and dynamic risk modelling, we enable insurers to build and operate parametric insurance at scale.
        </Typography>
        <Typography>Simple. Reliable. Fast.</Typography>

      </Box>
      <Box className={classes.rightSide}>
        <Box className={classes.menu} >
          {
            MENU.map(e => {
              return <Box key={e.label} className={classes.itemMenu}>
                <a href={`https://www.riskwolf.com${e.href}`} target="_blank">{e.label}</a>
              </Box>
            })
          }
        </Box>
        <Box className={classes.info}>
          <Typography>Riskwolf AG</Typography>
          <Typography>Neeserweg 16</Typography>
          <Typography>8048 Zurich</Typography>
          <Typography>Switzerland</Typography>
          <a href="mailto:office@riskwolf.com">office@riskwolf.com</a>
        </Box>
      </Box>
    </Box>
    <Box className={classes.bottomContent}>
      <Box className={classes.box}>
        <Typography>Copyright 2024, Riskwolf AG. All Rights Reserved.</Typography>
        <a target="_blank" href="http://www.linkedin.com/company/riskwolf"><IconLinked /></a>
      </Box>
    </Box>
  </Box>
};

const IconLinked = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="256" height="256" viewBox="0 0 256 256" >
    <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
      <path d="M 1.48 29.91 h 18.657 v 60.01 H 1.48 V 29.91 z M 10.809 0.08 c 5.963 0 10.809 4.846 10.809 10.819 c 0 5.967 -4.846 10.813 -10.809 10.813 C 4.832 21.712 0 16.866 0 10.899 C 0 4.926 4.832 0.08 10.809 0.08" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
      <path d="M 31.835 29.91 h 17.89 v 8.206 h 0.255 c 2.49 -4.72 8.576 -9.692 17.647 -9.692 C 86.514 28.424 90 40.849 90 57.007 V 89.92 H 71.357 V 60.737 c 0 -6.961 -0.121 -15.912 -9.692 -15.912 c -9.706 0 -11.187 7.587 -11.187 15.412 V 89.92 H 31.835 V 29.91 z" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
    </g>
  </svg>
};

export default Footer;