// FilesContainer.js
import React, {useMemo, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useFiles} from "../../hooks/files/useFiles";
import FilesPage from "./FilesPage";
import {useFileLabels} from "../../hooks/files/useFileLabels";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // Remove any excessive padding or margin
    padding: 0,
    margin: 0
  }
}));

const FilesContainer = () => {
  const classes = useStyles();

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handlePageChange = (newPage) => {
    setPagination((prevState) => ({...prevState, page: newPage}));
  };

  // Add this handler for rows per page changes
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPagination((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
      page: 0, // Reset to first page when changing rows per page
    }));
  };

  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [selectedFacets, setSelectedFacets] = useState({});

  const {
    query: {data: files, isLoading: filesLoading},
    downloadFile
  } = useFiles({
    page: pagination.page,
    size: pagination.rowsPerPage,
    labels: selectedFacets  // Pass the selected facets directly to useFiles
  });

  const {query: {data: labelsData, isLoading: labelsLoading}} = useFileLabels();

  const handleFacetChange = (newSelectedFacets) => {
    setSelectedFacets(newSelectedFacets);
    // Reset to first page when filters change
    setPagination(prev => ({...prev, page: 0}));
  };

  // Create a mapping object to translate between string tags and category/value pairs
  const [labelMapping, setLabelMapping] = useState({});

  // Transform the labels data from the API into string format expected by FacetedSearch
  const transformedLabels = useMemo(() => {
    if (!labelsData) {
      return [];
    }

    // Create a flat array of strings for FacetedSearch
    const stringLabels = labelsData.flatMap(item =>
        item.values.map(value => `${item.key}: ${value}`)
    );

    // Update the mapping object for later reference
    const newMapping = {};
    labelsData.forEach(item => {
      item.values.forEach(value => {
        const tagString = `${item.key}: ${value}`;
        newMapping[tagString] = {category: item.key, value};
      });
    });

    setLabelMapping(newMapping);
    return stringLabels;
  }, [labelsData]);

  const transformedFiles = useMemo(() => {
    if (!files?.content) {
      return [];
    }

    return files.content.map(file => ({
      id: file.id,
      fileName: file.fileName,
      description: file.description,
      contentType: file.contentType,
      fileSize: formatFileSize(file.size), // Using size for fileSize
      labels: file.labels,
      updatedAt: formatDate(file.updatedAt), // Using updatedAt as the date field
      creator: file.createdBy, // Now properly mapping createdBy
    }));
  }, [files]);

  // Convert from API format to FacetedSearch format for selected tags
  const selectedTagsForFacetedSearch = useMemo(() => {
    const selected = {};

    Object.entries(selectedFacets).forEach(([category, values]) => {
      values.forEach(value => {
        const tagString = `${category}: ${value}`;
        selected[tagString] = tagString;
      });
    });

    return selected;
  }, [selectedFacets]);

  const handleGetSuggestions = (inputValue) => {
    if (!transformedLabels.length) {
      return [];
    }

    // Filter the labels based on user input
    return transformedLabels.filter(label =>
        label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loaded = !filesLoading && !labelsLoading;

  return (
      <FilesPage
          files={transformedFiles}
          loaded={!filesLoading}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          totalElements={files?.totalElements || 0}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onDownload={downloadFile}
          facets={labelsData}
          selectedFacets={selectedFacets}
          onFacetChange={handleFacetChange}
      />
  );
};

export default FilesContainer;