import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/material';
import { ButtonSuccess } from '../Buttons/Buttons';
import CoveragesSearch from '../Coverages/CoveragesSearch';
import { useCoverageContext } from '../../Contexts/CoverageContext';

const ToolboxCoverages = props => {
  const {setSelectedCoverages} = useCoverageContext();

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'sticky',
      top: 64,
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.appBar,
    },
    Toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },
    title: {
      marginRight: theme.spacing(2)
    }
  }));

  const handleClickTermSheet = () => {
    setSelectedCoverages(props.selected);
  }

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.Toolbar}>
        <Typography className={classes.title} variant="h6">
          Coverages
        </Typography>
        <CoveragesSearch
          handleReload={props.onReload}
          view={props.view}
          handleView={props.handleView}
          mapInteraction={props.mapInteraction}
          handleInteraction={props.handleInteraction}
          {...{ onSearchChange: props.onSearchChange, refreshLoading: props.refreshLoading }}
        />
        <Stack direction="row">
          {props.selected.length > 1 && !props.selected.find(({ canActivate }) => !canActivate) && <ButtonSuccess onClick={props.handleOpen}>Activate {props.selected.length} Coverages</ButtonSuccess>}
          {!!props.selected.length && (
            <Button variant="contained" onClick={handleClickTermSheet} component={RouterLink} to="/coverages/term-sheet" color="primary" sx={{ml: 2}} disableElevation>Show Term-Sheet</Button>
          )}
          <Button variant="contained" onClick={props.onCreateNew} color="primary" sx={{ml: 2}} disableElevation>New Coverage</Button>
        </Stack>
      </Toolbar>
    </Box>
  );
};

export default ToolboxCoverages;
