import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, styled } from '@mui/material';

// Styled button component that follows the design from ux-pilot-components.html
const StyledButton = styled(MuiButton)(({ theme, variant, color }) => ({
  textTransform: 'uppercase',
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  borderRadius: '0.5rem', // rounded-lg
  padding: '0.5rem 1.5rem', // px-6 py-2 
  fontSize: '0.875rem', // text-sm
  fontWeight: 500,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  ...(variant === 'contained' && color === 'primary' && {
    backgroundColor: '#191920', // Black background as shown in HTML
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#2a2a33',
    },
  }),
  ...(variant === 'contained' && color === 'secondary' && {
    backgroundColor: '#ffe034', // Yellow background from HTML
    color: 'rgba(0, 0, 0, 0.9)', // text-black/90
    '&:hover': {
      backgroundColor: '#ffd700',
    },
  }),
  ...(variant === 'outlined' && {
    border: '1px solid rgba(0, 0, 0, 0.4)', // border-black/40
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      border: '1px solid rgba(0, 0, 0, 0.4)',
    },
  }),
  ...(variant === 'text' && color === 'error' && {
    color: '#f44336', // Red color from HTML
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.04)',
    },
  }),
}));

/**
 * RwButton component that provides button variants as shown in the design
 */
const Button = ({ 
  label,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  startIcon,
  endIcon,
  fullWidth,
  disabled,
  onClick,
  ...props 
}) => {
  return (
    <StyledButton
      variant={variant}
      color={color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {label}
    </StyledButton>
  );
};

Button.propTypes = {
  /**
   * Button label text
   */
  label: PropTypes.string.isRequired,
  /**
   * Button variant
   */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  /**
   * Button color
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error']),
  /**
   * Button size
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Icon to display before label
   */
  startIcon: PropTypes.node,
  /**
   * Icon to display after label
   */
  endIcon: PropTypes.node,
  /**
   * Whether the button should take up the full width of its container
   */
  fullWidth: PropTypes.bool,
  /**
   * Whether the button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Click handler
   */
  onClick: PropTypes.func,
};

export default Button;
