import React, { useContext } from 'react';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  Timeline,
  timelineItemClasses,
  TimelineOppositeContent,
} from '@mui/lab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AirIcon from '@mui/icons-material/Air';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as CloudRainIcon } from '../../assets/images/cloud-with-rain.svg';
import { ReactComponent as TemperatureRiseIcon } from '../../assets/images/temperature-rise.svg';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import PaymentIcon from '@mui/icons-material/Payment';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import { EventTimeLineCard } from './EventTimeLineCard';
import dayjs from 'dayjs';
import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Time from '../../Util/time';
import ContextTheme from '../../Contexts/Theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  iconContainer: {
    margin: 0,
    minWidth: 28,
    minHeight: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #00000026",
  },
  button: {
    padding: 0,
  },
  time: {
    fontSize: 12,
    letterSpacing: 0.16,
    fontWeight: 400,
  },
  hideText: {
    '& p': {
      color: "white",
      userSelect: "none",
    }
  },
  date: {
    fontWeight: 600,
    fontSize: 12,
  },
  opposite: {
    flex: 0,
    textAlign: "left",
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  arrowButton: {
    color: "#000",
    padding: 0,
  },
  status: {
    backgroundColor: theme.palette.running,
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 600,
    padding: "6px 14px",

    "& > .MuiChip-label": {
      padding: 0,
    },
  },
  content: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  asOf: {
    fontWeight: 600,
    fontSize: 12,
  }
}));

export const renderEventIcon = (icon) => {
  switch (icon) {
    case "RAINFALL":
      return <CloudRainIcon />;
    case "TEMPERATURE":
      return <TemperatureRiseIcon />;
    case "CLOUD":
      return <AirIcon />;
    case "ICT_SHOCK":
      return <ElectricBoltIcon />;
    case "CDN":
      return <AirIcon />;
    case "PAYMENT":
      return <PaymentIcon />;
    case "TC":
      return <InsertChartIcon />;
    case "SOLAR":
      return <SolarPowerIcon />;
    default:
      return null;
  }
};

const getStatusDescription = (status) => {
  switch (status) {
    case "MONITORED":
      return "Event is in date range, but no payout yet.";
    case "DETECTED":
      return "Event is in date range, with some payout.";
    case "QUALIFIED":
      return "Event is outside date range, with some payout or 100% payout.";
    case "NON_QUALIFIED":
      return "Event is outside date range, but no payout.";
    case "UNKNOWN":
      return "No footprint data to store, or default case.";
    case "RECONCILED":
      return "Event has been reconciled with a secondary data source or process.";
    case "REPORTED":
      return "Event has been officially reported to the insurer or relevant authorities for further processing.";
    case "IN_REVIEW":
      return "Claim event is under review, undergoing detailed assessment and investigation for validity.";
    case "CONFIRMED":
      return "The insurer has reviewed the event and determined it meets the criteria for coverage as outlined in the policy terms.";
    case "REJECTED":
      return "The insurer has reviewed the event and determined it does not meet the criteria for coverage as outlined in the policy terms.";
    case "PAYOUT":
      return "Event has resulted in a payout, either partial or full, indicating completion of the payout process.";
    case "NO_PAYOUT":
      return "Event has resulted in no payout, indicating completion of the payout process.";
    case "TERMINATED":
      return "Event or associated policy has been terminated prematurely due to various reasons such as breach or fraud.";
    default:
      return "";
  }
};

export const EventTimeLine = ({ events, coordinates, zoom, bounds, variablesSelected }) => {
  const classes = useStyles();
  const { locale } = useContext(ContextTheme);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        pt: 0,
        pb: 0,
      }}
      className={classes.root}
    >
      {events
        .filter((item) => item.parametricEvent)
        .map((event, index) => {
          const hideTime = index > 0 && events[index - 1]?.parametricEvent?.runTime === event.parametricEvent.runTime;

          return (
            <TimelineItem key={`timeline-item-${index}`}>
              <TimelineOppositeContent className={classes.opposite}>
                {event.parametricEvent && (
                  <Tooltip title="Execution time of the last data evaluation">
                    <Box mt={1} className={hideTime && classes.hideText}>
                      <Typography className={classes.asOf}>As of</Typography>
                      <Typography className={classes.date} noWrap>
                        {Time.getDate(event.parametricEvent.runTime, locale)}
                      </Typography>
                      <Typography className={classes.time} noWrap>
                        {Time.getTime(event.parametricEvent.runTime, locale)}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector sx={{ height: 14, flexGrow: "unset" }} />
                <TimelineDot color="primary" variant="outlined" className={classes.iconContainer}>
                  {renderEventIcon(event.indexDefinition?.riskTypeId)}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.content} sx={{ py: "12px", px: 2 }}>
                <Box>
                  <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                    <Stack direction="row" gap={1.25} alignItems="center">
                      <Typography variant="h6" component="span" className={classes.title}>
                        {event?.parametricEvent?.coverage?.label ?? "No label"}
                      </Typography>
                      {event.parametricEvent.status && (
                        <Tooltip title={getStatusDescription(event.parametricEvent.status)}>
                          <Chip
                            className={classes.status}
                            label={event.parametricEvent.status}
                            sx={{ backgroundColor: "#FFE034", textTransform: "uppercase" }}
                          />
                        </Tooltip>
                      )}
                    </Stack>
                    {event.parametricEvent && (
                      <Tooltip title="View detail">
                        <IconButton
                          component={RouterLink}
                          to={`/monitoring/event/${event?.parametricEvent?.id}?coordinates=${coordinates?.lng},${coordinates?.lat},${zoom}&bounds=${bounds}&variablesSelected=${variablesSelected}`}
                          variant="outlined"
                          size="small"
                          className={classes.arrowButton}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Box>
                <Box>
                  <EventTimeLineCard
                    trigger={event?.parametricEvent?.coverage?.trigger}
                    unit={event?.parametricEvent?.coverage?.indexDefinition?.output?.unit?.symbol}
                    index={event?.indexDefinition?.triggerLabel}
                    actual={event?.parametricEvent?.indexValue}
                    indexUnit={event?.parametricEvent?.coverage?.indexDefinition?.output?.unit?.symbol}
                    exit={event?.parametricEvent?.coverage?.exit ?? 0}
                    payoutPct={event?.parametricEvent?.payoutPct}
                    comparator={event?.indexDefinition?.triggerComparator}
                    dataProvider={event?.parametricEvent?.coverage?.dataSourceDescription}
                  />
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
};
