import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "QUERY_FILES";

/**
 * @param {Object} param0
 * @param {number} param0.page - Page number
 * @param {number} param0.size - Items per page
 * @param {Object} param0.labels - Map of labels where key is the label name and value is the label value
 * @returns {Object} Query result and download function
 */
export const useFiles = ({ page, size, labels = {} }) => {
  const downloadFile = async (fileId, fileName) => {
    try {
      const token = await GetToken();
      const url = `${window.appConfig.apiUrl}/internal/files/${fileId}`;

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Download failed');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${fileName}`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  return {
    query: useQuery({
      queryKey: [QUERY_KEY, { page, size, labels }],
      queryFn: async () => {
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('size', size);

        if (Object.keys(labels).length > 0) {
          const labelString = Object.entries(labels)
          .filter(([_, value]) => value)
          .map(([key, value]) => `${key}:${value}`)
          .join(';');

          if (labelString) {
            params.append('labels', labelString);
          }
        }

        const token = await GetToken();
        const url = new URL(`${window.appConfig.apiUrl}/internal/files/find-by-labels?${params.toString()}`);

        const response = await Ajax.getData(url.href, token);
        return response;
      },
      refetchOnWindowFocus: false,
      enabled: true,
    }),
    downloadFile
  };
};