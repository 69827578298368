import { useParams } from "react-router-dom";
import { usePolicyReport } from "../../hooks/policy";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import formatMissingValue from "../../Util/formatMissingValue";
import { Box, Collapse, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled, makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ContextTheme from "../../Contexts/Theme";
import Time from "../../Util/time";
import String from "../../Util/string";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMonitoringEventDetail } from "../../hooks/monitoring/useMonitoringEventDetail";
import { RainfallChart, TemperatureChart } from "../EventDetails/FootprintChart";
import { convertUnit } from "../../Util/convertUnit";
import plural from "../../Util/pluralize";
import { formatNumber } from "../../Util/formatNumber";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: "100%",
    overflowX: "auto",
    "& .MuiButtonBase-root": {
      padding: 0,
    },
  },
  tableHead: {
    backgroundColor: theme.palette.stripe,
    textTransform: "uppercase",
  },
  container: {
    padding: 0,
  },
  bar: {
    width: 3,
    height: "100%",
    backgroundColor: "#000",
    position: "absolute",
    top: 0,
    transform: "translateX(-1px)",
  },
  progressContainer: {
    position: "relative",
  },
  progressBar: {
    marginTop: 12,
    marginBottom: 12,
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.lavenderMist,
    },
  },
  defaultText: { fontWeight: 400, fontSize: "0.875rem", lineHeight: 1.43 },
  card: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    boxShadow: "0px 3px 12px 0px #0000001F",
    flex: 1,
  },
  disclaimer: {
    textAlign: "center",
    padding: 16,
    marginTop: 60,
    fontWeight: 600,
    background: theme.palette.basicGray,
    fontSize: 16,
  },
}));

const COLUMNS = [
  null,
  "Monitoring Target",
  "Coverage",
  "Reporting Period",
  "Current Index",
  "Index Tracker",
  "Payout",
  null,
];

const formatCurrency = (currency, amount) =>
  amount ? `${currency} ${formatNumber(amount, currency)}` : '---';

const ColorSegment = styled(Box)(({ theme, color, width, borderRadius }) => ({
  position: 'absolute',
  height: '100%',
  backgroundColor: color,
  width: width,
  top: "0",
  borderRadius: borderRadius,
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  borderRadius: 7,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
  },
}));

const Row = (row) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const { locale } = useContext(ContextTheme);

  const maxValue = row.exit || 1;
  const triggerPercent = row.exit
    ? Math.min((row.trigger * 100) / row.exit, 100)
    : row.trigger > row.indexValue ?  Math.min((row.trigger * 100) / (row.trigger * 1.2), 100) : Math.min((row.trigger * 100) / row.indexValue , 100)

  const exitPercent = Math.min(Math.min(row.exit * 100 / maxValue, 100))
  const actualWidth = `${row.exit
    ? Math.min((row.indexValue * 100) / maxValue, 100)
    : row.trigger > row.indexValue ? Math.min((row.indexValue * 100) / row.trigger, 100): 100}%`;

  const checkColor = row.exit
    ? Boolean((row.trigger * 100) / row.exit < (row.indexValue * 100) / maxValue)
    : Boolean((row.trigger * 100) / row.trigger < (row.indexValue * 100) / row.trigger);

  const triggerWidth = `${triggerPercent}%`;

  const location = useLocation();

  const { data } = useMonitoringEventDetail(
    {
      id: row.eventId,
      page: 0,
      size: 15,
    },
    isOpen,
  );

  const riskTypeId = data?.coverage?.riskTypeId;
  const lowerChartTitle = data?.coverage?.indexDefinition?.name;

  return (
    <Fragment key={row.policyId}>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{formatMissingValue(row.coverageLabel)}</TableCell>
        <TableCell>{formatMissingValue(row.coverage)}</TableCell>
        <TableCell>
          {Time.getDate(row.startTime, locale)} -{" "}
          {Time.getDate(row.lastReportTime, locale)}
        </TableCell>
        <TableCell>
          {row.indexUnit?.symbol === "day"
            ? row?.indexValue
            : Number(row.indexValue).toFixed(1).toLocaleString()}{" "}
          {plural(convertUnit(row.indexUnit?.symbol), row?.indexValue)}
        </TableCell>
        <TableCell>
          <Box className={classes.progressContainer}>
            <BorderLinearProgress
              variant="determinate"
              value={
                row.exit
                  ? Math.min((row.indexValue * 100) / maxValue, 100)
                  : row.trigger > row.indexValue
                  ? Math.min((row.indexValue * 100) / row.trigger, 100)
                  : 100
              }
              className={classes.progressBar}
              sx={{
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor:
                    row.comparator === "GT"
                      ? row.indexValue > row.trigger
                        ? "#F44336"
                        : "#31D158"
                      : row.indexValue < row.trigger
                      ? "#F44336"
                      : "#31D158",
                },
              }}
            />
            {checkColor ? (
              <ColorSegment
                color={`${row.comparator === "GT" ? "#31D158" : "#F44336"} `}
                width={row.trigger ? triggerWidth : "0%"}
                borderRadius="7px 0px 0px 7px"
              />
            ) : (
              ""
            )}
            {checkColor ? (
              <ColorSegment
                color={`${row.comparator === "GT" ? "#F44336" : "#31D158"} `}
                width={`calc(100% - ${triggerWidth - actualWidth})`}
                borderRadius="0px 7px 7px 0px"
                sx={{
                  right: 0,
                  zIndex: 0,
                }}
              />
            ) : (
              ""
            )}
            {row.trigger ? (
              <Box
                className={classes.bar}
                sx={{ left: `min(${triggerPercent}%, calc(100% - 3px))` }}
              />
            ) : (
              ""
            )}
            {row.exit ? (
              <Box
                className={classes.bar}
                sx={{ left: `min(${exitPercent}%, calc(100% - 3px))` }}
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>
        <TableCell>
          {formatCurrency(row.calculatedPayout?.currency, row.calculatedPayout?.amount)}
        </TableCell>
        <TableCell>
          <IconButton
            component={RouterLink}
            to={`/monitoring/event/${row.eventId}?backTo=${location.pathname}&tab=report`}
            variant="outlined"
            size="small"
            className={classes.arrowButton}
          >
            <ArrowForwardIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} pl={6}>
              <Typography variant="h6" gutterBottom component="div">
                {row.indexDefinition?.name}{" "}
                <Tooltip title="View coverage">
                  <IconButton
                    component={RouterLink}
                    to={`/coverages/coverage/${row?.coverageId}?backTo=${location?.pathname}&tab=report`}
                    variant="outlined"
                    size="small"
                    className={classes.arrowButton}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Box marginLeft={-2}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Beneficiary</TableCell>
                      <TableCell>{formatMissingValue(row?.beneficiaryName)}</TableCell>
                      <TableCell variant="head">Run Time</TableCell>
                      <TableCell>{Time.getDate(row.runTime, locale)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Insured Extend</TableCell>
                      <TableCell>
                        {formatMissingValue(data?.insuredExtendValue.toFixed(1) ?? 0)}{" "}
                        {plural(
                          convertUnit(data?.insuredExtendUnit),
                          Number(data?.insuredExtendValue),
                        )}{" "}
                      </TableCell>
                      <TableCell variant="head">
                        Trigger:{" "}
                        <Box component="span" className={classes.defaultText}>
                          {row?.trigger}{" "}
                          {row.trigger ? plural(row?.indexUnit.symbol, row.trigger) : "--"}
                        </Box>
                      </TableCell>
                      <TableCell variant="head">
                        Exit:{" "}
                        <Box component="span" className={classes.defaultText}>
                          {row?.exit} {row.exit ? plural(row?.indexUnit.symbol, row.exit) : "--"}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Sum Insured Per Extend</TableCell>
                      <TableCell>
                        {formatCurrency(
                          row?.coverageTotalSumInsured?.currency,
                          row?.coverageTotalSumInsured?.amount,
                        )}
                      </TableCell>
                      <TableCell variant="head">Data Source</TableCell>
                      <TableCell>{row?.dataSourceDescription}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              {riskTypeId === "TEMPERATURE" && (
                <TemperatureChart
                  data={data?.footprint ?? []}
                  triggers={data?.coverage?.indexDefinition?.triggers}
                />
              )}
              {riskTypeId === "RAINFALL" && (
                <RainfallChart
                  data={data?.footprint ?? []}
                  title={lowerChartTitle}
                  triggers={data?.coverage?.indexDefinition?.triggers}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export const PolicyReport = ({
  title,
  issuer,
  intermediary,
  inceptionDate,
  expiryDate,
  status,
  customerName,
  description,
  coverages,
  currency,
}) => {
  const { id } = useParams();
  const { data: reports } = usePolicyReport(id);
  const classes = useStyles();
  const { locale } = useContext(ContextTheme);
  const totalSumInsured = coverages?.reduce((acc, val) => acc + val.totalSumInsured.amount, 0) ?? 0;

  const headerCells = COLUMNS.map((col, index) => {
    return (
      <TableCell {...(index === 0 && { width: 8 })} key={index}>
        {col}
      </TableCell>
    );
  });

  const tableCells = (reports ?? []).map((row) => {
    const coverage = coverages.find(
      (coverage) => coverage.coverageId === row.coverageId,
    );

    row.dataSourceDescription = coverage?.dataSourceDescription;
    row.comparator = coverage?.indexDefinition.triggerComparator;

    return <Row key={row.id} {...row} />;
  });

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Box className={classes.card}>
        <Grid container spacing={4} mb={3}>
          <Grid xs={12} lg={6}>
            <Typography variant="h5" className={classes.title}>
              Basic Information
            </Typography>
            <Stack sx={{ mt: 4 }} rowGap={2.5}>
              <Grid container>
                <Grid sm={5}>
                  <Typography variant="body1">Policy Name</Typography>
                </Grid>
                <Grid sm={7}>
                  <Typography variant="body1" fontWeight={600}>
                    {title || "---"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sm={5}>
                  <Typography variant="body1">Policy Issuer</Typography>
                </Grid>
                <Grid sm={7}>
                  <Typography variant="body1" fontWeight={600}>
                    {issuer || "---"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sm={5}>
                  <Typography variant="body1">Policy Holder Name</Typography>
                </Grid>
                <Grid sm={7}>
                  <Typography variant="body1" fontWeight={600}>
                    {customerName || "---"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sm={5}>
                  <Typography variant="body1">Intermediary</Typography>
                </Grid>
                <Grid sm={7}>
                  <Typography variant="body1" fontWeight={600}>
                    {formatMissingValue(intermediary)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sm={5}>
                  <Typography variant="body1">Inception Date</Typography>
                </Grid>
                <Grid sm={7}>
                  <Typography variant="body1" fontWeight={600}>
                    {inceptionDate ? Time.getDate(inceptionDate, locale) : "---"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sm={5}>
                  <Typography variant="body1">Expiry Date</Typography>
                </Grid>
                <Grid sm={7}>
                  <Typography variant="body1" fontWeight={600}>
                    {expiryDate ? Time.getDate(expiryDate, locale) : "---"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid sm={5}>
                  <Typography variant="body1">Status</Typography>
                </Grid>
                <Grid sm={7}>
                  <Typography variant="body1" fontWeight={600}>
                    {String.capitalize(status) || "---"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
              <Grid sm={5}>
                <Typography variant="body1">Total Sum Insured</Typography>
              </Grid>
              <Grid sm={7}>
                <Typography variant="body1" fontWeight={600}>
                  {formatCurrency(currency, totalSumInsured)}
                </Typography>
              </Grid>
            </Grid>
            </Stack>
          </Grid>
          <Grid xs={12} lg={6}>
            <Typography variant="h5" className={classes.title}>
              Description
            </Typography>
            <Stack sx={{ mt: 4 }} rowGap={2.5}>
              {description?.split("\n").map((item) => {
                const [key, val] = item.split(":");

                return (
                  <Grid container key={key}>
                    <Grid sm={5}>
                      <Typography variant="body1">{key}</Typography>
                    </Grid>
                    <Grid sm={7}>
                      <Typography variant="body1" fontWeight={600}>
                        {formatMissingValue(val)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="Coverages table" size="medium">
            <TableHead className={classes.tableHead}>
              <TableRow>{headerCells}</TableRow>
            </TableHead>
            <TableBody>{tableCells}</TableBody>
          </Table>
        </TableContainer>
        <Typography className={classes.disclaimer}>
          THIS IS NOT A CLAIMS MONITORING REPORT. INTENDED TO BE USED FOR CONFIDENTIAL PURPOSES ONLY
        </Typography>
      </Box>
    </Container>
  );
};
