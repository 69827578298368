
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Paper,
  Chip,
  TextField,
  List,
  ListItem,
  ListItemText,
  styled,
  InputAdornment,
  Grow
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// Styled components
const SearchContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  width: '100%',
}));

const SearchInputContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem 1rem',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  borderRadius: '0.5rem',
  boxShadow: 'none',
  height: '42px',
  boxSizing: 'border-box',
  '&:hover': {
    boxShadow: 'none',
  }
}));

const SuggestionContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius * 1.5,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  zIndex: 10,
  overflow: 'hidden',
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
}));

const SuggestionItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
}));

const FacetedSearch = ({ facets, selectedFacets, onFacetChange, placeholder }) => {
  const [searchInput, setSearchInput] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const flattenedLabels = useMemo(() => {
    return facets?.flatMap(({ key, values }) =>
      values.map(value => `${key}: ${value}`)
    ) || [];
  }, [facets]);

  const suggestions = useMemo(() => {
    if (!searchInput) return flattenedLabels;
    return flattenedLabels.filter(label =>
      label.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [searchInput, flattenedLabels]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setTimeout(() => setIsFocused(false), 150);

  const handleTagSelect = (tagString) => {
    const [key, value] = tagString.split(':').map(s => s.trim());
    const updated = { ...selectedFacets };

    if (!updated[key]) updated[key] = [];
    if (!updated[key].includes(value)) {
      updated[key] = [...updated[key], value];
      onFacetChange(updated);
    }
    setSearchInput('');
  };

  const handleTagRemove = (tagString) => {
    const [key, value] = tagString.split(':').map(s => s.trim());
    const updated = { ...selectedFacets };
    updated[key] = updated[key].filter(v => v !== value);
    if (updated[key].length === 0) delete updated[key];
    onFacetChange(updated);
  };

  return (
    <SearchContainer>
      <SearchInputContainer>
        <TextField
          fullWidth
          placeholder={placeholder || "Search tags..."}
          variant="standard"
          value={searchInput}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          }}
        />
      </SearchInputContainer>

      <Grow in={isFocused}>
        <SuggestionContainer>
          <List>
            {suggestions.map((suggestion) => (
              <SuggestionItem
                key={suggestion}
                button
                onMouseDown={() => handleTagSelect(suggestion)}
              >
                <ListItemText primary={suggestion} />
              </SuggestionItem>
            ))}
          </List>
        </SuggestionContainer>
      </Grow>

      <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
        {Object.entries(selectedFacets).flatMap(([key, values]) =>
          values.map(value => {
            const tag = `${key}: ${value}`;
            return (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleTagRemove(tag)}
              />
            );
          })
        )}
      </Box>
    </SearchContainer>
  );
};

FacetedSearch.propTypes = {
  facets: PropTypes.array.isRequired,
  selectedFacets: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default FacetedSearch;
