import { Autocomplete, Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  containerInput: {
    position: "relative",
  },
  inputSearch: {
    background: "#fff",
    borderRadius: "8px",
    minWidth: "400px",

    "& .MuiFormControl-root .MuiInputBase-root": {
      borderRadius: "8px",
      paddingRight: 58
    },
    "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
      right: 32
    }
  },
  icon: {
    position: "absolute",
    top: "50%",
    right: 8,
    transform: "translateY(-50%)",
  }
}));

const InputSearch = ({ options, onChange, value }) => {
  const classes = useStyles();

  return <Box className={classes.containerInput}>
    <Autocomplete
      disablePortal
      value={value}
      className={classes.inputSearch}
      options={options ?? []}
      onChange={onChange}
      groupBy={(option) => option.key}
      getOptionLabel={(option) => option.value}
      renderInput={(params) => <TextField {...params} placeholder="Search for Location" />}
    />
    <IconFilter className={classes.icon} />
  </Box>
};

const IconFilter = (props) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.8337 2.5H4.16699C2.98866 2.5 2.39949 2.5 2.03366 2.84333C1.66699 3.18667 1.66699 3.74 1.66699 4.84583V5.42083C1.66699 6.285 1.66699 6.7175 1.88366 7.07583C2.10033 7.43417 2.49449 7.6575 3.28533 8.1025L5.71283 9.46917C6.24283 9.7675 6.50866 9.91667 6.69866 10.0817C7.09366 10.4242 7.33699 10.8275 7.44699 11.3233C7.50033 11.56 7.50033 11.8383 7.50033 12.3942V14.6192C7.50033 15.3767 7.50033 15.7558 7.71033 16.0508C7.92033 16.3467 8.29366 16.4925 9.03866 16.7842C10.6045 17.3958 11.387 17.7017 11.9437 17.3533C12.5003 17.0058 12.5003 16.21 12.5003 14.6183V12.3933C12.5003 11.8383 12.5003 11.56 12.5537 11.3225C12.6583 10.8368 12.9219 10.3999 13.3028 10.0808C13.492 9.91667 13.7578 9.7675 14.2878 9.46833L16.7153 8.10167C17.5053 7.6575 17.9012 7.435 18.117 7.07667C18.3337 6.71833 18.3337 6.285 18.3337 5.42V4.845C18.3337 3.74 18.3337 3.18667 17.967 2.84333C17.602 2.5 17.0128 2.5 15.8337 2.5Z" stroke="#1A1A21" stroke-width="1.5" />
  </svg>
};

export default InputSearch;
