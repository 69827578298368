import makeStyles from '@mui/styles/makeStyles';
import { Stack, Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const CoverageVariableList = (props) => {
  const { variables } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant='h5' className={classes.title}>List of Variables</Typography>
      <Stack sx={{mt: 2}} gap={1}>
        {variables.map((variable, index) => (
          <Stack key={`variable-${index}`} gap={1}>
            <Stack direction="row">
              <Typography variant="body1" fontWeight={600}>Dataset ID:</Typography>
              <Typography variant="body1" sx={{ml: 2}}>{variable.datasetId}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body1" fontWeight={600}>Variable ID:</Typography>
              <Typography variant="body1" sx={{ml: 2}}>{variable.variableId}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default CoverageVariableList;