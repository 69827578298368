import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ProgramAutocomplete } from './ProgramAutocomplete';
import { useCoverageContext } from '../../Contexts/CoverageContext';
import CoverageTable from './CoverageTable';
import programsData from '../Quotes/QuoteNew/QuoteNewProgram/rw-programs-demo.json'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '80px 24px 16px',
		flex: 1,
  },
  content: {
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
    flex: 1,
		overflow: 'hidden'
  },
	label: {
		fontSize: 18,
		fontWeight: 600,
	},
  icon: {
    fontSize: 80,
  },
}));

const TermSheet = () => {
  const classes = useStyles();
  const { selectedCoverages } = useCoverageContext();

	const [program, setProgram] = useState(programsData[0]);

	return (
		<Box className={classes.container}>
			<Box className={classes.content}>
				<Stack direction="row" p={3} gap={2} justifyContent="space-between">
					<Box>
						<ProgramAutocomplete value={program} onChange={(_, val) => setProgram(val)} />
						{!!program && (
							<Stack direction="row" gap={5} marginTop={4.5}>
								<Stack gap={1}>
									<Typography className={classes.label}>Industry</Typography>
									<Typography>{program?.InsuredValue}</Typography>
								</Stack>
								<Stack gap={1}>
									<Typography className={classes.label}>Insured Unit</Typography>
									<Typography>{program?.InsuredUnit}</Typography>
								</Stack>
								<Stack gap={1}>
									<Typography className={classes.label}>Country, Region</Typography>
									<Typography>{program?.Country}</Typography>
								</Stack>
								<Stack gap={1}>
									<Typography className={classes.label}>Currency</Typography>
									<Typography>{program?.Currency}</Typography>
								</Stack>
							</Stack>
						)}
					</Box>
					{!!program?.Icon && <i className={clsx(clsx('material-icons', program?.Icon), classes.icon)}>{program?.Icon}</i>}
				</Stack>
        <CoverageTable data={selectedCoverages} program={program} />
			</Box>
		</Box>
	);
}

export default TermSheet;
