import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const CoveragePhaseTable = (props) => {
  const { phases } = props;
  const classes = useStyles();

  const columns = [
    { label: 'Period Start', width: '25%' },
    { label: 'Period End', width: '25%' },
    { label: 'Parameters', width: '50%' },
  ];

  return (
    <Box className={classes.root}>
      <Typography variant='h5' className={classes.title}>Index Specification Phases</Typography>
      <Table sx={{mt: 2}}>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell key={index} sx={{width: col.width}} colSpan={index === columns.length - 1 ? 2 : 1}>
                <Typography variant="body1" fontWeight={600}>{col.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        
        <TableBody>
          {phases.map((phase, index) => (
            <TableRow key={`coverage-phase-table-row${index}`}>
              <TableCell>
                <Typography variant="body1">{phase.periodStart}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{phase.periodEnd}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{JSON.stringify(phase.parameters)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default CoveragePhaseTable;