import React from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import programsData from '../Quotes/QuoteNew/QuoteNewProgram/rw-programs-demo.json'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    marginBottom: theme.spacing(1.5),
  },
}));

export const ProgramAutocomplete = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.label}>Program Name</Typography>
      <Autocomplete
        disablePortal
        options={programsData}
        sx={{ width: 400 }}
        renderInput={(params) => <TextField {...params} />}
        getOptionLabel={(option) => `${option.Name} - ${option.Country}`}
        {...props}
      />
    </>
  );
}
