import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import ReactJson from 'react-json-view';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    minHeight: 100,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    boxShadow: '0px 3px 12px 0px #0000001F',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const CoverageParamXel = (props) => {
  const { paramXel } = props;
  const classes = useStyles();
  
  return (
    <Box className={classes.root}>
      <Typography variant='h5' className={classes.title}>Parametric Expression</Typography>
      <Box sx={{mt: 2}}>
        <ReactJson
          src={paramXel}
          theme="harmonic"
        />
      </Box>
    </Box>
  );
};

export default CoverageParamXel;