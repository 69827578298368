import React from 'react';
import PropTypes from 'prop-types';
import { Slider as MuiSlider } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled component
const StyledSlider = styled(MuiSlider)(({ theme }) => ({
  color: 'var(--color-secondary, #ffe034)',
  height: 8,
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '1px solid var(--color-secondary, #ffe034)',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    '&:focus, &:hover': {
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
  },
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4,
  },
  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.2)', // bg-black/20
  },
}));

/**
 * Slider component for selecting values from a range
 */
const RwSlider = ({ 
  value, 
  onChange, 
  min, 
  max, 
  step, 
  disabled, 
  valueLabelDisplay,
  ...props 
}) => {
  return (
    <StyledSlider
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      disabled={disabled}
      valueLabelDisplay={valueLabelDisplay}
      {...props}
    />
  );
};

RwSlider.propTypes = {
  /**
   * The value of the slider
   */
  value: PropTypes.number,
  /**
   * Callback fired when the value changes
   */
  onChange: PropTypes.func,
  /**
   * The minimum allowed value of the slider
   */
  min: PropTypes.number,
  /**
   * The maximum allowed value of the slider
   */
  max: PropTypes.number,
  /**
   * The step size of the slider
   */
  step: PropTypes.number,
  /**
   * If true, the slider will be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Controls when the value label is displayed
   */
  valueLabelDisplay: PropTypes.oneOf(['auto', 'on', 'off']),
};

RwSlider.defaultProps = {
  value: 50,
  min: 0,
  max: 100,
  step: 1,
  disabled: false,
  valueLabelDisplay: 'off',
};

export default RwSlider;
