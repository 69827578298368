import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import { Close, Download } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import ToolboxCoverage from '../Toolbox/ToolboxCoverage';
import CoverageActivate from './CoverageActivate';
import CoverageEdit from './CoverageEdit';
import CardStack from '../Cards/CardStack';
import CardIcon from '../Cards/CardIcon';
import CoverageType from './CoverageType';
import RiskPeriodCard from './RiskPeriodCard';
import { BurnCostGraph } from './BurnCostGraph';
import CoverageDetailsCard from './CoverageDetailsCard';
import CoveragePhaseTable from './CoveragePhaseTable';
import CoverageParamXel from './CoverageParamXel';
import CoverageVariableList from './CoverageVariableList';
import CoverageOutput from './CoverageOutput';

import Format from '../../Util/format';
import formatMissingValue from '../../Util/formatMissingValue';
import { ReactComponent as CashIcon } from '../../assets/images/cash.svg';
import { ReactComponent as BoardIcon } from '../../assets/images/board.svg';
import { ReactComponent as PdfIcon } from '../../assets/images/pdf-icon.svg';
import plural from '../../Util/pluralize';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    backgroundColor: '#f7f8fc',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  cardContainer: {
    marginBottom: theme.spacing(1.5),
  },
  spaced: {
    marginBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
  cardStackDescription: {
    fontSize: 18,
    fontWeight: 700,
  },
  cardSubtitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 2
  }
}));

const Coverage = props => {
  const {
    id,
    riskRate,
    premiumRate,
    eventTargeting,
    version,
    creator,
    createdAt,
    status,
    label,
    loaded,
    cards,
    technicalPricingReport,
    technicalPricingReportHTML,
    coverageFactSheet,
    indexType,
    phases,
    paramXel,
    variables,
    outputVariable,
    trigger,
    triggerUnit,
    minPayout,
    handleDownload,
    riskCarrier,
    payoutPerUnit,
    dataSourceId,
    dataSetId,
    monitoringTarget,
    riskTypeId,
    riskPremium,
    dataProvider,
    onRunSimulationPayout,
    onRemoveTechnicalPricingReportHtml,
  } = props;

  // Dialog box state.
  const [openActivate, setOpenActivate] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const technicalPricingReportBlobUrl = React.useMemo(() => technicalPricingReportHTML ? URL.createObjectURL(technicalPricingReportHTML.data) : undefined, [technicalPricingReportHTML]);

  const handleActivateOpen = () => {
    setOpenActivate(true);
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleDownloadTechnicalReport = () => {
    let cdHeader = technicalPricingReportHTML.headers['content-disposition'];

    let filename;
    if (cdHeader != null) {
      filename = cdHeader
          .split('filename=')[1]
          .split(';')[0]
      filename = filename.substr(1,filename.length -2);
    } else {
      filename = "TPR.pdf";
    }

    saveAs(technicalPricingReportHTML.data, filename);
  };

  const handleActivateClose = (object, reason) => {
    if (reason !== 'backdropClick') {
      setOpenActivate(false);
    }
  };

  const handleEditClose = (object, reason) => {
    if (reason !== 'backdropClick') {
      setOpenEdit(false);
    }
  };

  const classes = useStyles();
  const theme = useTheme();

  const root = window.appConfig.apiUrl;
  const labelText = label ?? `${cards.riskType.title.toUpperCase()} COVERAGE FOR ${eventTargeting}`;

  return (
    <Box className={classes.root}>
      <Toolbar/>
      <ToolboxCoverage
        id={id}
        loaded={loaded}
        label={labelText}
        variant={status}
        handleOpen={handleActivateOpen}
        handleEditOpen={handleEditOpen}
        creator={creator}
        createdAt={createdAt}
        version={version}
        onRunSimulationPayout={onRunSimulationPayout}
      />
      <CoverageActivate open={openActivate} handleActivate={props.handleActivate} handleClose={handleActivateClose} openEdit={handleEditOpen}/>
      <CoverageEdit open={openEdit} handleClose={handleEditClose}/>
      <div
        data="main"
        className={classes.main}
        style={{
          paddingTop: technicalPricingReportBlobUrl ? 0 : theme.spacing(5),
          paddingBottom: technicalPricingReportBlobUrl ? 0 : theme.spacing(5)
        }}
      >
        <Container
          maxWidth="xl"
          className={classes.container}
          style={{
            paddingLeft: technicalPricingReportBlobUrl ? 0 : theme.spacing(2),
            paddingRight: technicalPricingReportBlobUrl ? 0 : theme.spacing(2)
          }}
        >
          {technicalPricingReportBlobUrl ? (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: `<iframe src="${technicalPricingReportBlobUrl}" width="100%" height="100%"></iframe>` }}
                style={{ flex: 1, position: 'relative' }}
              />
              <IconButton
                sx={{ position: 'absolute', left: 10, top: 1, color: 'white' }}
                onClick={handleDownloadTechnicalReport}
              >
                <Download />
              </IconButton>
              <IconButton
                sx={{ position: 'absolute', right: 10, top: 1, color: 'white' }}
                onClick={onRemoveTechnicalPricingReportHtml}
              >
                <Close />
              </IconButton>
            </>
          ) : (
            <Stack spacing={2}>
              <Grid container columnSpacing={2} rowSpacing={2} sx={{ m: '-8px !important' }}>
                <Grid item xs={12} md={6} lg={3}>
                  <CoverageType riskTypeId={riskTypeId} label={labelText} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <RiskPeriodCard start={cards.start} end={cards.end} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <CardStack
                    title="Trigger"
                    content={
                      <Stack direction="row" alignItems="flex-end" gap={1}>
                        {formatMissingValue(parseInt(trigger * 100) / 100)}
                        <Typography className={classes.cardSubtitle}>{`${triggerUnit ? `${plural(triggerUnit, parseInt(trigger * 100) / 100)} ` : ''}pays ${formatMissingValue(parseInt(minPayout?.amount * 100) / 100)}%`}</Typography>
                      </Stack>
                    }
                    description={
                      <Stack direction="row" alignItems="center">
                        <CashIcon />
                        <Typography className={classes.cardStackDescription} color="#31D158">
                          {(payoutPerUnit?.amount ? (' then ' + parseInt(payoutPerUnit?.amount * 100) / 100 + '% per ' + plural(triggerUnit,parseInt(payoutPerUnit?.amount * 100) / 100)) : '')}
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <CardStack
                    title="Risk Premium"
                    content={Format.percentageWrapFormat(riskRate)}
                    description={
                      <Stack direction="row" alignItems="center">
                        <BoardIcon />
                        <Typography className={classes.cardStackDescription} color="#0000008A">
                          {premiumRate ? Format.percentageWrapFormat(premiumRate) : '-'} GWP Est.
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
              </Grid>
              <BurnCostGraph
                coverageId={id}
                {...{trigger, triggerUnit, payoutPerUnit, riskTypeId}}
              />
              <CoverageDetailsCard
                riskType={cards.riskType}
                trigger={formatMissingValue(parseInt(trigger * 100) / 100) + " " + (plural(triggerUnit,parseInt(trigger * 100) / 100) ?? '')}
                minPayout={formatMissingValue(parseInt(minPayout?.amount * 100) / 100) + '%'}
                payoutPerUnit={formatMissingValue(parseInt(payoutPerUnit?.amount * 100) / 100) + '% per ' + (triggerUnit ?? '')}
                indexType={cards.insuredTarget.sub}
                monitoringTarget={monitoringTarget}
                {...{label: labelText, dataProvider, riskCarrier, creator, dataSetId, dataSourceId}}
              />
              {indexType === 'COMPLEX' && (
                <>
                  {!!phases && <CoveragePhaseTable phases={phases} />}
                  {!!paramXel && <CoverageParamXel paramXel={paramXel} />}
                  {!!variables && <CoverageVariableList variables={variables} />}
                  {!!outputVariable && <CoverageOutput variable={outputVariable} />}
                </>
              )}
              <Grid container columnSpacing={2} rowGap={2} sx={{ mx: '-8px !important' }}>
                <Grid item xs={12} md={6} display="flex">
                  <CardIcon
                    loaded={loaded}
                    title="Technical pricing report"
                    handleDownload={handleDownload}
                    reportId={technicalPricingReport}
                    linkUrl={`${root}/internal/files/${technicalPricingReport}`}
                    icon={<PdfIcon/>}>
                    Coverage contains a loss and risk breakdown of a coverage before creating a quotation.
                  </CardIcon>
                </Grid>
                <Grid item xs={12} md={6} display="flex">
                  <CardIcon
                    loaded={loaded}
                    title="Coverage factsheet"
                    handleDownload={handleDownload}
                    linkUrl={`${root}/internal/files/${coverageFactSheet}`}
                    // reportId={coverageFactSheet}
                    icon={<PdfIcon/>}>
                    Coverage factsheet can be used to review the possible performance of a coverage.
                  </CardIcon>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Container>
      </div>
      <Backdrop className={classes.backdrop} open={props.backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Coverage;
