import { format } from 'date-fns';

/**
 * Formats a file size in bytes to a human-readable string (B, KB, MB, GB)
 * @param {number} sizeInBytes - Size in bytes
 * @returns {string} Formatted file size
 */
export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(1)} KB`;
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(1)} MB`;
  } else {
    return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  }
};

/**
 * Formats a timestamp to a relative time string (e.g., "Just now", "5m ago", "2h ago")
 * @param {Date|string|number} timestamp - Timestamp to format
 * @returns {string} Relative time string
 */
export const formatTimeAgo = (timestamp) => {
  if (!timestamp) return '';
  
  const now = new Date();
  const updateTime = new Date(timestamp);
  const diffInMinutes = Math.floor((now - updateTime) / (1000 * 60));
  
  if (diffInMinutes < 1) return 'Just now';
  if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
  
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours}h ago`;
  
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) return `${diffInDays}d ago`;
  
  return format(updateTime, 'MMM d, yyyy');
};
