import { useQuery } from "@tanstack/react-query";
import Ajax, { GetToken } from "../../Util/ajax";

const QUERY_KEY = "SEARCH_EVENT_EXTERNAL";

export const useSearchEventExternal = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const url = new URL(`${window.appConfig.apiUrl}/internal/coverages/labels?user=true&system=false`);     
      const response = await Ajax.getData(url.href);

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
