import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import {FacetedSearch} from "../Shared";
import ToolboxFiles from "../Toolbox/ToolboxFiles";
import FileAttachmentTable from "../Shared/FileAttachment/FileAttachmentTable";
import Toolbar from "@mui/material/Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
  },
  facetedSearchWrapper: {
    width: '40%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tableSection: {
    flexGrow: 1,
  }
}));

const FilesPage = (props) => {
  const {
    files,
    loaded,
    page,
    rowsPerPage,
    totalElements,
    onChangePage,
    onDownload,
    facets,
    selectedFacets,
    onFacetChange,
  } = props;

  const classes = useStyles();

  return (
      <Box className={classes.root}>
        <ToolboxFiles/>
        <Toolbar/>
        <Box className={classes.facetedSearchWrapper}>
          <FacetedSearch
              facets={facets}
              selectedFacets={selectedFacets}
              onFacetChange={onFacetChange}
              placeholder="Search files by labels..."
          />
      </Box>
        <Box className={classes.tableSection}>
          <FileAttachmentTable
              files={files}
              onDownload={onDownload}
              showHeader={true}
              selectable={false}
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
              onChangePage={onChangePage}
              onChangeRowsPerPage={(newRowsPerPage) => {
                if (props.onChangeRowsPerPage) {
                  props.onChangeRowsPerPage(newRowsPerPage);
                }
              }}
          />
        </Box>
      </Box>
  );
};

export default FilesPage;