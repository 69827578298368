import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  CircularProgress,
  Stack,
  IconButton,
  Collapse,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import useSupercluster from "use-supercluster";
import maplibre from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import clsx from "clsx";
import { getCentroid } from "../../Util/getCentroid";
import { redirect } from "../../Util/web";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },
  map: {
    flex: 1,
    display: "none",
  },
  mapShow: {
    display: "block",
  },
  mapPin: {
    '& .maplibregl-canvas-container': {
      cursor: 'url(assets/images/pin-location.png) 7.5 23, auto'
    }
  },
  icon: {
    position: "relative",
  },
  label: {
    position: "absolute",
    inset: 0,
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  markerContainer: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  activeMarker: {
    filter: "drop-shadow(0px 2px 5px #98221A)",
  },
  alertContainer: {
    position: "absolute",
    top: 10,
    zIndex: 100,
  },
  alert: {
    backgroundColor: "#1976D2",
    borderRadius: 2,
    color: 'white',
  }
}));

const apiKey = window.appConfig.geoapify.api.key;

const options = {
  radius: 75,
  maxZoom: 15,
  reduce: (acc, props) => {
    acc.count += props.count;

    if (acc.status === "error") {
      return acc;
    }

    if (acc.status === "simulated" && props.status !== 'error') {
      return acc;
    }

    acc.status = props.status;

    return acc;
  },
};

const LAT_OFFSET = 0.05;

const COLOR = {
  Green: "GREEN",
  Red: "RED",
  Yellow: "YELLOW",
};

const getMarkerIcon = (color = COLOR.Green, selected) => {
  switch (color) {
    case COLOR.Green:
      return selected
        ? "assets/images/green-circle-shadow-icon.svg"
        : "assets/images/green-circle-icon.svg";
    case COLOR.Red:
      return selected
        ? "assets/images/red-circle-shadow-icon.png"
        : "assets/images/red-circle-icon.svg";
    case "YELLOW":
      return selected
        ? "assets/images/yellow-circle-shadow-icon.svg"
        : "assets/images/yellow-circle-icon.svg";
    default:
      throw new Error("Invalid color");
  }
};

const getColor = (status) => {
  switch (status) {
    case "error":
      return COLOR.Red;
    case "simulated":
      return COLOR.Yellow;
    case "active":
    default:
      return COLOR.Green;
  }
};

export const CoveragesMap = ({
  isLoading,
  setBounds,
  selectedCoordinates,
  setSelectedCoordinates,
  mapInteraction,
  zoom,
  setZoom,
  coveragesForMap
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(() => {
    return localStorage.getItem('coveragesMapAlertClosed') !== 'true';
  });
  const [points, setPoints] = useState([]);
  const [boundsCluster, setBoundsCluster] = useState();
  const mapContainer = useRef(null);
  const maplibreRef = useRef();
  const [mapReady, setMapReady] = useState(false);
  const [isFitten, setIsFitten] = useState(false);

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom,
    bounds: boundsCluster,
    options: {
      ...options,
    },
  });
  
  useEffect(() => {
    const mapStyle = "https://maps.geoapify.com/v1/styles/toner-grey/style.json";
    const map = new maplibre.Map({
      container: mapContainer.current,
      style: `${mapStyle}?apiKey=${apiKey}`,
      interactive: true,
      maxZoom: 15,
    });

    const updateMap = () => {
      const b = map.getBounds();
      setBoundsCluster([
        b.getSouthWest().lng,
        b.getSouthWest().lat,
        b.getNorthEast().lng,
        b.getNorthEast().lat,
      ]);

      setZoom(map.getZoom());
    };

    map.on("move", () => {
      const mapBounds = map.getBounds();
      const LON_OFFSET = (mapBounds._ne.lng - mapBounds._sw.lng) * 0.02;
      const topLeftLat = mapBounds._sw.wrap().lat - LAT_OFFSET;
      const topLeftLon = mapBounds._ne.wrap().lng + LON_OFFSET;
      const bottomRightLat = mapBounds._ne.wrap().lat - LAT_OFFSET;
      const bottomRightLon = mapBounds._sw.wrap().lng + LON_OFFSET;

      setBounds({
        topLeftLat: topLeftLat.toFixed(1),
        topLeftLon: topLeftLon.toFixed(1),
        bottomRightLat: bottomRightLat.toFixed(1),
        bottomRightLon: bottomRightLon.toFixed(1),
      });
    });

    map.on("load", () => {
      map.setZoom(0);
      setMapReady(true);
      updateMap();

      map.on("move", updateMap);
    });

    maplibreRef.current = map;

    return () => {
      setMapReady(false);
      maplibreRef.current?.remove?.();
    };
  }, [setBounds, setZoom]);

  useEffect(() => {
    const map = maplibreRef.current;
    const isReady = !mapContainer.current || !map || !mapReady;

    if (isReady) {
      return;
    }

    map.on("click", (e) => {
      if (mapInteraction === 'pin') {
        const {lng, lat} = e.lngLat;

        if (lng && lat) {
          redirect(`/coverages/new?lng=${lng.toFixed(4)}&lat=${lat.toFixed(4)}`);
        }
      }
    })
  }, [mapReady, mapInteraction])

  useEffect(() => {
    const map = maplibreRef.current;
    const isReady = !mapContainer.current || !map || !mapReady;

    if (isReady) {
      return;
    }

    const markers = clusters.map((cluster) => {
      const {cluster: isCluster, count , cluster_id: clusterId, status } = cluster.properties;
      let topLeftLat = cluster.geometry.coordinates[1];
      let topLeftLon = cluster.geometry.coordinates[0];
      let bottomRightLat = cluster.geometry.coordinates[1];
      let bottomRightLon = cluster.geometry.coordinates[0];
      if(isCluster){
        const clusterChildren = supercluster.getLeaves(clusterId, Infinity);
        const coordinates = clusterChildren.map((child) => child.geometry.coordinates);

        const lngs = coordinates.map(([lng]) => lng);
        const lats = coordinates.map(([_, lat]) => lat);

        topLeftLat = Math.max(...lats);
        topLeftLon = Math.min(...lngs);
        bottomRightLat = Math.min(...lats);
        bottomRightLon = Math.max(...lngs);
      }

      const markerContainer = document.createElement("div");
      markerContainer.classList.add(classes.markerContainer);

      const icon = document.createElement("img");
      icon.src = getMarkerIcon(
        getColor(status),
        selectedCoordinates?.topLeftLon === topLeftLon &&
          selectedCoordinates?.topLeftLat === topLeftLat &&
          selectedCoordinates?.bottomRightLat === bottomRightLat &&
          selectedCoordinates?.bottomRightLon === bottomRightLon,
      );
      icon.classList.add(classes.icon);

      const label = document.createElement("div");
      label.classList.add(classes.label);
      markerContainer.appendChild(icon);
      markerContainer.appendChild(label);
      label.innerText = count;

      const marker = new maplibre.Marker({ element: markerContainer });

      markerContainer.addEventListener("click", (e) => {
        e.stopPropagation();
        setSelectedCoordinates({
          topLeftLon,
          topLeftLat,
          bottomRightLon,
          bottomRightLat,
        });
      });

      marker.setLngLat(cluster.geometry.coordinates).addTo(map);
      return marker;
    });

    return () => {
      markers.forEach((marker) => marker.remove());
    };
  }, [
    mapReady,
    selectedCoordinates,
    classes.marker,
    classes.activeMarker,
    classes.markerContainer,
    classes.icon,
    classes.label,
    clusters,
  ]);

  useEffect(() => {
    const fetchPoints = async () => {
      const features =
        coveragesForMap
          .map((coverage) => {
            const geometryType = coverage?.monitoringTarget?.geometryData?.geoJson?.features?.[0]?.geometry.type;
            const geometry = coverage?.monitoringTarget?.geometryData?.geoJson?.features?.[0]?.geometry;
            
            if (!geometry) return null;

            let coordinates;
            switch (geometryType) {
              case "MultiPolygon":
                coordinates = geometry.coordinates;
                break;
              case "Polygon":
                coordinates = [geometry.coordinates];
                break;
              case "Point":
                coordinates = [[geometry.coordinates]];
                break;
              default:
                return null;
            }

            return {
              coordinates,
              monitoringTargetId: coverage?.monitoringTarget?.id,
              coverage: coverage,
              geometryType: geometryType,
            };
          })
          .filter(Boolean);

      // Extract all coordinates for bounds calculation
      const allCoordinates = features.flatMap((item) => {
        const {coordinates} = item;

        return coordinates.reduce((acc, polygon) => {
          return acc.concat(polygon[0]);
        }, []);
      });

      const latValues = allCoordinates.map(coord => coord[1]).filter(Boolean);
      const lonValues = allCoordinates.map(coord => coord[0]).filter(Boolean);

      if (!!latValues.length && !!lonValues.length && !isFitten) {
        const bounds = [
          [
            lonValues.reduce((acc, cur) => Math.min(acc, cur), Infinity) - 1,
            latValues.reduce((acc, cur) => Math.min(acc, cur), Infinity) - 1
          ],
          [
            lonValues.reduce((acc, cur) => Math.max(acc, cur), -Infinity) + 1,
            latValues.reduce((acc, cur) => Math.max(acc, cur), -Infinity) + 1
          ]
        ];
        const map = maplibreRef.current;
        map.fitBounds(bounds);
        setIsFitten(true);
      }

      const transformedFeatures =
        features
          .map((item) => {
            const {geometryType, coordinates, coverage} = item;
            
            let points;
            switch (geometryType) {
              case 'MultiPolygon':
              case 'Polygon':
                  points = coordinates.reduce((acc, polygon) => {
                  return acc.concat(polygon[0]);
                }, []);
                break;
              case 'Point':
                points = coordinates;
                break;
              default:
                points = [];
            }

            const centroid = getCentroid(points);
            console.log(centroid);
            return {
              points: centroid,
              monitoringTargetId: item.monitoringTargetId,
              coverage: coverage,
            };
          }) ?? [];

      const pointsWithCounts = transformedFeatures.map((feature) => {
        return {
          type: "Feature",
          properties: {
            monitoringTargetId: feature.monitoringTargetId,
            cluster: false,
            count: 1,
            status: feature.coverage?.uiState,
          },
          geometry: {
            type: "Point",
            coordinates: feature.points,
          },
        };
      });

      setPoints(mapInteraction === 'pin' ? [] : pointsWithCounts);
    };

    fetchPoints();
  }, [coveragesForMap, mapInteraction, isFitten]);

  return isLoading ? (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  ) : (
    <div className={classes.root} style={{height: selectedCoordinates ? "calc(100% - 450px)" : "calc(100% - 145px)"}}>
      <Collapse className={classes.alertContainer} in={open}>
        <Stack direction="row" alignItems="center" className={classes.alert} sx={{py: 2, px: 3}}>
          <InfoIcon fontSize="inherit" />
          <Typography component="span" color="white" fontSize="14px" lineHeight="1" sx={{mx: 1.25}}>Click on clusters to explore available coverages in this region!</Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
              localStorage.setItem('coveragesMapAlertClosed', 'true');
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Collapse>
      <div
        className={clsx(classes.map, mapInteraction === "pin" && classes.mapPin, mapReady && classes.mapShow)}
        ref={mapContainer}
      />
    </div>
  );
};
