import {
  faFilePdf,
  faFileExcel,
  faFileWord,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFileArchive,
  faFileCode,
  faFile,
} from '@fortawesome/free-regular-svg-icons';

import {
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';

/**
 * Get the appropriate FontAwesome icon and color for a file type
 * @param {string} fileType - The type of file or MIME type
 * @returns {Object} Object containing the icon and color
 */
export const getFileIcon = (fileType) => {
  const type = fileType?.toLowerCase() || '';
  console.log("types:" + type);

  // Handle MIME types
  if (type.includes('/')) {
    // PDF files
    if (type.includes('pdf')) {
      return { icon: faFilePdf, color: '#FF5252' };
    }

    // Excel files
    if (type.includes('spreadsheetml') ||
        type.includes('excel') ||
        type.includes('xls')) {
      return { icon: faFileExcel, color: '#4CAF50' };
    }

    // Word files
    if (type.includes('wordprocessingml') ||
        type.includes('msword')) {
      return { icon: faFileWord, color: '#2196F3' };
    }

    // CSV files
    if (type.includes('csv')) {
      return { icon: faFileCsv, color: '#00BCD4' };
    }

    // Image files
    if (type.startsWith('image/')) {
      return { icon: faFileImage, color: '#9C27B0' };
    }

    // Audio files
    if (type.startsWith('audio/')) {
      return { icon: faFileAudio, color: '#FF9800' };
    }

    // Video files
    if (type.startsWith('video/')) {
      return { icon: faFileVideo, color: '#795548' };
    }

    // Archive files
    if (type.includes('zip') || type.includes('compressed') || type.includes('archive')) {
      return { icon: faFileArchive, color: '#607D8B' };
    }

    // Code files
    if (type.includes('javascript') || type.includes('html') || type.includes('css') ||
        type.includes('xml') || type.includes('json')) {
      return { icon: faFileCode, color: '#673AB7' };
    }
  }

  // If not a MIME type, handle file extensions
  else {
    switch (type) {
      case 'pdf':
        return { icon: faFilePdf, color: '#FF5252' };
      case 'excel':
      case 'xlsx':
      case 'xls':
        return { icon: faFileExcel, color: '#4CAF50' };
      case 'word':
      case 'doc':
      case 'docx':
        return { icon: faFileWord, color: '#2196F3' };
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'image':
        return { icon: faFileImage, color: '#9C27B0' };
      case 'mp3':
      case 'wav':
      case 'audio':
        return { icon: faFileAudio, color: '#FF9800' };
      case 'mp4':
      case 'avi':
      case 'mov':
      case 'video':
        return { icon: faFileVideo, color: '#795548' };
      case 'zip':
      case 'rar':
      case 'archive':
        return { icon: faFileArchive, color: '#607D8B' };
      case 'html':
      case 'css':
      case 'js':
      case 'code':
        return { icon: faFileCode, color: '#673AB7' };
      case 'csv':
        return { icon: faFileCsv, color: '#00BCD4' };
    }
  }

  return { icon: faFile, color: 'rgba(0, 0, 0, 0.6)' };
};