import InfoIcon from '@mui/icons-material/InfoOutlined';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Popover,
  Stack,
  TablePagination,
  Tooltip,
  Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {RwCheckbox} from '../../Base';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCodeBranch,
  faDownload,
  faEye,
  faHardDrive,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendar,
  faClock,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import {getFileIcon} from './utils';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";

// Styled components
const TableContainer = styled(Box)(({theme}) => ({
  fontFamily: theme.typography?.fontFamily || '"Inter", sans-serif',
  width: '100%',  // Change to 100% to fill the container
  backgroundColor: '#fff',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '0.5rem',
}));

const TableHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.75rem 1rem',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  fontSize: '0.875rem',
  color: 'rgba(0, 0, 0, 0.8)',
  fontWeight: 500,
}));

const TableRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.75rem 1rem',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));

const TableCell = styled(Box)(({width}) => ({
  width: width || 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

const FileIcon = styled(Box)(({color}) => ({
  fontSize: '1rem',
  color: color || 'rgba(0, 0, 0, 0.6)',
}));

const MetadataItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '0.875rem',
}));

const MetadataIcon = styled(Box)(() => ({
  color: 'rgba(0, 0, 0, 0.4)',
}));

const PopoverContent = styled(Box)(() => ({
  width: '16rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
}));

const CustomAvatar = styled('img')(() => ({
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '9999px',
}));

/**
 * FileAttachmentTable component for displaying files in a table format
 */
const FileAttachmentTable = ({
  files,
  onDisplay,
  onDownload,
  onInfo,
  selectable,
  onSelectChange,
  showHeader,
  page,
  rowsPerPage,
  totalElements,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const [activeFileIndex, setActiveFileIndex] = useState(null);
  const [openRowIndex, setOpenRowIndex] = useState(null);

  const handleInfoClick = (event, index) => {
    setInfoAnchorEl(event.currentTarget);
    setActiveFileIndex(index);
    if (onInfo) {
      onInfo(files[index], index);
    }
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  const infoOpen = Boolean(infoAnchorEl);
  const infoPopoverId = infoOpen ? 'attachment-table-info-popover' : undefined;

  // Info popover content
  const infoPopover = activeFileIndex !== null && infoOpen && (
      <Popover
          id={infoPopoverId}
          open={infoOpen}
          anchorEl={infoAnchorEl}
          onClose={handleInfoClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
        <PopoverContent>
          <MetadataItem>
            <MetadataIcon>
              <FontAwesomeIcon icon={faCalendar}/>
            </MetadataIcon>
            <Typography
                variant="body2">Created: {files[activeFileIndex].createdDate
                || 'N/A'}</Typography>
          </MetadataItem>
          <MetadataItem>
            <MetadataIcon>
              <FontAwesomeIcon icon={faUser}/>
            </MetadataIcon>
            <Typography variant="body2">Created
              by: {files[activeFileIndex].creator || 'Unknown'}</Typography>
          </MetadataItem>
          {files[activeFileIndex]?.updatedDate && (
              <MetadataItem>
                <MetadataIcon>
                  <FontAwesomeIcon icon={faClock}/>
                </MetadataIcon>
                <Typography
                    variant="body2">Updated: {files[activeFileIndex].updatedDate}</Typography>
              </MetadataItem>
          )}
          {files[activeFileIndex].updater && (
              <MetadataItem>
                <MetadataIcon>
                  <FontAwesomeIcon icon={faUser}/>
                </MetadataIcon>
                <Typography variant="body2">Updated
                  by: {files[activeFileIndex].updater}</Typography>
              </MetadataItem>
          )}
          {files[activeFileIndex].fileVersion && (
              <MetadataItem>
                <MetadataIcon>
                  <FontAwesomeIcon icon={faCodeBranch}/>
                </MetadataIcon>
                <Typography
                    variant="body2">Version: {files[activeFileIndex].fileVersion}</Typography>
              </MetadataItem>
          )}
        </PopoverContent>
      </Popover>
  );

  return (
      <TableContainer>
        {showHeader && (
            <TableHeader>
              {selectable && (
                  <TableCell
                      sx={{width: '1rem'}}>  {/* Changed to 1rem to match data rows */}
                    <RwCheckbox
                        checked={false}
                        indeterminate={true}
                        onChange={() => {
                          // Handle select all functionality if needed
                          const allSelected = files.every(
                              file => file.selected);
                          const newSelectedState = !allSelected;
                          if (onSelectChange) {
                            files.forEach((file, index) => {
                              onSelectChange(newSelectedState, index, file);
                            });
                          }
                        }}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            width: '1rem',
                            height: '1rem'
                          },
                          '& .MuiCheckbox-root': {
                            borderRadius: '0.25rem'
                          }
                        }}
                    />
                  </TableCell>
              )}
              <TableCell
                  sx={{width: '1rem'}}></TableCell> {/* Changed to 1rem to match data rows */}
              <TableCell sx={{flex: 1}}>Name</TableCell>
              <TableCell sx={{width: '6rem'}}>Size</TableCell>
              <TableCell sx={{width: '10rem'}}>Modified</TableCell>
              <TableCell sx={{width: '10rem'}}>Created by</TableCell>
              <TableCell sx={{width: '6rem'}}>Actions</TableCell>
            </TableHeader>
        )}
        {files.map((file, index) => {
          const {icon, color} = getFileIcon(file.contentType);
          return (
              <TableRow key={`file-${index}`}>
                {selectable && (
                    <TableCell sx={{width: '1rem', alignItems: 'center'}}>
                      <RwCheckbox
                          checked={file.selected}
                          onChange={(e) => onSelectChange && onSelectChange(
                              e.target.checked, index, file)}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              width: '1rem',
                              height: '1rem'
                            },
                            '& .MuiCheckbox-root': {
                              borderRadius: '0.25rem'
                            }
                          }}
                      />
                    </TableCell>
                )}
                <TableCell sx={{width: '1rem', alignItems: 'center'}}>
                  <FileIcon color={color}>
                    <FontAwesomeIcon icon={icon}/>
                  </FileIcon>
                </TableCell>
                <TableCell sx={{flex: 1, alignItems: 'center'}}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1">{file.fileName}</Typography>
                    <ClickAwayListener
                        onClickAway={() => {
                          if (openRowIndex === index) {
                            setOpenRowIndex(null);
                          }
                        }}
                    >
                      <div>
                        <Tooltip
                            title={file.description || 'No description'}
                            placement="bottom"
                            onClose={() => setOpenRowIndex(null)}
                            open={openRowIndex === index}
                            disableHoverListener
                            disableFocusListener
                            disableTouchListener
                        >
                          <IconButton
                              size="small"
                              onClick={() =>
                                  setOpenRowIndex(
                                      openRowIndex === index ? null : index)
                              }
                          >
                            <InfoIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </Box>
                  {file.labels && Object.entries(file.labels).length > 0 && (
                      <Stack direction="row" spacing={1} flexWrap="wrap"
                             sx={{ alignItems: 'center'}}>
                        {Object.entries(file.labels).map(([key, value]) => (
                            <Chip
                                key={key + ':' + value}
                                label={`${key}: ${value}`}
                                size="small"
                                variant="outlined"
                                sx={{bgcolor: '#f6f6f6'}}
                            />
                        ))}
                      </Stack>
                  )}
                </TableCell>
                <TableCell sx={{width: '6rem'}}>
                  <Typography variant="body2" sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '0.875rem'
                  }}>
                    <FontAwesomeIcon icon={faHardDrive} style={{
                      fontSize: '0.75rem',
                      marginRight: '0.25rem'
                    }}/>
                    {file.fileSize}
                  </Typography>
                </TableCell>
                <TableCell sx={{width: '10rem'}}>
                  <Typography variant="body2" sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '0.875rem'
                  }}>
                    <FontAwesomeIcon icon={faClock} style={{
                      fontSize: '0.75rem',
                      marginRight: '0.25rem'
                    }}/>
                    {file.updatedAt}
                  </Typography>
                </TableCell>
                <TableCell sx={{width: '10rem'}}>
                  <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    {file.creatorAvatar && (
                        <CustomAvatar src={file.creatorAvatar}
                                      alt={file.creator}
                                      sx={{width: '1.5rem', height: '1.5rem'}}/>
                    )}
                    <FontAwesomeIcon icon={faUser}
                                     style={{fontSize: '0.75rem'}}/>
                    <Typography variant="body2" sx={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontSize: '0.875rem'
                    }}>
                      {file.creator}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{width: '6rem'}}>
                  <Box sx={{display: 'flex', gap: 1}}>
                    {onDisplay && (
                        <Tooltip title="View File">
                          <Box component="span" sx={{display: 'inline-block'}}>
                            <Button
                                variant="text"
                                color="secondary"
                                size="small"
                                onClick={() => onDisplay(file, index)}
                                startIcon={<FontAwesomeIcon icon={faEye}/>}
                                aria-label="View File"
                                sx={{
                                  padding: '0.375rem',
                                  minWidth: 'auto',
                                  borderRadius: '0.25rem',
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                  },
                                  fontFamily: theme => theme.typography?.fontFamily
                                      || '"Inter", sans-serif'
                                }}
                            />
                          </Box>
                        </Tooltip>
                    )}

                    {onDownload && (
                        <Tooltip title="Download File">
                          <Box component="span" sx={{display: 'inline-block'}}>
                            <Button
                                variant="text"
                                color="secondary"
                                size="small"
                                onClick={() => onDownload(file.id, file.fileName)}
                                startIcon={<FontAwesomeIcon icon={faDownload}/>}
                                aria-label="Download File"
                                sx={{
                                  padding: '0.375rem',
                                  minWidth: 'auto',
                                  borderRadius: '0.25rem',
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                  },
                                  fontFamily: theme => theme.typography?.fontFamily
                                      || '"Inter", sans-serif'
                                }}
                            />
                          </Box>
                        </Tooltip>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
          );
        })}
        <TablePagination
            component="div"
            count={totalElements || 0}
            page={page || 0}
            onPageChange={(event, newPage) => {
              if (onChangePage) {
                onChangePage(newPage);
              }
            }}
            rowsPerPage={rowsPerPage || 5}
            onRowsPerPageChange={(event) => {
              if (onChangeRowsPerPage) {
                onChangeRowsPerPage(
                    parseInt(event.target.value, 10));
              }
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            labelRowsPerPage="Files per page:"
        />

      </TableContainer>
  );
};

FileAttachmentTable.propTypes = {
  files: PropTypes.array.isRequired,
  onDisplay: PropTypes.func,
  onDownload: PropTypes.func,
  onInfo: PropTypes.func,
  selectable: PropTypes.bool,
  onSelectChange: PropTypes.func,
  showHeader: PropTypes.bool,
  // Add new pagination prop types
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalElements: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
};

FileAttachmentTable.defaultProps = {
  files: [],
  selectable: false,
  showHeader: true,
  page: 0,
  rowsPerPage: 5,
};

export default FileAttachmentTable;