import React from 'react';
import PropTypes from 'prop-types';
import { Box, Input, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components
const TextFieldWrapper = styled(Box)({
  width: '100%',
});

const InputContainer = styled(Box)(({ error, disabled }) => ({
  height: '2.5rem', // reduced from 3.5rem
  padding: '0.5rem 0.75rem', // reduced vertical padding
  borderRadius: '0.25rem', // rounded
  width: '100%',
  border: `1px solid ${error ? '#f44336' : 'rgba(0, 0, 0, 0.4)'}`, // border-black/40
  backgroundColor: disabled ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
  transition: 'border-color 0.2s ease',
  marginTop: '0.5rem', // added to make space for label
  '&:focus-within': {
    borderColor: error ? '#f44336' : 'var(--color-secondary, #ffe034)',
  },
}));

const LabelWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
});

const StyledLabel = styled(Typography)(({ error, disabled }) => ({
  fontSize: '0.75rem', // text-xs
  color: error ? '#f44336' : 'rgba(0, 0, 0, 0.4)', // text-black/40
  position: 'absolute',
  top: '0.1rem',
  left: '0.5rem',
  paddingRight: '0.25rem',
  paddingLeft: '0.25rem',
  backgroundColor: 'white',
  transform: 'translateY(-50%)',
  zIndex: 1,
}));

const StyledInput = styled(Input)(({ error, disabled }) => ({
  fontSize: '1rem', // text-base
  color: disabled ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.9)', // text-black/90 or text-black/40 when disabled
  width: '100%',
  height: '100%',
  padding: 0,
  '&:before, &:after': {
    display: 'none', // Remove Material UI underline
  },
  '& input': {
    padding: 0,
    height: '100%',
  },
  '&.Mui-disabled': {
    cursor: 'not-allowed',
  },
}));

/**
 * TextField component styled exactly according to the design specification
 */
const RwTextField = ({
  label,
  fullWidth,
  required,
  disabled,
  error,
  type,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  return (
    <TextFieldWrapper sx={{ width: fullWidth ? '100%' : 'auto' }}>
      <LabelWrapper>
        <StyledLabel error={error} disabled={disabled}>
          {label}{required && ' *'}
        </StyledLabel>
        <InputContainer 
          error={error} 
          disabled={disabled}
          {...props}
        >
        <StyledInput
          disableUnderline
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          error={error}
          fullWidth
          inputProps={{
            style: { outline: 'none' }
          }}
        />
      </InputContainer>
    </LabelWrapper>
    </TextFieldWrapper>
  );
};

RwTextField.propTypes = {
  /**
   * The label content
   */
  label: PropTypes.string,
  /**
   * If true, the input will take up the full width of its container
   */
  fullWidth: PropTypes.bool,
  /**
   * If true, the label is displayed as required and the input element will be required
   */
  required: PropTypes.bool,
  /**
   * If true, the component is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If true, the component will display an error state
   */
  error: PropTypes.bool,
  /**
   * Type of the input element
   */
  type: PropTypes.string,
  /**
   * The short hint displayed in the input before the user enters a value
   */
  placeholder: PropTypes.string,
  /**
   * The value of the input element
   */
  value: PropTypes.any,
  /**
   * Callback fired when the value is changed
   */
  onChange: PropTypes.func,
};

RwTextField.defaultProps = {
  fullWidth: true,
  required: false,
  disabled: false,
  error: false,
  type: 'text',
  label: 'Label',
};

export default RwTextField;
